'use strict';

const utils = require('../components/utils');

const $mainPDPGallery = $('.product-gallery--main');
const $modelDescription = $('.js-onmodel-description');

/**
 * Reset (hide) model description
 */
function resetModelDescription() {
    $modelDescription.removeClass('d-block').addClass('d-none');
}

/**
 * Initialize model data
 * @param {string} onModelValue: Reference to data model of thumbnail image
 * @returns {boolean} True if no errors occur
 */
function getOnModelData(onModelValue) {
    let modelKey;
    let modelValue;
    let modelObject;
    const url = $mainPDPGallery.data('model-url');
    try {
        const onModelObj = onModelValue && JSON.parse(decodeURIComponent(onModelValue));
        if (onModelObj) {
            modelKey = Object.keys(onModelObj)[0];
            modelValue = onModelObj[modelKey];
            modelObject = {
                modelKey: modelKey,
                modelValue: modelValue
            };
            $.ajax({
                url: utils.setUrlKeyValue(url, 'pid', onModelObj.variantUPC.trim()),
                type: 'post',
                dataType: 'json',
                data: modelObject,
                success: function (data) {
                    if (data && data.modelDescription) {
                        $modelDescription.html(data.modelDescription)
                            .removeClass('d-none').addClass('d-block');
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    } catch (e) {
        return false;
    }
    return true;
}

/**
 * Initialize model data
 * @returns {string} modeldataValue on model data value of image
 */
function imageModelData() {
    let modelImg = $mainPDPGallery.find('.js-model-image');
    let modelDataValue;

    if (modelImg) {
        let modelImgArray = Array.from(modelImg);
        modelDataValue = modelImgArray.find(function (element) {
            return $(element).data('onmodel') !== null;
        });
    }
    return modelDataValue;
}

/**
 * Trigger display of OnModel description
 * Dependency: imageModelData()
 */
function triggerOnModelDescription() {
    const modelValue = imageModelData();
    const imageclick = $(modelValue).data('onmodel');

    resetModelDescription();
    getOnModelData(imageclick);
}

module.exports = {
    getOnModelData: getOnModelData,
    imageModelData: imageModelData,
    triggerOnModelDescription: triggerOnModelDescription
};
