'use strict';

const keyboardNav = require('./headerKeyboardNav');
const countrySelectorKeyboardNav = require('./countrySelectorKeyboardNav');
const breakpoints = require('./breakpoints');
const rewardsTracker = require('./rewardsTracker');
const debounce = require('lodash/debounce');
const _ = require('underscore');

const $window = $(window);
const $body = $('body');

const $sitenav = $('.sitenav');
const $sitenavMenu = $('.sitenav__menu');
const $siteheaderOpen = $('.js-action__menuopen');
const $sitenavClose = $('.js-action__menuclose');

/**
 * Open Menu Plugin
 * @returns {jQuery} jQuery element reference
 */
$.fn.openMenuItem = function () {
    // only set aria-expanded if value already exists
    if (this.attr('aria-expanded') !== undefined || this.closest('li').attr('aria-expanded') !== undefined) { // when "this" is the "ul", find the closest aria-expanded state
        this.attr('aria-expanded', 'true');
        this.addClass('open'); // this should correspond with the aria-expanded "true" state
    }
    return this;
};

/**
 * Close Menu Plugin
 * @returns {jQuery} jQuery element reference
 */
$.fn.closeMenuItem = function () {
    // close any open child menus
    this.find('.open').removeClass('open');
    this.find('[aria-expanded]').attr('aria-expanded', 'false');

    // only set aria-expanded if value already exists
    if (this.attr('aria-expanded') !== undefined) {
        this.attr('aria-expanded', 'false');
    }

    this.removeClass('open');
    return this;
};

/**
 * Focus Menu Plugin
 * @returns {jQuery} jQuery element reference
 */
$.fn.focusMenuItem = function () {
    // only set aria-expanded if value already exists
    if (this.attr('aria-expanded') !== undefined) {
        this.attr('aria-expanded', 'true'); // allows screen readers to properly announce expanded/collapse state on keyboard navigation focus
    }
    return this;
};

/**
 * Remove inline styles from mobile menu
 */
function removeMobileMenuOffset() {
    $sitenav.css({
        transform: '',
        bottom: ''
    });
}

/**
 * Sets inline styles on mobile menu to offset the header height
 */
function setMobileMenuOffset() {
    const boundingRect = document.querySelector('.siteheader__bottom').getBoundingClientRect();
    const navOffset = boundingRect.bottom + 'px';

    $sitenav.css({
        transform: 'translateY(' + navOffset + ')',
        bottom: navOffset
    });
}

/**
 * Lazy Load Account Drawer Content
 * @param {string} openDrawerTarget registration or login drawer selector
 */
function lazyLoadAccountContent(openDrawerTarget) {
    let url = $('.js-action__menuopen, .js-action__menutoggle').data('lazy-load-account-drawer-url');
    if (!url) {
        return;
    }

    let $container = $('.js-mobile-menu-account-drawer-container');

    // Exit early if the container is loading.
    if ($container.data('is-loading')) {
        return;
    }

    // Exit early if the content exists.
    if ($container.children().length) {
        if (openDrawerTarget) {
            module.exports.openSubNav(openDrawerTarget);
        }

        return;
    }

    // Exit early if the container is loading.
    $container.data('is-loading', true);

    $.ajax({
        url: url,
        method: 'GET',
        success: function (response) {
            $container.append($(response));
            $container.on('click', '.js-btn-registration', function () {
                $container.find('.js-rewards-terms').prop('checked', !$(this).hasClass('js-btn-registration-no-rewards'));
            });
            rewardsTracker.loadRewardsTracker($('.account-content'));
            $container.find('form.login').trigger('form:created');
            $container.find('form.registration').trigger('form:created');

            if (openDrawerTarget) {
                module.exports.openSubNav(openDrawerTarget);
            }
        },
        complete: function () {
            $container.data('is-loading', false);
        }
    });
}

/**
 * Open the mobile navigation menu
 * @param {string} openDrawerTarget registration or login drawer selector
 */
function openMobileNav(openDrawerTarget) {
    lazyLoadAccountContent(openDrawerTarget);

    // Prevent page scrolling
    $.disableScroll();
    module.exports.setMobileMenuOffset();
    $('html').addClass('menu-active');
    $sitenav.addClass('open');
    $sitenavMenu.addClass('open');
    $(document).trigger('mobileMenu:show');
    $('.refinement-bar button.close').trigger('click'); // close refinement bar on mobile
}

/**
 * If selected menu item has a submenu, show it
 * @param {string} selected - selected menu item
 * @returns {bool} if submenu exists
 */
function openSubNav(selected) {
    // NOTE: This function is only invoked when a primary navigation item is clicked
    // this function is overridden in other brands
    const $openItems = $(selected).parent().find('ul.sitenav-secondary__ul');
    if ($openItems.length) {
        // if there are secondary items, add the open class to .sitenav-secondary
        const $secondaryContainer = $(selected).siblings('.sitenav-secondary');

        if ($secondaryContainer.length) {
            $secondaryContainer.addClass('open');
        }

        $openItems.each(function () {
            $(this).openMenuItem();
        });

        return true;
    }
    return false;
}

/**
* If selected menu item has a submenu, toggle displaying it
* @param {string} selected - selected menu item anchor
* @param {string} selector - sub navigation target element
* @returns {bool} if submenu exists
*/
function toggleSubNav(selected, selector) {
    const $parentLi = $(selected).parent('li');
    const $subNav = $parentLi.find(selector).first();

    if ($subNav.length) {
        if ($subNav.hasClass('open')) { // close subnav
            $subNav.closeMenuItem();
            $subNav.removeClass('open');
        } else { // open subnav
            $subNav.openMenuItem();
            $subNav.addClass('open');
        }

        $parentLi.toggleClass('open');

        return true;
    }
    return false;
}

/**
 * Close secondary navigation menus
 */
function closeSecondarySubNav() {
    $('.sitenav-secondary').closeMenuItem();
}

/**
 * Close teritary navigation menus
 */
function closeTertiarySubNav() {
    $('.sitenav-tertiary__ul').closeMenuItem();
    $('.sitenav-tertiary__ul').removeClass('open');
}

/**
 * Close secondary and tertiary navigation menus
 */
function closeSubNav() {
    closeTertiarySubNav();
    closeSecondarySubNav();
    $('.sitenav-primary__ul').removeClass('overflow-hidden');
}

/**
 * Close the mobile navigation menu
 */
function closeMobileNav() {
    module.exports.closeSubNav();
    $sitenavMenu.closeMenuItem();
    $('html').removeClass('menu-active');
    /* wait for menu close animation */
    setTimeout(function () {
        $sitenav.closeMenuItem();
        module.exports.removeMobileMenuOffset();
    }, 500);
    $.enableScroll();
}

/**
 *  Close the desktop navigation menu
 */
function closeDesktopMenu() {
    $('.sitenav-primary__li.open, .sitenav-secondary__li.open').closeMenuItem();
}

/**
 * Back or close the mobile navigation menu
 */
function backMobileNav() {
    const openSecondaryLevel = $('.sitenav-secondary.open').length;
    const openTertiaryLevel = $('.sitenav-tertiary__ul.open').length;

    if (openTertiaryLevel) {
        closeTertiarySubNav();
    } else if (openSecondaryLevel) {
        closeSecondarySubNav();
    }
}

/**
 * Mouse events
 */
function mouseEvents() {
    // only for desktop

    if (breakpoints.isHighRes()) {
        let $openLi = null;
        let hasMouseEnteredSecondaryArea = false;
        let timeoutHandle = null;

        $sitenav
            .on('mouseleave.breakpointDependent', '.sitenav-primary__li', function () {
                $(this).find('.open').removeClass('open');
            });

        $sitenav
            .on('click.breakpointDependent', '.sitenav-secondary__li', function () {
                $(this).parent().find('.open').removeClass('open');
                $(this).openMenuItem();
            });

        $sitenav
            .on('mouseleave.breakpointDependent', '.sitenav-secondary', function () {
                hasMouseEnteredSecondaryArea = true; // If leaving sitenav-secondary then mouse has been in sitenav-secondary
                if ($openLi) {
                    timeoutHandle = setTimeout(function () {
                        $openLi.closeMenuItem();
                    }, 400);
                }
            });

        $sitenav
            .on('mouseenter.breakpointDependent', '.sitenav-secondary', function () {
                clearTimeout(timeoutHandle);
                if ($openLi) {
                    $openLi.openMenuItem();
                }
            });

        $sitenav
            .on('mouseenter.breakpointDependent', '.sitenav-primary__li', function () {
                clearTimeout(timeoutHandle);
                hasMouseEnteredSecondaryArea = false;

                if ($openLi !== null) {
                    $openLi.closeMenuItem(); // mouse moved to another sitenav-primary__li
                }

                $(this).openMenuItem();
                $openLi = $(this);
            });

        $sitenav
            .on('mouseleave.breakpointDependent', $openLi, function () {
                if (!hasMouseEnteredSecondaryArea && $openLi !== null) { // Want menu to close instantly if mouse has not entered sitenav-secondary
                    $openLi.closeMenuItem();
                    hasMouseEnteredSecondaryArea = false;
                }
            });
    }

    // only for mobile
    if (breakpoints.isLowRes()) {
        // open menu
        $siteheaderOpen
            .on('click.breakpointDependent', function (e) {
                e.preventDefault();
                module.exports.openMobileNav();
            });

        // open primary menu level
        $sitenav
            .on('click.breakpointDependent', '.sitenav-primary__a', function (e) {
                if (module.exports.openSubNav(this)) {
                    e.preventDefault();
                }
            });

        // close menu
        $sitenav
            .on('click.breakpointDependent', function (e) {
                if (e.target === this) {
                    e.preventDefault();
                    module.exports.closeMobileNav();
                }
            });

        $sitenavClose
            .on('click.breakpointDependent', function (e) {
                if (e.target === this) {
                    e.preventDefault();
                    module.exports.closeMobileNav();
                }
            });

        // open secondary menu level
        $sitenav
            .on('click.breakpointDependent', '.sitenav-secondary__a', function (e) {
                if ($(this).siblings('ul.sitenav-tertiary__ul').length) {
                    e.preventDefault();
                    toggleSubNav(this, 'ul');
                }
            });

        // previous menu
        $(document)
            .on('click.breakpointDependent', '.js-action__menuback', function (e) {
                e.preventDefault();
                module.exports.backMobileNav();
            });
    }
}

/**
 * Large Screen Touch Events
 */
function desktopTapEvents() {
    const tapOpenMenu = function (menuItem) {
        $(menuItem).siblings().closeMenuItem();
        $(menuItem).openMenuItem();
        $sitenavMenu.loseFocus(closeDesktopMenu);
    };
    $('.sitenav-primary__li, .sitenav-secondary__li').on('touchend', 'a', function (e) {
        const menuItem = $(this).parent();
        if (breakpoints.isCurrentBreakpoint('lg', 'xl') && !$(menuItem).hasClass('open') && $(menuItem).find('ul').length) { // check that menu item has dropdown menu
            e.preventDefault(); // if menu is not already open, prevent link
            tapOpenMenu(menuItem);
        }
    });
}

/**
 * Keyboard events
 */
function keyboardEvents() {
    // Close menu when it loses focus
    $sitenavMenu.on('focus', '*', function () {
        $sitenavMenu.loseFocus(closeDesktopMenu);
    });

    // on keyboard navigation focus
    if (breakpoints.isHighRes()) {
        $sitenav
            .on('focus', '.sitenav-primary__li', function () {
                $(this).openMenuItem();
            });
    }
}

/**
 * Focus event handlers
 */
function focusEvents() {
    // allows Screen Readers to announce aria-expanded state
    if (breakpoints.isHighRes()) {
        $sitenav
            .on('focus', '.sitenav-secondary__li', function () {
                $(this).focusMenuItem();  // allows secondary menu items to receive focus to toggle collapse/expanded state for Screen Readers
            });
    }
}

/**
 * Miscellaneous events
 */
function miscEvents() {
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('hide');
    });

    // There are "Sign In" links in checkout that need to trigger the account menu to open...
    $('.js-action__account-open').on('click', function (e) {
        const hasAccountIcon = $('.js-action__account').length;
        const $siteHeaderAccount = $('.navbar-header .siteheader__account');
        if (hasAccountIcon) {
            e.preventDefault();
            e.stopPropagation();

            if (breakpoints.isLowRes()) {
                const loginPage = $siteHeaderAccount.find('.js-action__account').attr('href');
                if (loginPage !== '') {
                    window.location.replace(loginPage);
                }
            } else {
                $(document).trigger('accountDrawer:show');
            }
        }
    });
}

/**
 * Determines if the window is scrolled
 */
function windowIsScrolled() {
    const isScrolled = $window.scrollTop() > 0;
    $body.toggleClass('window-is-scrolled', isScrolled);
}

/**
 * Events triggered on page scroll
 */
function scrollEvents() {
    const windowIsScrolledDebounce = _.throttle(windowIsScrolled, 50);

    /**
     * Passive event listeners improve scrolling performance: https://web.dev/uses-passive-event-listeners/
     * All our supported browsers support passive listeners: https://caniuse.com/passive-event-listener
     * JQuery will not support passive scroll listeners until v4 (in development): https://github.com/jquery/jquery/issues/2871
     */
    document.addEventListener('scroll', windowIsScrolledDebounce, { passive: true });
}

/**
 * Events triggered on screen resize
 */
function resizeEvents() {
    let lastWidth = $window.width();
    const isWidthResized = debounce(function () {
        if ($window.width() !== lastWidth) {
            lastWidth = $window.width();
            $window.trigger('widthResize');
        }
    }, 300);

    const reloadEvents = function () {
        // clear all breakpoint dependent events
        $('*').off('.breakpointDependent');
        module.exports.mouseEvents();

        if (breakpoints.isHighRes()) {
            module.exports.closeMobileNav();
        }
    };

    // reload events on screen resize
    $window.on('resize.reloadEvents', isWidthResized);
    $window.on('widthResize.reloadEvents', reloadEvents);
}

module.exports = {
    // expose methods for use within brand cartridges
    openMobileNav: openMobileNav,
    openSubNav: openSubNav,
    closeSecondarySubNav: closeSecondarySubNav,
    closeTertiarySubNav: closeTertiarySubNav,
    closeSubNav: closeSubNav,
    closeMobileNav: closeMobileNav,
    backMobileNav: backMobileNav,
    // expose events for use within brand cartridges
    mouseEvents: mouseEvents,
    keyboardEvents: keyboardEvents,
    keyboardNav: keyboardNav,
    countrySelectorKeyboardNav: countrySelectorKeyboardNav,
    desktopTapEvents: desktopTapEvents,
    miscEvents: miscEvents,
    scrollEvents: scrollEvents,
    resizeEvents: resizeEvents,
    setMobileMenuOffset: setMobileMenuOffset,
    removeMobileMenuOffset: removeMobileMenuOffset,
    focusEvents: focusEvents,
    lazyLoadAccountContent: lazyLoadAccountContent
};
