'use strict';

var emailSpellChecker = require('@zootools/email-spell-checker');

/**
 * Handles the creation & update of an email suggestion alert
 * @param {Object} $emailInput Email input form that triggers the email suggestion
 * @returns {Object} Object containing the created alert
 */
function setUpAlert($emailInput) {
    if ($emailInput) {
        var emailSuggestionConfig = window.resources.emailSuggestion;
        const emailSuggestion = emailSpellChecker.run({
            email: $emailInput.val(),
            domains: [...emailSpellChecker.POPULAR_DOMAINS, ...emailSuggestionConfig.additionalDomains]
        });

        if (emailSuggestion) {
            if ($emailInput.data('alert')) {
                // Update the existing suggestion based on updated input
                $emailInput.data('alert').find('.js-suggested-email').text(emailSuggestion.full);
            } else {
                // Create & Display Alert if there is an email suggestion
                var $alert = $emailInput.closest('.form-group').alert({
                    content: `<p class="mb-0 text-break">${emailSuggestionConfig.text.prompt} <span class="js-suggested-email font-weight-bold">${emailSuggestion.full}</span>?</p>
                    <button class="js-email-fix btn btn-link mt-2 font-weight-bold">${emailSuggestionConfig.text.fix}</button>`,
                    theme: 'warning',
                    autoClose: false,
                    dismissible: false
                }).addClass('mb-0');

                // Set the alert to be related to the email input
                $emailInput.data('alert', $alert);
                $alert.data('input', $emailInput);

                $alert.on('alert.shown', function () {
                    $('.js-email-fix').on('click', function (e) {
                        e.preventDefault();
                        var currentSuggestion = $(this).closest('.alert__content').find('.js-suggested-email').text();

                        $emailInput.val(currentSuggestion)
                            .trigger('invalid', this.validity)
                            .removeData('alert');

                        $alert.close();
                    });
                });

                return $alert;
            }
        }
    }
    return null;
}

module.exports = function () {
    let $emailSuggestionAlert;

    $('body').on('input', 'input[type=email]', function () {
        const $emailInput = $(this);

        // Check the email for spelling suggestions
        if (window.resources.emailSuggestion.enabled) {
            var $alert = $emailInput.data('alert');

            // If the user has entered the provided suggestion, don't find a new suggestion & remove the alert
            if ($alert && $emailInput.val() === $alert.find('.js-suggested-email').text()) {
                $emailInput.removeData('alert');
                $alert.close();
            } else {
                // Check if the input is a valid email, and delay the alert to allow the user to finish typing
                // TODO GSD-16278: remove email regex declaration, use declaration in email.properties > email.regex
                // eslint-disable-next-line no-useless-escape
                var regex = /^[\w.%+\-]+@[\w.\-]+\.[\w]{2,6}$/;
                if (regex.test($emailInput.val())) {
                    setTimeout(() => {
                        var $newAlert = setUpAlert($emailInput);
                        // only assign a valid suggestion alert
                        if ($newAlert) {
                            $emailSuggestionAlert = $newAlert;
                        }
                    }, 200);
                }
            }
        }
    });

    $('body').on('focus', 'input, select, textarea', function () {
        // If the user navigates to another input that is not related to the email suggestion, close it.
        if (window.resources.emailSuggestion.enabled && $emailSuggestionAlert && $emailSuggestionAlert.length) {
            var $input = $emailSuggestionAlert.data('input');
            if ($input && $input.length && $input[0] !== this) {
                $emailSuggestionAlert.data('input').removeData('alert');
                $emailSuggestionAlert.close();
                $emailSuggestionAlert = null;
            }
        }
    });
};
