'use strict';

const Swiper = require('swiper/swiper-bundle.min.js');
const breakpoints = require('../components/breakpoints');
const utils = require('../components/utils');
const onModel = require('./onModelDescription');

let $mainGallery;
let $thumbnailGallery;

// swiper instances
let swiperMain;
let swiperThumb;

// video player instance
let player;

/**
 * Initialize Swiper Gallery
 * @param {jQuery} $main jQuery reference to main gallery element
 * @param {jQuery|undefined} $thumbnail jQuery reference to thumbnail gallery element
 */
function initSwiperGallery($main, $thumbnail) {
    const swiperGalleryMain = $main[0];
    const swiperConfigMain = {
        centeredSlides: true,
        slidesPerView: 1,
        zoom: { toggle: false },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            disabledClass: 'd-none'
        },
        loop: false
    };
    const removeSlideSelectors = [
        '.product-quickview .video-slide'
    ];

    if ($thumbnail.length) {
        const swiperGalleryThumb = $thumbnail[0];

        swiperThumb = new Swiper(swiperGalleryThumb, {
            width: 44,
            slidesPerView: 'auto',
            loop: false,
            threshold: 1000 // arbitrary high value to prevent cancellation of click event
        });

        swiperConfigMain.thumbs = {
            swiper: swiperThumb
        };

        // Prevent screenreader from reading all alt tags whenever the gallery is updated
        $thumbnail.find('.swiper-wrapper').removeAttr('aria-live');
    }

    $(removeSlideSelectors.join(',')).each(function (i, slide) {
        $(slide).remove();
    });

    swiperMain = new Swiper(swiperGalleryMain, swiperConfigMain);

    // Prevent screenreader from reading all alt tags whenever the gallery is updated
    $main.find('.swiper-wrapper').removeAttr('aria-live');
}

/**
 * Initialize zoom container
 * @param {Object} $zoomModal: Reference to zoom container
 * @param {number} zoomIndex: The index to zoom on init
 */
function initZoom($zoomModal, zoomIndex) {
    const $zoomViewer = $zoomModal.find('.js-product-zoom-viewer');
    const $bigImg = $zoomViewer.find('img');

    // On thumbnail click inside zoom viewer, change to the zoomed view of the clicked image
    $zoomModal
        .on('click', '.js-product-thumb', function (e) {
            e.preventDefault();
            const $elem = $(this);
            const hiResImage = $elem.data('img-hi-res');
            const altZoomThumbSubstring = 'Zoom thumbnail:';
            const altZoomSubstring = 'Zoom:';

            // Get alt description from thumbnail and modify for zoom image
            let alt = $elem.find('img').attr('alt');

            if (alt.substring(altZoomThumbSubstring)) {
                alt = alt.replace(altZoomThumbSubstring, altZoomSubstring);
            }

            $zoomModal.find('.js-product-thumb').removeClass('active');
            $elem.addClass('active');
            $bigImg.attr('src', hiResImage);
            $bigImg.attr('alt', alt);
        })
        // Move around the zoomed image with mouse
        .on('mousemove', '.js-product-zoom-viewer', function (e) {
            const viewerWidth = $zoomViewer.outerWidth();
            const viewerHeight = $zoomViewer.outerHeight();
            const difWidth = viewerWidth - $bigImg.outerWidth();
            const difHeight = viewerHeight - $bigImg.outerHeight();
            const viewerOffset = $zoomViewer.offset();
            // Track mouse movement on desktop
            let x = 0;
            let y = 0;
            let imgX;
            let imgY;
            if (difWidth < 0) {
                x = e.pageX - viewerOffset.left;
                imgX = difWidth * ((x / viewerWidth) - 0.5);
            } else {
                imgX = 0;
            }
            if (difHeight < 0) {
                y = e.pageY - viewerOffset.top;
                imgY = difHeight * ((y / viewerHeight) - 0.5);
            } else {
                imgY = 0;
            }
            $bigImg.css('transform', `translate3d(${imgX}px, ${imgY}px, 0)`);
        })
        .on('hide.bs.modal', function () {
            // slide swiper gallery to image that was open in zoom
            const activeIndex = $zoomModal.find('.js-product-thumb.active').data('index');
            swiperMain.slideTo(activeIndex);
        })
        .on('hidden.bs.modal', function () {
            // remove the modal markup completely
            $(this).remove();
        });

    // On init, open the last image that was being viewed in the product gallery
    const $activeThumb = $zoomModal.find('.js-product-thumb[data-index="' + zoomIndex + '"]');
    if ($activeThumb.length) {
        $activeThumb.trigger('click');
    }
}

/**
 * Initialize video player
 */
function initVideoPlayer() {
    const $videoSlide = $mainGallery.find('.js-video-player');

    // exit if no video player exists
    if (!$videoSlide.length) return;

    const playerHost = $videoSlide.data('host');
    const videoId = $videoSlide.data('video-id');
    const playerId = playerHost === 'youtube' ? 'ytPlayer_' + videoId : 'vimeoPlayer_' + videoId;

    // preload youtube video player dependency
    if (playerHost === 'youtube') {
        const tagId = 'youtube-api-script';
        const tagExists = document.getElementById(tagId);
        if (!tagExists) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            tag.id = tagId;
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    }

    // wait for video player node to be created
    const videoPlayerObserver = new MutationObserver(function () {
        player = window.videoPlayers[playerId];
        if (player) {
            videoPlayerObserver.disconnect();
        }
    });
    videoPlayerObserver.observe($videoSlide[0], { childList: true, subtree: true });

    // pause product video on slide change
    swiperMain.on('slideChange', function () {
        if (player) {
            if (playerHost === 'youtube') {
                player.pauseVideo();
            } else {
                player.pause();
            }
        }
    });
}

/**
 * Initialize product gallery events
 */
function initGalleryEvents() {
    $mainGallery
        .off('.zoom')
        // prevent swiping when zoom icon is being interacted with
        .on('mousedown.zoom', '.js-pdp-gallery-zoom', function () {
            if (swiperMain.allowTouchMove) {
                swiperMain.allowTouchMove = false;

                setTimeout(() => {
                    swiperMain.allowTouchMove = true;
                }, 500);
            }
        })
        // When zoom button is clicked, enable zoom
        .on('click.zoom', '.js-pdp-gallery-zoom', function () {
            if (breakpoints.isLowRes()) {
                swiperMain.zoom.toggle();
            } else {
                const $imageClicked = $(this).closest('.js-product-image');
                let url = $imageClicked.closest('.js-product-gallery').data('zoom-url');

                // If a color swatch has been selected
                const $selectedColorSwatch = $('[data-attr="color"] .color-value.selected');
                if ($selectedColorSwatch.length && url) {
                    const pid = $('.product-detail').data('pid').toString();
                    const color = $selectedColorSwatch.data('attr-value');
                    const urlPid = utils.getParamsFromURL(url).pid;

                    if (pid !== urlPid) {
                        url = utils.updateURLParam(url, 'pid', pid);
                    }
                    if (color && pid) {
                        // get base pid without appended _S
                        const basePid = pid.split('_')[0];
                        url = utils.appendParamToURL(url, 'dwvar_' + basePid + '_color', color);
                    }
                }

                if (!url) { return; }

                $.spinner().start();
                $.ajax({
                    url: url,
                    method: 'GET',
                    dataType: 'html',
                    success: function (html) {
                        $.spinner().stop();

                        const modalHTML = utils.createModalMarkup(html, { modalSizeClass: 'modal-fluid' });
                        let $zoomModal = $(modalHTML).modal();

                        let imageIndexToZoom = 0;
                        if ($imageClicked.data('index')) {
                            imageIndexToZoom = $imageClicked.data('index');
                        }

                        // fix for IE:
                        const ua = window.navigator.userAgent;
                        if (ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1) {
                            $zoomModal.one('shown.bs.modal', function () {
                                const $modalBody = $zoomModal.find('.modal-body');
                                const zoomViewerHeight = $modalBody.find('.js-product-zoom-viewer').outerHeight();
                                const modalBodyPadding = parseFloat($modalBody.css('padding-top')) + parseFloat($modalBody.css('padding-bottom'));
                                $modalBody.css('height', zoomViewerHeight + modalBodyPadding);
                            });
                        }

                        initZoom($zoomModal, imageIndexToZoom);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });

    // use to swap image low/high resolution when zoom button is clicked
    swiperMain.on('zoomChange', function (swiper, scale, imageEl) {
        // zoomChange fires with these params: swiper, scale, imageEl, slideEl
        let setImageUrl = $(imageEl).data('lowres');
        if (scale !== 1) {
            setImageUrl = $(imageEl).data('hires');
        }
        $(imageEl).attr('src', setImageUrl);
    });

    // re-initialize video player when swatch interaction refreshes gallery
    $(document).on('video:player:update', function () {
        initVideoPlayer();
    });
}

/**
 * Disable/hide zoom button
 */
function disableZoom() {
    $('.js-pdp-gallery-zoom').addClass('d-none');
}

/**
 * Get jQuery elements required to build image gallery via configuration
 * @param {Object} config allowable options specified in configDefault
 * @returns {Object} jQuery elements required to build image gallery
 */
function getGalleryElements(config) {
    const configDefault = {
        hasThumbnails: true,
        isQuickview: false,
        gallerySelector: '.js-product-gallery',
        thumbnailSelector: '.js-product-gallery-thumb',
        galleryContainerSelector: '.primary-images'
    };

    const options = $.extend({}, configDefault, config);

    const galleryElements = {};
    const $galleryContainer = $(options.galleryContainerSelector).first();

    if ($galleryContainer.length) {
        const $gallery = $galleryContainer.find(options.gallerySelector).first();

        if ($gallery.length) {
            galleryElements.$gallery = $gallery;
            galleryElements.$galleryContainer = $galleryContainer;
            galleryElements.isQuickview = !!options.isQuickview;

            if (options.hasThumbnails) {
                const $thumbnail = $galleryContainer.find(options.thumbnailSelector).first();

                if ($thumbnail.length) {
                    galleryElements.$thumbnail = $thumbnail;
                    galleryElements.hasThumbnails = true;
                }
            }
        }
    }

    return galleryElements;
}

module.exports = function (galleryConfig) {
    const galleryElements = getGalleryElements(galleryConfig);

    $mainGallery = galleryElements.$gallery;
    $thumbnailGallery = galleryElements.$thumbnail;
    const hasThumbnails = galleryElements.hasThumbnails;
    const isQuickview = galleryElements.isQuickview;

    if (!$mainGallery || !$mainGallery.length || $mainGallery[0].swiper) {
        return {
            init: function () {}
        };
    }

    this.$gallery = $mainGallery;

    if (hasThumbnails) {
        this.$thumbnailGallery = $thumbnailGallery;
    }

    this.init = function () {
        initSwiperGallery($mainGallery, $thumbnailGallery);

        if (!isQuickview) {
            initVideoPlayer();
            initGalleryEvents();
            onModel.triggerOnModelDescription();
        } else {
            disableZoom();
        }
    };

    return this;
};
