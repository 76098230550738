'use strict';

/**
 * Creates and loads a membership drawer.
 * @param {Event} $openDrawerButton button that opens drawer
 * @returns {Object} jQuery object of created drawer
 */
function initMembershipDrawer($openDrawerButton) {
    const $membershipDrawer = $.drawer({
        src: $openDrawerButton.data('drawer-url'),
        size: 'sm',
        returnFocusTo: $openDrawerButton
    }).on('click', '.js-membership-switch-drawer', () => {
        // When navigating to another drawer, delay closing this drawer until the next drawer opens
        $(document).one('shown.drawer', '.drawer', () => {
            $membershipDrawer.close();
        });
    }).on('click', '.js-membership-submit-completion', (e) => {
        const $initiator = $(e.currentTarget);

        $.ajax({
            url: $initiator.data('submit-completion-url'),
            method: 'POST'
        });

        $membershipDrawer.close();
    });

    return $membershipDrawer;
}

/**
 * Adds membership specific event listeners
 */
function initMembershipEventListeners() {
    $(document).on('click', '.js-membership-drawer, .js-membership-partners-drawer', function (e) {
        const $target = $(e.currentTarget);

        if ($target.hasClass('js-membership-partners-drawer')) {
            $target.data('drawer-url', window.resources.membership.partnersDrawerURL);
        }

        const $membershipDrawer = initMembershipDrawer($target);
        $membershipDrawer.on('contentAsyncLoaded.drawer', function () {
            var $stepBar = $(this).find('.membershipStepBar');
            if ($stepBar) {
                $stepBar.stepBar();
            }
        });
    });
}

module.exports = {
    init: initMembershipEventListeners
};
