'use strict';

/**
 * Contains Functionality for Global Marketing Banner
 * Comprised of the AEM Marketing Banner Controller component and one or multiple Marketing Banner Child components
 * This is slotted in the nav-1 content slot on all brands
 */

module.exports = {
    init: function () {
        if (!$('.js-global-marketing-banner').length) {
            return;
        }

        const $mobileMessages = $('.global-marketing-banner-messages .mobileMessage');
        const $desktopMessages = $('.global-marketing-banner-messages .desktopMessage');
        const $bannerIcon = $('.marketing-banner__details');

        $mobileMessages.first().show(); // show the first message - mobile
        $desktopMessages.first().show(); // show the first message - desktop

        const totalBannerMessages = $mobileMessages.length; // equates to total number of marketing banner child components in aem
        let currentMessageIndex = 0;
        let setIntervalMarketingBannerId;
        let nextMessageIndex;

        /**
         * Starts the banner message rotation animation
         * Animation also will not run unless there is more than one marketing banner child message
        */
        function playMessageRotation() {
            nextMessageIndex = (currentMessageIndex + 1) % totalBannerMessages;

            $mobileMessages.filter(':visible').fadeOut(null, () => {
                $mobileMessages.eq(nextMessageIndex).fadeIn(); // fadeIn the matching index message
                currentMessageIndex = nextMessageIndex;
            });

            $desktopMessages.filter(':visible').fadeOut(null, () => {
                $desktopMessages.eq(nextMessageIndex).fadeIn(); // fadeIn the matching index message
                currentMessageIndex = nextMessageIndex;
            });

            $bannerIcon.fadeOut(null, () => {
                $bannerIcon.fadeIn(); // fadeIn the matching index message
            });
        }

        /**
         * Stops the banner message rotation animation
        */
        function pauseMessageRotation() {
            clearInterval(setIntervalMarketingBannerId);
        }
        /**
        * Starts the interval and calls the animation function - interval is set to 5 seconds
        */
        function startInterval() {
            if (totalBannerMessages > 1) { // only start rotation if more than one message
                setIntervalMarketingBannerId = setInterval(playMessageRotation, 5000);
            }
        }

        startInterval(); // start rotation

        /**
        * If the drawer is opened, shows a span that reads 'Hide Offers'.
        * Customized mobile and desktop messages are hidden when drawer is opened.
        * When the drawer is closed, the mobile/desktop messages & spans are shown, then the 'Hide Offers' message is hidden.
        * @param {boolean} isPaused controls if the animation is playing or paused
        */
        function toggleBannerText(isPaused) {
            if (isPaused) { // if drawer is open
                // display the hide offers text span and hide rotating message els
                $('.global-marketing-banner-messages__hide-offers').removeClass('d-none');
                // hide the rotating messages
                $('.global-marketing-banner-messages span').addClass('d-none');
                // updates svg
                $('.marketing-banner__details').attr('aria-expanded', 'true');
            } else { // if drawer is closed
                // hide the hide offers text span
                $('.global-marketing-banner-messages__hide-offers').addClass('d-none');
                // display the mobile/desktop text spans
                $('.global-marketing-banner-messages span').removeClass('d-none');
                // updates svg
                $('.marketing-banner__details').attr('aria-expanded', 'false');
            }
        }

        // Controls what the banner reads when the drawer is opened vs closed
        $('body').on('show.drawer', '.marketing-banner__drawer', function () { // drawer opens
            pauseMessageRotation(); // pause rotation of messages
            toggleBannerText(true); // show the "Hide Offer" message, hide the rotating banner messages
        }).on('hidden.drawer', '.marketing-banner__drawer', function () { // drawer closes
            startInterval(); // start rotation
            toggleBannerText(false); // show the rotating banner messages, hide the "Hide Offers" message
        });
    }
};
