/**
 * SMS Consent Form Submission Handler
 *
 * This module handles the form submission process for the SMS consent form.
 * It is validates the form, and sends the form data to the server using an AJAX POST request.
 */

'use strict';

module.exports = {
    init: function () {
        $(document).on('submit', '.js-smsConsentForm', function (e) {
            e.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');

            if (!$form.validateForm()) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        if (data.nextStepMarkup) {
                            $form.closest('.drawer__body').html(data.nextStepMarkup);
                        } else {
                            $form.closest('.drawer').find('.close').click();
                        }
                        $.spinner().stop();
                    } else {
                        // Show the invalid state of the input field
                        var $inputWraper = $form.find('.form-group');

                        $inputWraper.addClass('is-invalid');
                        $form.find('.form-control').addClass('is-invalid');
                        $inputWraper.find('.invalid-feedback').text(data.msg);
                        $inputWraper.find('.form-help-text').hide();

                        $.spinner().stop();
                    }
                },
                error: function () {
                    console.error('Failed to submit the form.');
                    $.spinner().stop();
                }
            });
        });
    }
};
