/* global registerButtonAsRecaptchaTrigger */
'use strict';

/**
 * Adds password visibilty icon on each
 * password field and their respective
 * event listeners
 */
function addPasswordVisibility() {
    const $passwordField = $('input[type=password]');

    $passwordField.each(function () {
        const $thisPWField = $(this);

        if (!$thisPWField.siblings('.js-show-password').length) {
            // add toggle icon to the PW field
            const $icon = $('<svg class="js-show-password show-password-icon"><use href="#visibility_on"></use></svg>');
            $thisPWField.after($icon);

            // toggle the PW visibility
            $icon.on('click', function () {
                const currentType = $thisPWField.attr('type');
                $thisPWField.attr('type', currentType === 'password' ? 'text' : 'password');
                $icon.find('use').attr('href', currentType === 'password' ? '#visibility_off' : '#visibility_on');
            });
        }
    });
}

module.exports = {
    init: function () {
        addPasswordVisibility();

        $(document).on('form:created', function () {
            addPasswordVisibility();
        });
    }
};
