/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
'use strict';

(function ($) {
    /**
     * circularProgressIndicator - a jQuery plugin to display a visual indicator
     *
     * Structure of the markup:
     *
     * @param {Object} options JSON object containing optional settings.
     * @return {Object} jQuery object of the selected element.
     */
    $.fn.progressArc = function (options) {
        const defaults = {
            lineWidth: 2,
            progress: 0.33,
            color: '#007AB8', // defaults to $brand-compass-blue
            background: '#ddd',
            duration: 2000, // animation duration. 0 for instant.
            ease: 'easeInOut',
            indeterminate: false
        };

        // Converts Degrees of Angle to Radians
        const _degToRad = function (deg) {
            return (deg * Math.PI) / 180;
        };

        // Draws the initial arc, which will act as the background progress bar
        const _drawArcPath = function (ctx, settings) {
            ctx.beginPath();
            ctx.lineWidth = settings.lineWidth * 2;
            ctx.strokeStyle = settings.background;
            ctx.arc(settings.center.x, settings.center.y, settings.radius, 0, 2 * Math.PI);
            ctx.stroke();
        };

        // This function will draw the progress bar foreground
        const _drawArc = function (ctx, progress, settings) {
            const orientation = _degToRad(-90);
            const startAngle = orientation;
            const endAngle = (2 * Math.PI * progress) + orientation;
            const color = settings.color;

            ctx.beginPath();
            ctx.lineWidth = settings.lineWidth * 2;
            ctx.strokeStyle = color;
            ctx.arc(settings.center.x, settings.center.y, settings.radius, startAngle, endAngle);
            ctx.stroke();
        };

        const _clamp = function (x, min, max) {
            return Math.max(Math.min(x, max), min);
        };

        const _ease = function (func, start, current, duration) {
            // ease formulas from https://easings.net/
            const x = _clamp((current - start) / duration, 0, 1);
            switch (func) {
                case 'easeIn':
                    return Math.pow(x, 4);
                case 'easeOut':
                    return 1 - Math.pow(1 - x, 4);
                case 'easeInOut':
                    if (x < 0.5) {
                        return 8 * Math.pow(x, 4);
                    }
                    return 1 - (8 * Math.pow(x - 1, 4));
                case 'linear':
                default:
                    return x;
            }
        };

        const _renderFrame = function (ctx, startTimeStamp, settings) {
            const currentTimeStamp = performance.now();
            if (!startTimeStamp) {
                startTimeStamp = currentTimeStamp;
            }
            const startProgress = ctx.startProgress || 0;
            const previousProgress = ctx.previousProgress || 0;
            const ease = _ease(settings.ease, startTimeStamp, currentTimeStamp, settings.duration);

            const step = (ease * (settings.progress - startProgress)) + startProgress;

            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            _drawArcPath(ctx, settings);
            _drawArc(ctx, step, settings);

            // if (step < settings.progress) {
            const percentDelta = Math.abs(step - previousProgress);
            if (ease > 0.999 || (ease > 0.5 && percentDelta < 0.00001)) { // ease out is imperceptible
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                _drawArcPath(ctx, settings);
                _drawArc(ctx, settings.progress, settings);

                ctx.isAnimating = false;
                cancelAnimationFrame(ctx.frameId);

                ctx.startProgress = step;
            } else {
                ctx.frameId = requestAnimationFrame(function () {
                    _renderFrame(ctx, startTimeStamp, settings);
                });
            }

            ctx.previousProgress = step;
        };

        // If indeterminate value is set to true, by data-attr or js, this will render a spinner animation for the circular progress indicator.
        const _renderSpinner = function (ctx, startTimeStamp, settings) {
            const currentTimeStamp = performance.now();
            if (!startTimeStamp) {
                startTimeStamp = currentTimeStamp;
            }
            let ease = _clamp((currentTimeStamp - startTimeStamp) / settings.duration, 0, 2);
            if (ease === 2) {
                ease = 0;
                startTimeStamp = currentTimeStamp;
            }

            const _drawArc2 = function (progress) {
                const startAngle = Math.PI * progress;
                const endAngle = (2 * Math.PI * progress) + startAngle;
                const color = settings.color;

                ctx.beginPath();
                ctx.lineWidth = settings.lineWidth * 2;
                ctx.strokeStyle = color;
                if (progress > 1) {
                    ctx.arc(settings.center.x, settings.center.y, settings.radius, endAngle, startAngle);
                } else {
                    ctx.arc(settings.center.x, settings.center.y, settings.radius, startAngle, endAngle);
                }
                ctx.stroke();
            };

            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            _drawArcPath(ctx, settings);
            _drawArc2(ease);

            ctx.frameId = requestAnimationFrame(function () {
                _renderSpinner(ctx, startTimeStamp, settings);
            });
        };

        return $(this).each(function () {
            // grab the .progress element, and add progress indicator bar element and progress text element
            const $progress = $(this);
            const $progressWidth = $progress.innerWidth() * 2;
            const $progressHeight = $progress.innerHeight() * 2;
            const $progressCanvas = $(`<canvas class="progress__canvas" width="${$progressWidth}" height="${$progressHeight}"></canvas>`);
            const $progressText = $('<span class="progress__text"></span>');

            if ($progress.find('.progress__canvas').length) { // check if progress indicator has a canvas already to avoid duplication
                return this;
            }
            $progress.append($progressCanvas);
            $progress.append($progressText);
            defaults.radius = Math.round(($progressWidth / 2) - (defaults.lineWidth * 2)); // radius is half the canvas width minus lineWidth

            // get the canvas element, return drawing context of 2d
            const ctx = $progressCanvas[0].getContext('2d');
            const settings = $.extend({}, defaults, $progress.data(), options); // take obj arguments from defaults and options (if applicable) to create one object
            settings.center = { // find the center based on canvas width/height
                x: ctx.canvas.width / 2,
                y: ctx.canvas.height / 2
            };

            ctx.frameId = requestAnimationFrame(function () {
                ctx.isAnimating = true;
                if (settings.indeterminate) {
                    _renderSpinner(ctx, null, settings);
                } else {
                    _renderFrame(ctx, null, settings);
                }
            });

            const displayValue = Math.round(settings.progress * 100); // round to nearest whole number
            $progressText.text(displayValue + '%');

            // If there are custom values, this will update defaults
            this.updateProgress = function (options) {
                cancelAnimationFrame(ctx.frameId);

                const newSettings = $.extend({}, settings, options);

                ctx.startProgress = ctx.previousProgress || 0;
                ctx.isAnimating = true;

                ctx.frameId = requestAnimationFrame(function () {
                    if (newSettings.indeterminate) {
                        _renderSpinner(ctx, null, newSettings);
                    } else {
                        _renderFrame(ctx, null, newSettings);
                    }
                });

                const displayValue = Math.round(newSettings.progress * 100); // round to nearest whole number
                $progressText.text(displayValue + '%');
            };

            return this;
        });
    };
}(jQuery));
