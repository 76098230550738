'use strict';

/**
 * Determines if a referrer is single use if it exists.
 * @returns {boolean} returns true referrer exists and is single use.
 */
function isSingleUseReferrer() {
    if (!document.referrer) {
        return false;
    }

    if (!window.resources || !window.resources.dataLayer) {
        // eslint-disable-next-line no-console
        console.error('window.resources.dataLayer not loaded');
        return false;
    }

    const referrerURL = new URL(document.referrer.toLowerCase());

    /**
     * Example: https://www.google.com/ yields a hostname of www.google.com
     * hostname will be blank for referrers like android-app://com.google.android.gm/ (GSD-12748)
     * in case they need to be supported in the future.
     */
    if (!referrerURL.hostname) {
        return false;
    }

    const domainMatchResults = /[^.]*\.[^.]+$/.exec(referrerURL.hostname);
    const domain = domainMatchResults ? domainMatchResults[0] : null;

    return (domain && window.resources.dataLayer.singleReferralDomains.includes(domain)) || false;
}

/**
 * If a referrer is single use, it captures the usage.
 * @returns {Object} returns objects with details on the capture.
 */
function captureSingleUseReferrer() {
    var result = {
        isSingleUse: isSingleUseReferrer(),
        previouslyCaptured: false
    };

    const sessionSpecificLocalStorageHelper = require('../helper/sessionSpecificLocalStorageHelper');

    // Do not change the storage key unless code is added to delete entries made under old key
    const storageKey = 'pageVisitReferrer.singleUseReferrerHistory';

    // Always fetch the value before exiting so the code can clear invalid values
    let singleUseReferrals = sessionSpecificLocalStorageHelper.getItem(storageKey);

    if (!result.isSingleUse) {
        return result;
    }

    singleUseReferrals = singleUseReferrals || {
        /**
         * localStorage is not intended to expire. As such, if the storage structure changes, the code will need to account for it.
         * In such a scenario, increase this version number and clear the stored data for older versions.
         */
        storageVersion: 1
    };

    // Lower case the URLs to be safe/consistent
    var currentUrl = window.location.href.toLowerCase();
    var referrer = document.referrer.toLowerCase();

    var currentUrlReferrers = singleUseReferrals[currentUrl];

    if (currentUrlReferrers && currentUrlReferrers.includes(referrer)) {
        result.previouslyCaptured = true;
        return result;
    }

    if (!currentUrlReferrers) {
        currentUrlReferrers = [];
        singleUseReferrals[currentUrl] = currentUrlReferrers;
    }

    currentUrlReferrers.push(referrer);
    sessionSpecificLocalStorageHelper.setItem(storageKey, singleUseReferrals);

    return result;
}

module.exports = {
    isSingleUseReferrer: isSingleUseReferrer,
    captureSingleUseReferrer: captureSingleUseReferrer
};
