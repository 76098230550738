'use strict';

const baseSearch = require('brand_core/components/search');

/**
 * Search event key handler
 */
function suggestionKeyHandler() {
    $('input.js-search-field').each(function () {
        const tabKey = 9;
        let timeout = null;
        $(this).on('keyup', function (e) {
            if (e.which !== tabKey) {
                clearTimeout(timeout);
                // Make a new timeout set to go off in 800ms
                timeout = setTimeout(() => baseSearch.getSuggestions(e.target), 500);
            }
        });

        $(this).on('focus', function () {
            $.disableScroll();
            baseSearch.toggleSuggestionsWrapperContent(this);
            // Copied from col_core
            const $searchOpen = $(this).closest(baseSearch.headerSearchContainerSelector);
            if (!$searchOpen.hasClass('search-mobile')) {
                // close search when it loses focus
                $searchOpen.loseFocus(baseSearch.tearDownSearch, '.globalbanner, header, .page, footer');
            }
        });
    });
}

/**
 * Search event open handler
 */
function searchClickOpenHandler() {
    $('.js-action-search').on('click', function (e) {
        e.preventDefault();
        const $searchContainer = $(e.target).closest(baseSearch.headerSearchContainerSelector);
        if ($searchContainer.hasClass('search-mobile')) {
            baseSearch.openMobileSearch($searchContainer);
        }
    });
}

/**
 * Initialize functions
 */
function init() {
    baseSearch.keyboardNav.init();
    baseSearch.searchEvents();
}

module.exports = function () {
    // Override for columbia
    baseSearch.suggestionKeyHandler = suggestionKeyHandler;
    baseSearch.searchClickOpenHandler = searchClickOpenHandler;
    init();
};
