/* global LazyLoad _satellite */
'use strict';

const breakpoints = require('../components/breakpoints');
const _ = require('underscore');

const $body = $('body');

/**
 * Loads all primary images when an event/interaction occurs
 * @param {Element} $tile Product tile containing images to be lazyloaded
 */
function lazyloadPrimaryImages($tile) {
    var $primaryImages = $tile.find('.tile__image--primary:not(.loaded)');
    $.each($primaryImages, function (i, primaryImage) {
        if (!$(primaryImage).data('ll-status')) {
            LazyLoad.load(primaryImage);
        }
    });
}

/**
 * Selects supplied swatch
 * @param {Element} swatch swatch to be selected
 */
function selectSwatch(swatch) {
    const $gridTile = $(swatch).closest('.js-grid-tile');
    const $hoverSwatch = $(swatch).closest('.js-color-swatch');
    // Modify ID selectors that contain characters that have special meaning in CSS
    const variantID = $.escapeSelector($hoverSwatch.data('variant-id'));

    // handle element selection
    $('.selected', $gridTile).removeClass('selected');
    $hoverSwatch.addClass('selected');
    $gridTile.find(`[data-img-variant-id=${variantID}]`).addClass('selected');

    // Change quickview url to show selected color variant
    $gridTile.find('.js-quickview').data('link', $hoverSwatch.data('quickview-url'));

    // Change product title url to show selected color variant
    $gridTile.find('.js-tile-link').attr('href', $hoverSwatch.attr('href'));

    // Change product title url for ratings to show selected color variant
    $gridTile.find('.js-tile-ratings').attr('href', $hoverSwatch.attr('href') + '#product-accordion-reviews');

    // Letting know compare feature about changing the swatch
    $gridTile.find('.js-compare-checkbox input').trigger('swatch-changed');

    // Lazy load the appropriate image
    lazyloadPrimaryImages($gridTile);
}

/**
 * Toggle swatch less/more control
 * @param {JQuery} selectedSwatch JQuery object for selected swatch
 */
function updateSwatchToggle(selectedSwatch) {
    const $tile = $(selectedSwatch).closest('.product-tile');
    const $toggle = $tile.find('.swatch--toggle');
    const $more = $toggle.find('.js-swatches-more');
    const $less = $toggle.find('.js-swatches-less');

    if (breakpoints.isLowRes()) {
        // On mobile, toggle more/less links
        if ($less.hasClass('d-none')) {
            // Hide More link --> Show Less link
            $more.addClass('d-none');
            $less.removeClass('d-none');

            // Override classes that hide responsive swatches
            $tile.find('.swatch:not(.js-swatches-toggle)').addClass('d-block');
        } else {
            // Hide Less link --> Show More link
            $more.removeClass('d-none');
            $less.addClass('d-none');

            // Override classes that hide responsive swatches
            $tile.find('.swatch:not(.js-swatches-toggle)').removeClass('d-block');
        }
    }
}

/**
 * Promote selected swatch in the "more swatches" section to be the
 * last of the visible tile swatches when "more swatches" are hidden.
 * This supports responsive swatch counts at all breakpoints.
 * @param {JQuery} selectedSwatch JQuery object for selected swatch
 * @param {string} currentBreakpoint current breakpoint
 */
function updateVisibleSwatches(selectedSwatch, currentBreakpoint) {
    const breakpointArray = ['sm', 'md', 'lg', 'xl'];

    // Generate array of responsive swatch--hide-* classes based off current breakpoint
    const hideClasses = currentBreakpoint ?
        breakpointArray.splice(breakpointArray.indexOf(currentBreakpoint)).map((bp) => 'swatch--hide-' + bp) : [];

    const $swatchContainer = $(selectedSwatch).closest('.swatch__container');
    const $visibleSwatches = $swatchContainer.find('.swatch--color')
        .not('[class*="swatch--hide-' + currentBreakpoint + '"]')
        .not('.swatch--toggle');
    const $visibleSwatchToHide = $($visibleSwatches[$visibleSwatches.length - 1]);
    const isSelectedSwatchVisible = $visibleSwatches.index($(selectedSwatch));

    if (isSelectedSwatchVisible < 0) {
        // Hide one of the original visible swatches
        // i.e. Swap places with the selected hidden swatch
        $visibleSwatchToHide.addClass(hideClasses);

        // Remove "hide classes" from clicked swatch
        $(selectedSwatch).removeClass(hideClasses);
    }
}

/**
 * Keyboard Accessibility Events
 */
function keyboardEvents() {
    $('.product-tile').off('.tilefocus').on('focusin.tilefocus', function (e) {
        // unselect any previously selected tiles
        $('.product-tile--hover').removeClass('product-tile--hover');
        $(e.target).closest('.product-tile').addClass('product-tile--hover');
    });

    // if focused element is not a child of a tile, unselect all tiles
    $(window).on('focusin', function (e) {
        if (!$(e.target).closest('.product-tile--hover').length) {
            $('.product-tile--hover').removeClass('product-tile--hover');
        }
    });
}

module.exports = {
    keyboardAccessibility: function () {
        $(document).ready(function () {
            if (breakpoints.isHighRes()) {
                keyboardEvents();
            }
        });
    },

    onGridScroll: function () {
        const $productGrid = $('.product-grid');

        if ($productGrid.length) {
            let preventSuppression = false;
            let preventSuppressionTimeout;
            let scrollTimeout;

            window.addEventListener('mousemove',
                _.throttle(function () {
                    if (breakpoints.isHighRes()) {
                        // enable hover styles by cancelling suppression from scrolling
                        $productGrid.removeClass('suppress-hover');
                        preventSuppression = true;
                        clearTimeout(preventSuppressionTimeout);

                        // prevent suppression from scroll for 1s after done moving mouse
                        preventSuppressionTimeout = setTimeout(function () {
                            preventSuppression = false;
                        }, 1000);
                    }
                }, 250),
                { passive: true });

            window.addEventListener('scroll',
                _.throttle(function () {
                    if (breakpoints.isHighRes()) {
                        // suppress hover styles while scrolling
                        if (!preventSuppression) {
                            $productGrid.addClass('suppress-hover');
                        }

                        clearTimeout(scrollTimeout);

                        // cancel hover style suppression 0.5s after done scrolling
                        scrollTimeout = setTimeout(function () {
                            $productGrid.removeClass('suppress-hover');
                        }, 500);
                    }
                }, 500),
                { passive: true });
        }
    },

    onTileHover: function () {
        $('body')
            .on('mouseenter', '.tile__image-container a', function () {
                var $primaryImage = $(this).find('.tile__image--primary');
                var $altImage = $(this).find('.tile__image--alt');
                if ($altImage.length <= 0 || $altImage.data('ll-status')) {
                    return;
                }
                var lazyLoadedCallback = function () {
                    $primaryImage.addClass('loaded-alt');
                };

                LazyLoad.load($altImage[0], {
                    callback_loaded: lazyLoadedCallback
                });
            })
            .on('mouseenter', '.js-grid-tile .product-tile', (e) => {
                if (breakpoints.isHighRes()) {
                    const $tileHover = $(e.target).closest('.product-tile');
                    const $carouselContainer = $tileHover.closest('.product-carousel, .slick-slider');
                    const isCarousel = $carouselContainer.length > 0;
                    const $hotSpotWrapper = $(e.target).closest('.hotSpot__content-wrapper');

                    // On desktop, load all primary images on hover so switching swatches does not produce a flash when images are switched.
                    lazyloadPrimaryImages($tileHover);

                    if (isCarousel) {
                        const $productCarousel = $carouselContainer.find('.swiper-container, .slick-track');

                        $carouselContainer.css('height', $carouselContainer.outerHeight());
                        $tileHover.addClass('product-tile--hover');

                        let tileHeight = $tileHover.outerHeight();
                        let carouselHeight = $productCarousel.outerHeight();

                        // only use tile height if it is larger than carousel container height
                        if (carouselHeight > tileHeight) {
                            tileHeight = carouselHeight;
                        }

                        $productCarousel.css('height', tileHeight);

                        if ($productCarousel.hasClass('swiper-container')) {
                            $productCarousel.css('position', 'absolute');
                        }
                    } else {
                        $tileHover.css('height', $tileHover.height()).addClass('product-tile--hover');

                        // if the hotspot component is being used on Tile hover, toggle the overflow of the product tile on hover
                        if ($hotSpotWrapper.length) {
                            $hotSpotWrapper.css('overflow', 'visible');
                        }
                    }
                }
            })
            .on('mouseleave', '.js-grid-tile .product-tile', (e) => {
                if (breakpoints.isHighRes()) {
                    const $tileHover = $(e.target).closest('.product-tile');
                    const $carouselContainer = $tileHover.closest('.product-carousel, .slick-slider');
                    const isCarousel = $carouselContainer.length > 0;
                    const $hotSpotWrapper = $(e.target).closest('.hotSpot__content-wrapper');

                    if (isCarousel) {
                        const $productCarousel = $carouselContainer.find('.swiper-container, .slick-track');

                        $carouselContainer.css('height', 'auto');
                        $tileHover.removeClass('product-tile--hover');
                        $productCarousel.css('height', 'auto').css('position', 'static');
                    } else {
                        $tileHover.css('height', 'auto').removeClass('product-tile--hover');

                        // if the hotspot component is being used on Tile hover, toggle the overflow of the product tile on hover
                        if ($hotSpotWrapper.length) {
                            $hotSpotWrapper.css('overflow', 'hidden');
                        }
                    }
                }
            })
            // updateVisibleSwatches needs to be triggered on click for desktop, touch for mobile
            .on('click touchstart', '.swatch--color:not(.js-swatches-toggle)', function () {
                const currentBreakpoint = breakpoints.getCurrentBreakpoint();
                updateVisibleSwatches($(this), currentBreakpoint);
            })
            // for Mobile, change the hover functionality to click/tap
            .on('click', '.js-grid-tile .product-tile .image-container:not(.lookswelove .js-grid-tile .product-tile .image-container)', (e) => {
                if (breakpoints.isLowRes()) {
                    window.location.href = $(e.target).closest('a').attr('href');
                    e.preventDefault();
                }
            });
    },

    onTileFocus: function () {
        $('.product-tile').on('focusin focusout', function (e) {
            if (e.type === 'focusin') {
                $(this).addClass('product-tile--focus');
            } else {
                $(this).removeClass('product-tile--focus');
            }
        });
    },

    onClickColorSwatches: function () {
        $body.on('click', '.js-grid-tile .js-color-swatch', (e) => {
            e.preventDefault();

            selectSwatch(e.target);

            if (breakpoints.isLowRes()) {
                const $gridTile = $(e.target).closest('.js-grid-tile');
                const $clickedSwatch = $(e.target).closest('.js-color-swatch');

                $('.js-color-swatch', $gridTile).removeClass('selected');
                $clickedSwatch.addClass('selected');
            }
        });
    },

    onClickColorToggle: function () {
        $body.on('click', '.js-swatches-toggle', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const $clickedLink = $(e.target);
            const $gridTile = $clickedLink.closest('.js-grid-tile');

            updateSwatchToggle($clickedLink);
            // Proactively load images to prevent flashing on mobile
            lazyloadPrimaryImages($gridTile);
        });
    },

    onClickRecommendations: function () {
        $('body').on('click', '.js-show-tile-recommendations', function () {
            const $recsBtn = $(this);
            const $tile = $recsBtn.closest('.product-tile');
            const tileRecsUrl = $tile.data('tile-recs-url');
            const $tileImg = $tile.find('.tile__image-container .selected .tile__image--primary');
            const drawerTitle = (window.resources && window.resources.recommendations && window.resources.recommendations.drawerTitle) || 'More Like This';
            $.drawer({
                src: tileRecsUrl, // Tile-AjaxRecommendations?pid=
                title: `
                    <img src="${$tileImg.attr('src')}" alt="${$tileImg.attr('alt')}" class="drawer__title-thumb" />
                    <span class="recommend-heading-text-pos heading-recommended-product">${drawerTitle}</span>
                `,
                size: 'sm',
                class: 'drawer--tile-recommendations'
            });

            if (_satellite) {
                const pid = $recsBtn.closest('.product').data('pid');
                _satellite.track('gridUpsell', { productID: pid });
            }
        });
    }
};
