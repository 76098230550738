'use strict';

const breakpoints = require('../components/breakpoints');
const debounce = require('lodash/debounce');

/**
 * Updates the tab and content state for the item that was clicked
 * @param {selector} clickedTabSelector for example: .ofs-item
 */
function updateTabbedContainerContent(clickedTabSelector) {
    var $clickedTab = $(clickedTabSelector);
    var $tabbedContainer = $clickedTab.closest('.tabbed-container');
    var $contentContainer = $tabbedContainer.children('.tabbed-container__content-container');
    var targetContentSelector = $clickedTab.data('target') || $clickedTab.attr('href');
    var $targetContent = $(targetContentSelector);

    // Show clicked tab's contents, hide all sibling tabs' contents.
    // This prevents white space below the footer when very long tab content is hidden
    $targetContent.show();

    if (breakpoints.isHighRes() || !$targetContent.closest('.responsive-tabs').length) {
        // only apply this style class when the tabbed container is being rendered as tabs (e.g. hybrid view at desktop width, or tab-only view at any width)
        $targetContent.siblings().hide();
    }

    // toggle active class on the content
    $contentContainer.children('.tabbed-container__content').removeClass('active');
    $targetContent.addClass('active');
}

/**
 * Reinitializes any carousels within tabbed content of any variation (e.g. tabs, accordions, hybrid)
 * @param {JQuery<HTMLElement>} $clickedItem for example: .ofs-item or .card-header
 */
function reinitializeCarousels($clickedItem) {
    var targetContentSelector = $clickedItem.data('target') || $clickedItem.attr('href');
    $(targetContentSelector).find('.slick-initialized').slick('unslick').slick('reinit');
}

module.exports = {
    init: function () {
        // set initial height of all content containers to the size of their active content.
        $('.tabbed-container__content-container').each(function () {
            var $this = $(this);
            var $hybridTabbedContainer = $this.closest('.responsive-tabs'); // may or may not exist depending on which $('.tabbed-container__content-container') we are in

            // wait for window.onLoad and try setting the height again, for those instances that weren't ready the first time.
            $(window).on('load', function () {
                if (breakpoints.isHighRes() || !$hybridTabbedContainer.length) {
                    // only apply this style class when the tabbed container is being rendered as tabs (e.g. hybrid view at desktop width, or tab-only view at any width)
                    $this.find('.active').siblings().hide();
                }
            });

            // make sure that we don't lose content if someone rotates their tablet or phone back and forth and the tabbed container transitions between accordion and tab view
            $(window).on('resize', debounce(
                function () {
                    if ($hybridTabbedContainer.length) {
                        $this.find('.active').siblings().toggle(breakpoints.isLowRes());
                    }
                }, 200));
        });

        $('.tabbed-container__tabs').on('click', '.ofs__item', function (e) {
            e.preventDefault();
            var $thisItem = $(this);

            // toggle active classes on the tabs
            $thisItem.siblings('.ofs__item').removeClass('active');
            $thisItem.addClass('active');

            var $ofs = $thisItem.closest('.overflow-slider');
            $ofs.prop('ofs').slideToItem($ofs, $thisItem);

            // reinitialize the carousels in case they got cut short on initial page load and tab content hiding
            reinitializeCarousels($thisItem);

            updateTabbedContainerContent($thisItem);
        });

        $('.accordions').on('click', '.card-header', function () {
            var $thisItem = $(this);

            // reinitialize the carousels in case they got cut short on initial page load and tab content hiding
            reinitializeCarousels($thisItem);
        });

        var hash = location.hash;
        if (hash) {
            // check if any of the ofs__items have this href or target.
            $('.tabbed-container__tabs .ofs__item').each(function () {
                var $thisItem = $(this);
                var targetContentSelector = $thisItem.data('target') || $thisItem.attr('href');
                if (targetContentSelector === hash) {
                    // go to this item
                    $thisItem.trigger('click'); // this will call the event handler above to make this item active.
                    return false; // break out of the .each() loop.
                }
                return true;
            });
        }
    }
};
