'use strict';
const breakpoints = require('./breakpoints');
const keyboardAccessibility = require('../components/keyboardAccessibility');
module.exports = function () {
    const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
    let selector = '';

    sizes.forEach(function (size) {
        selector = '.collapsible-' + size + ' .title';

        // fire click event on enter
        keyboardAccessibility(selector, {
            13: function (selected) { // enter
                $(selected).click();
            }
        },
        function () {
            return this; // this is the scope of the jQuery event handler in keyboardAccessibility
        }, 'body');

        $('body').on('click', selector, function (e) {
            if (size === 'xl' || (breakpoints.isCurrentBreakpoint('sm', 'md', 'lg') && size === 'lg') || (breakpoints.isCurrentBreakpoint('sm', 'md') && size === 'md') || (breakpoints.isCurrentBreakpoint('sm') && size === 'sm') || breakpoints.isCurrentBreakpoint('xs')) {
                e.preventDefault();
                $(this).closest('.collapsible-' + size).toggleClass('active');

                if ($(this).closest('.collapsible-' + size).hasClass('active')) {
                    $(this).attr('aria-expanded', true);
                } else {
                    $(this).attr('aria-expanded', false);
                }
                $(this).trigger('filter:click');
            }
        });
    });
};
