'use strict';

const keyboardAccessibility = require('../components/keyboardAccessibility');

module.exports = {
    init: function () {
        $(document).ready(function () {
            const bootstrapModals = $('[data-toggle="modal"]');
            // fire click event on enter
            keyboardAccessibility(bootstrapModals, {
                13: function (selected) { // enter
                    $(selected).click();
                }
            },
            function () {
                return this; // this is the scope of the jQuery event handler in keyboardAccessibility
            });
        });
    }
};
