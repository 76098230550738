'use strict';
const breakpoints = require('../components/breakpoints');
// Accessibility Enhancements

const KEYCODES = {
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    TAB: 9,
    ESCAPE: 27,
    ENTER: 13
};

/**
 * Adds a "keyboard-focus" event to body if the keyboard is being used to navigate
 */
function highlightFocus() {
    $('body').data('last-device-used', '');
    $('body')
        .on('mousedown', function () {
            $(this).data('last-device-used', 'mouse');
            $(this).data('last-device-used-when', new Date().getTime());
        })
        .on('keydown', function () {
            $(this).data('last-device-used', 'keyboard');
        })
        .on('focusin', function () {
            if ($(this).data('last-device-used') !== 'mouse' || new Date().getTime() - 50 > $(this).data('last-device-used-when')) {
                $(this).addClass('keyboard-focus');
            } else {
                $(this).removeClass('keyboard-focus');
            }
        })
        .on('focusout', function () {
            $(this).removeClass('keyboard-focus');
        });
}

/**
 * Basic keyboard navigation. Single level <ul><li><a> format:
 *
 *  <div id="skip-link-nav" aria-label="Skip Menu" role="navigation">
 *      <ul role="menu"> <-- parentSelector should point to this... Whatever has a role of menu or menubar
 *          <li role="presentation"><a role="menuitem" href="${'#content-wrapper'}">Skip to Content</a></li>
 *          <li role="presentation"><a role="menuitem" href="${'#main-menu'}">Skip to Main Nav</a></li>
 *          <li role="presentation"><a role="menuitem" href="${'#sidebar-wrapper'}">Skip to Category Nav</a></li>
 *          <li role="presentation"><a role="menuitem" href="${'#searchPopover_fieldInput'}">Skip to Search</a></li>
 *          <li role="presentation"><a role="menuitem" href="${URLUtils.https('Cart-Show')}">Go to Cart</a></li>
 *          <li role="presentation"><a role="menuitem" href="${URLUtils.https('Account-Show')}">Go to Login</a></li>
 *      </ul>
 *  </div>
 *
 * leftRightArrows is optional. If the role is menubar, then it will be true (otherwise false)
 *
 * @param {string} containerSelector - Selector for the menu
 * @param {boolean} leftRightArrows - Use Left/Right for navigation or Up/Down for navigation
 */
function basicKeyboardNavigation(containerSelector, leftRightArrows) {
    // parentSelector must point to UL or Container.
    jQuery(containerSelector).each(function () {
        var parentSelector = this;
        var isMenuBar = false; // A horizontal menu bar uses Left/Right for navigation. Otherwise Up/Down.
        if (leftRightArrows !== undefined) {
            isMenuBar = leftRightArrows;
        } else {
            var menuRole = $(this).attr('role');
            if (menuRole !== undefined) {
                if (menuRole === 'menubar') {
                    isMenuBar = true;
                } else if (menuRole === 'menu') {
                    isMenuBar = false;
                }
            }
        }

        $(parentSelector).on('keydown',
            function (e) {
                // Focus will on the PREVIOUS element, when the key was pressed.
                var el = $('a:focus', parentSelector);

                /**
                 * Focus on last visible link inside sub menu.
                 */
                function keyPreviousLink() {
                    var allLinks = $('a:visible', parentSelector);
                    if (allLinks.length) {
                        var indexOfEl = $(allLinks).index(el);
                        if (indexOfEl >= 0) {
                            indexOfEl--;
                            indexOfEl = (indexOfEl < 0) ? allLinks.length - 1 : indexOfEl; // Wrap
                            var el2 = $(allLinks).get(indexOfEl);
                            if (el2 !== undefined) {
                                $(el2).focus();
                                e.preventDefault();
                            }
                        }
                    }
                }

                /**
                 * Focus on first visible link inside sub menu.
                 */
                function keyNextLink() {
                    var allLinks = $('a:visible', parentSelector);
                    if (allLinks.length) {
                        var indexOfEl = $(allLinks).index(el);
                        if (indexOfEl >= 0) {
                            indexOfEl++;
                            indexOfEl = (indexOfEl >= allLinks.length) ? 0 : indexOfEl; // Wrap
                            var el2 = $(allLinks).get(indexOfEl);
                            if (el2 !== undefined) {
                                $(el2).focus();
                                e.preventDefault();
                            }
                        }
                    }
                }

                switch (e.keyCode) {
                    case KEYCODES.UP:
                        if (!isMenuBar) {
                            keyPreviousLink();
                        }
                        break;
                    case KEYCODES.DOWN:
                        if (!isMenuBar) {
                            keyNextLink();
                        }
                        break;
                    case KEYCODES.LEFT:
                        if (isMenuBar) {
                            keyPreviousLink();
                        }
                        break;
                    case KEYCODES.RIGHT:
                        if (isMenuBar) {
                            keyNextLink();
                        }
                        break;
                    case KEYCODES.TAB:
                        // Allow the default browser behavior to take over
                        break;
                    case KEYCODES.ESCAPE:
                        // Allow the default browser behavior to take over
                        break;
                    default:
                        break;
                }
            }
        );
    });
}

/**
 * Events for skiplinks menu
 */
function skipLinks() {
    basicKeyboardNavigation('#skip-link-nav ul', false);

    $('#skip-link-nav').focusin(function () {
        $(this).removeClass('aria-offscreen');
    });

    $('#skip-link-nav').focusout(function () {
        $(this).addClass('aria-offscreen');
    });

    $('#skip-link-nav a').click(function (e) {
        var anchor = $(this).attr('href');
        if (anchor.indexOf('#') === 0) {
            var el;
            if (anchor === '#main') {
                /*
                If an el with id equal to login or register and class tab pane exists find the first active nav link tag or
                find the first visible anchor or input inside the first div immediately after <header> element.
                */
                var elAfterHeader = $('header').nextAll('div:visible');
                if (elAfterHeader.find('#login', '#register').hasClass('tab-pane')) {
                    el = elAfterHeader.first().find('.nav-link.active');
                } else {
                    el = elAfterHeader.first().find('a[href]:visible, input:visible, button:not(:disabled):visible').first();
                }

                /*
                // Might need to add more rules for specific page.
                if (el.length === 0) {
                    var arr = [];
                    arr.push('#product-search-results'); // Product Search Results
                    arr.push('.container'); // Generic
                    for (var i=0; i<arr.length; i++) {
                        el = $(arr[i]).find('a[href]:visible:first, input:visible:first').first();
                        if (el.length > 0) {
                            break;
                        }
                    }
                }
                */
            } else if (anchor === '#main-menu') {
                if (breakpoints.isLowRes()) {
                    $('.js-action__menuopen:visible').first().trigger('click');
                    el = $('#main-menu li:visible').first();
                } else {
                    // Put the focus on the first visible anchor in the main menu.
                    el = $('#main-menu li:visible').first();
                }
            } else if (anchor === '#searchPopover_fieldInput') {
                // Click the search icon, put the focus on the search field.
                $('.js-action-search:visible').first().trigger('click');
                el = $('.js-search-field:visible').first();
            } else {
                // Fallback
                el = $(anchor).find('a[href]:visible:first, input:visible:first, button:not(:disabled):first').first();
            }
            if (el !== undefined && el.length > 0) {
                $(el).focus();
            }
            e.preventDefault();
        } else {
            // Follow link
            // e.preventDefault();
        }
    });
}

module.exports = {
    init: function () {
        highlightFocus();
        skipLinks();
    },
    basicKeyboardNavigation: basicKeyboardNavigation
};
