'use strict';

var Swiper = global.Swiper = require('swiper/swiper-bundle.min.js');

module.exports = {
    init: function ($selector = $('.swiper-container[data-swiper]')) {
        var swiper = [];
        $selector.each(function (index) {
            // do not duplicate existing swiper instances
            if (!this.swiper) {
                var $el = $(this);
                var swOptions = $el.data('swiper');

                swOptions.on = {
                    init: function () {
                        $el.addClass('swiper-loaded');
                    }
                };

                $el.on('updateLazyImages', () => {
                    if ($el[0].swiper) {
                        var $images = $('.swiper-wrapper', $el).find('img');
                        $images.each((i) => {
                            if ($images[i].hasAttribute('src')) {
                                $images.eq(i).attr('src', $images.eq(i).attr('data-src'));
                            }
                        });
                    }
                });

                swiper[index] = new Swiper(this, swOptions);
            }
        });
    },
    destroy: function ($selector) {
        if (!$selector) { return; }
        $selector.each(function () {
            if ($(this)[0].swiper) {
                $(this)[0].swiper.destroy(true, true);
            }
        });
    }
};
