'use strict';

/**
 * Adds or removes the alert-badge red dot on the minicart icon based on if
 * the Price Drop Modal is enabled and the user has a value for the
 * priceDropUserSegment in sessionStorage.
 */
function toggleAlertBadge() {
    const $icon = $('.minicart-icon .svgicon');
    const hasAlertWrapper = $icon.parent().hasClass('alert-badge');

    if (window.clientData && window.clientData.priceDropModalEnabled) {
        const customerGroup = sessionStorage.getItem('priceDropUserSegment');

        if (customerGroup) {
            const hasSaleItemInCart = !!(window.digitalData && window.digitalData.cart && window.digitalData.cart.item) && window.digitalData.cart.item.some(item => item.isSaleItem === 'Yes');

            if (hasSaleItemInCart && !hasAlertWrapper) {
                $icon.wrap('<div class="alert-badge"></div>');
            } else if (!hasSaleItemInCart && hasAlertWrapper) {
                $icon.unwrap('.alert-badge');
            }
        } else if (hasAlertWrapper) {
            $icon.unwrap('.alert-badge');
        }
    } else if (hasAlertWrapper) {
        $icon.unwrap('.alert-badge');
    }
}

module.exports = {
    toggleAlertBadge
};
