'use strict';

/**
* Checks if the current bootstrap UI breakpoint matches the breakpoint abbreviation.
* @param {string} breakpointAbbreviation Breakpoint abbreviations. Options are: xs, sm, md, lg, xl
* @returns {boolean} True if the breakpoint abbreviation is the current breakpoint.
*/
function isCurrentBreakpointAbbreviation(breakpointAbbreviation) {
    if (
        breakpointAbbreviation !== 'xs' &&
        breakpointAbbreviation !== 'sm' &&
        breakpointAbbreviation !== 'md' &&
        breakpointAbbreviation !== 'lg' &&
        breakpointAbbreviation !== 'xl') {
        throw new Error('Invalid breakpoint abbreviation: ' + breakpointAbbreviation);
    }

    var selector = '.bootstrap-breakpoints-' + breakpointAbbreviation;
    var $breakpointIndicator = $(selector);

    if ($breakpointIndicator.length === 0) {
        throw new Error('Breakpoint indicator not found for: ' + breakpointAbbreviation);
    }
    return $breakpointIndicator.css('display') === 'block';
}

/**
 * Finds the current breakpoint from the hidden .bootstrap-breakpoints element.
 * @returns {string} The currently active breakpoint abbreviation. ('xs', 'sm', 'md', 'lg', or 'xl')
 */
function getCurrentBreakpoint() {
    var $breakpointIndicators = $('.bootstrap-breakpoints > div');
    var currentBP;
    $breakpointIndicators.each(function () {
        var $bpIndicator = $(this);
        if ($bpIndicator.css('display') === 'block') {
            currentBP = $bpIndicator.data('bp');
            return false; // break out of the each loop
        }
        return true; // continue to next item in loop
    });
    return currentBP;
}

module.exports = {
    /**
     * This check is only for the resolution of the screen, it should
     * not be used to infer device type or user interaction (touch/click).
     * @returns {boolean} True if the screen is low resolution.
     */
    isLowRes: function () {
        return this.isCurrentBreakpoint('xs', 'sm', 'md');
    },
    /**
     * This check is only for the resolution of the screen, it should
     * not be used to infer device type or user interaction (touch/click).
     * @returns {boolean} True if the screen is high resolution.
     */
    isHighRes: function () {
        return this.isCurrentBreakpoint('lg', 'xl');
    },

    /**
     * Checks if the current bootstrap UI breakpoint matches a parameter.
     * @example isCurrentBreakpoint('xs', 'sm')
     * @param {arguments} breakpointAbbreviations One of more comma separated string breakpoint parameters. Options are: xs, sm, md, lg, xl
     * @returns {boolean} True if any of the breakpoint abbreviations are the current breakpoint.
     */
    isCurrentBreakpoint: function () {
        if (arguments.length === 0) {
            throw new Error('No breakpoint abbreviations specified.');
        }

        for (var i = 0, len = arguments.length; i < len; i++) {
            var abbreviation = arguments[i];

            if (isCurrentBreakpointAbbreviation(abbreviation)) {
                return true;
            }
        }

        /**
        * NOTE: it is explicitly intentional that this function accepts the breakpoint abbreviations and returns a boolean.
        * If the function was instead 'getCurrentBreakpoint' where it returns the breakpoint abbreviation...
        * then it might make future refactors and bootstrap updates more difficult if the abbreviations change
        * because the return value may not be consumed near the function call (difficult to track/update).
        */
        return false;
    },

    getCurrentBreakpoint: getCurrentBreakpoint
};
