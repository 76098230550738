'use strict';

const keyboardAccessibility = require('./keyboardAccessibility');

/**
 * Keyboard navigation for region list
 */
function regionEvents() {
    keyboardAccessibility('.js-action__showregion',
        {
            13: function () { // enter
                const $currentExpandedMenu = $('button.js-action__showregion[aria-expanded*=true]');

                if ($currentExpandedMenu.length) {
                    $currentExpandedMenu.attr('aria-expanded', 'false');
                }

                if (!$(this).is($currentExpandedMenu)) {
                    $(this).attr('aria-expanded', 'true');
                }
            },
            40: function () { // down
                const regionDataTarget = $(this).data('target');
                const $countryLink = $('.countries').find(regionDataTarget + ' .country-link').first();

                if ($countryLink.length) {
                    $countryLink.trigger('focus');
                }

                return true;
            }
        },
        function () {
            return $(this);
        }
    );
}

/**
 * Keyboard navigation for country list
 */
function countryEvents() {
    keyboardAccessibility('.country-link',
        {
            9: function ($this, event) { // tab
                const id = $this.closest('.region-countries').attr('id');
                const $currentRegion = $('[aria-controls*=' + id + ']').first();
                let $regionToTarget = $currentRegion.parent().next().children('.js-action__showregion').first();

                if (event.shiftKey) {
                    $regionToTarget = $currentRegion.parent().prev().children('.js-action__showregion').first();
                }

                if ($regionToTarget.length) {
                    $regionToTarget.trigger('focus');
                }

                return true;
            },
            38: function () { // up
                const $prevCountryLink = $(this).prev('a:visible');

                if ($prevCountryLink.length) {
                    $prevCountryLink.trigger('focus');
                } else {
                    const $prevColumnCountryLink = $(this).parent().prev().find('a:visible')
                                                    .last();

                    if ($prevColumnCountryLink.length) {
                        $prevColumnCountryLink.trigger('focus');
                    } else {
                        const id = $(this).closest('.region-countries').attr('id');
                        const $prevRegion = $('[aria-controls*=' + id + ']').first();

                        if ($prevRegion.length) {
                            $prevRegion.trigger('focus');
                        }
                    }
                }

                return true;
            },
            40: function () { // down
                const $nextCountryLink = $(this).next('a:visible');

                if ($nextCountryLink.length) {
                    $nextCountryLink.trigger('focus');
                } else {
                    const $nextColumnCountryLink = $(this).parent().next().find('a:visible')
                                                    .first();

                    if ($nextColumnCountryLink.length) {
                        $nextColumnCountryLink.trigger('focus');
                    }
                }

                return true;
            },
            37: function () { // left
                const $prevColumnCountryLink = $(this).parent().prev().find('a:visible')
                                                .first();

                if ($prevColumnCountryLink.length) {
                    $prevColumnCountryLink.trigger('focus');
                }

                return true;
            },
            39: function () { // right
                const $nextColumnCountryLink = $(this).parent().next().find('a:visible')
                                                .first();

                if ($nextColumnCountryLink.length) {
                    $nextColumnCountryLink.trigger('focus');
                }

                return true;
            }
        },
        function () {
            return $(this);
        }
    );
}

/**
 * Initialize keyboard event listeners
 */
function init() {
    module.exports.regionEvents();
    module.exports.countryEvents();
}

module.exports = {
    init: init,
    regionEvents: regionEvents,
    countryEvents: countryEvents
};
