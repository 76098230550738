'use strict';

/**
 * Gets the current session ID.
 * @returns {string} returns the session ID if it can be found.
 */
function getSessionID() {
    /**
     * Ideally we would use dwsid, which SFCC logs.
     * However, its stored in a cookie that is inaccessible to client JS.
     * 'sid' also identifies the current browsing session, matches session.sessionID, and is client JS accessible.
     * Per SFCC:
     * - session.sessionID: this can safely be used as an identifier against external systems.
     * - sid cookie: identifies the current browsing session.
     */
    const sessionCookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith('sid='));

    const sessionId = sessionCookie ? sessionCookie.split('=')[1] : null;

    if (!sessionId) {
        // eslint-disable-next-line no-console
        console.error('getSessionID: could not determine session ID');
    }

    return sessionId;
}

/**
 * Gets an item from local storage, that was previously JSON stringified.
 * If the session ID that was stored with the item,
 * doesn't match the current ID then local storage is cleared and null returned.
 * Note: for non-primitive types, like for dates, JSON.parse will leave it as string.
 * @param {string} keyName - name of the key where the item is stored
 * @returns {*} the stored item or null (particularly if session ID has changed)
 */
function getItem(keyName) {
    const sessionID = getSessionID();

    if (!sessionID) {
        return null;
    }

    const json = localStorage.getItem(keyName);
    let itemWrapper;

    if (json) {
        try {
            /**
             * In the future, if additional customization is needed,
             * add 'reviver' as an optional argument to getItem and pass it into JSON.parse.
             */
            itemWrapper = JSON.parse(json);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Unable to parse ${keyName}': ${e.message}; ${json}`);
        }
    }

    if (!itemWrapper) {
        return null;
    }

    // Session has change remove the item
    if (itemWrapper.sessionID !== sessionID) {
        localStorage.removeItem(keyName);
        return null;
    }

    return itemWrapper.item;
}

/**
 * Sets an item in local storage after passing it through JSON stringify along with its session ID.
 * @param {string} keyName - name of the key where the item is stored
 * @param {*} item - item to store
 * @returns {boolean} true if item is successfully stored, otherwise false.
 */
function setItem(keyName, item) {
    const itemWrapper = {
        sessionID: getSessionID(),
        item: item
    };

    if (!itemWrapper.sessionID) {
        return false;
    }

    localStorage.setItem(keyName, JSON.stringify(itemWrapper));
    return true;
}

module.exports = {
    getItem: getItem,
    setItem: setItem
};
