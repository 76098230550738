'use strict';

require('../components/spinner');
var utils = require('../components/utils');
window.Vimeo = require('@vimeo/player');
var videoPlayer = null; // Youtube script is loaded async in JS
window.videoPlayers = {};

/** _________
 * |         |
 * |   (>)   |
 * |_________|
 *
 * <a class="js-video-player video-play-button" href="https://www.youtube.com/watch?v=JVq0GbCLIuU">
 *      <img src="https://via.placeholder.com/960x480" alt="Columbia's 80th Anniversary" />
 * </a>
 * <div class="js-video-player video-play-button" data-video-id="JVq0GbCLIuU" data-host="youtube">
 *      <img src="https://via.placeholder.com/960x480" alt="Columbia's 80th Anniversary" />
 * </div>
 * <a href="https://www.youtube.com/watch?v=JVq0GbCLIuU" class="js-video-modal video-play-button">...</a>
 * <a href="..." class="js-video-modal" data-video-id="JVq0GbCLIuU" data-host="youtube">...</a>
 */

var getVideoSource = function (el) {
    var host;
    var videoId;

    if (el.tagName.toLowerCase() === 'a') {
        // get host and video ID from href
        var hostName = el.hostname.toLowerCase();
        var pathName = el.pathname;
        var queryStr = el.search;
        var pathTest;

        // try to get the video provider from the domain
        // try to get the video id from the query string or path
        // URI might look like one of these:
        // * https://youtu.be/JVq0GbCLIuU
        // * https://www.youtube.com/embed/JVq0GbCLIuU
        // * https://www.youtube.com/watch?v=JVq0GbCLIuU
        // * https://vimeo.com/284409069
        // * https://player.vimeo.com/video/284409069
        if (hostName.indexOf('youtu') >= 0) {
            host = 'youtube';

            // query string -> object
            var params = {};
            if (queryStr.length > 1) {
                queryStr = queryStr.substring(1); // remove the ? from the front
                params = utils.keyValueArray(queryStr);
            }

            if (params.v) { // look for video id in query string object
                videoId = params.v;
            } else { // look for video id in path
                pathName = pathName.replace('/embed', '');
                pathTest = /^\/[a-z0-9]+$/i; // youtube ids are mixed-case alpha-numeric
                if (pathTest.test(pathName)) {
                    videoId = videoId.substring(1); // remove the leading /
                }
            }
        } else if (hostName.indexOf('vimeo.com') >= 0) {
            host = 'vimeo';

            // look for video id in path
            pathName = pathName.replace('/video', '');
            pathTest = /^\/[0-9]+$/; // vimeo ids are numeric only
            if (pathTest.test(pathName)) {
                videoId = pathName.substring(1); // remove the leading /
            }
        } else {
            // fall back to youtube
            host = 'youtube';
        }
    }

    if (!host) {
        host = $(el).data('host');
    }

    if (!videoId) {
        videoId = $(el).data('video-id');
    }

    return {
        host: host,
        id: videoId
    };
};

var getVideo = function (host, videoId, width, height, options) {
    var defaults = {
        autoplay: 1,
        loop: 0,
        autopause: 0,
        transparent: true
    };

    var params = $.extend({}, defaults, options);
    var queryString = $.param(params);

    var w = width || '100%';
    var h = height || '100%';

    if (host.toLowerCase() === 'youtube') {
        return '<iframe ' +
        'id="ytPlayer_' + videoId + '" ' +
        'class="video-player" ' +
        'src="https://www.youtube.com/embed/' + videoId + '?enablejsapi=1&' + queryString + '&origin=' + window.location.origin + '" ' +
        'width="' + w + '" height="' + h + '" ' +
        'allow="autoplay; encrypted-media" ' +
        'title="YouTube video player" ' +
        'frameborder="0" ' +
        'autohide="1" ' +
        'allowfullscreen="1" ' +
        'enablejsapi="true">' +
        '</iframe>';
    } else if (host.toLowerCase() === 'vimeo') {
        return '<iframe ' +
        'id="vimeoPlayer_' + videoId + '" ' +
        'class="video-player" ' +
        'src="https://player.vimeo.com/video/' + videoId + '?' + queryString + '" ' +
        'width="' + w + '" height="' + h + '" ' +
        'allow="autoplay; encrypted-media" ' +
        'title="Vimeo video player" ' +
        'frameborder="0" ' +
        'webkitallowfullscreen mozallowfullscreen allowfullscreen>' +
        '</iframe>';
    } else if (host.toLowerCase() === 'scene7') {
        return '<video controls autoplay="true" id="scene7Player_' + videoId + '" class="video-player">' +
        '<source src="https://columbia.scene7.com/is/content/ColumbiaSportswear2/' + videoId + '?' + queryString + '" >' +
        '</video>';
    }

    // if all else fails, return a generic iframe
    return '<iframe ' +
        'id="videoPlayer_' + videoId + '" ' +
        'class="video-player" ' +
        'width="' + w + '" height="' + h + '" ' +
        'allow="autoplay; encrypted-media" ' +
        'title="Video Player" ' +
        'frameborder="0" ' +
        'allowfullscreen="1">' +
        '</iframe>';
};

var destroyModalMarkup = function ($modal) {
    $modal.remove();
    // also remove the backdrop
    $('.modal-backdrop').remove();
};

var playVideoInFrame = function (host, videoId) {
    if (host.toLowerCase() === 'youtube') {
        var createYTPlayer = function () {
            var $youTubeIframe = $('#' + videoId);
            var $imgContainer = $youTubeIframe.parent('.js-video-player');

            // create the player
            videoPlayer = new window.YT.Player(videoId, {
                events: {
                    onReady: function (event) {
                        $.spinner().stop();
                        event.target.playVideo();
                    },
                    onStateChange: function (event) {
                        if (event.data === window.YT.PlayerState.PLAYING) {
                            var videoName = $imgContainer.data('name') || videoPlayer.getVideoData().title;

                            // add the video name in the digital data video object
                            if (window.digitalData && window.digitalData.page && window.digitalData.page.video) {
                                window.digitalData.page.video.videoName = $imgContainer.data('name');
                            }

                            // Note: this event trigger was added primarily to support code registered in Adobe Launch (AKA DTM)
                            var ev = new CustomEvent('video:start', {
                                detail: {
                                    videoUrl: $youTubeIframe.attr('src'),
                                    videoName: videoName,
                                    playerType: 'youtube'
                                }
                            });
                            document.dispatchEvent(ev);

                            $.spinner().stop();
                            $imgContainer.addClass('is-playing');
                        } else if (event.data === window.YT.PlayerState.ENDED) {
                            var $modalContainer = $youTubeIframe.closest('.modal');
                            // unhide the play button
                            $imgContainer.removeClass('is-playing');
                            // destroy the player
                            event.target.destroy();
                            // if we're in a modal, destroy it too.
                            $modalContainer.remove();
                            // also remove the backdrop
                            $('.modal-backdrop').remove();
                            // remove the video from the videoPlayers
                            delete window.videoPlayers[videoId];
                        }
                    }
                }
            });
            return videoPlayer;
        };

        if (window.YT) {
            // YouTube API is already loaded.
            videoPlayer = createYTPlayer(videoId);
            window.videoPlayers[videoId] = videoPlayer;
        } else {
            // load the API
            var firstScriptTag = document.getElementsByTagName('script')[0];
            var tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            // when the API finishes loading...
            window.onYouTubeIframeAPIReady = function () {
                // create the player
                videoPlayer = createYTPlayer(videoId);
                window.videoPlayers[videoId] = videoPlayer;
            };
        }
    } else if (host.toLowerCase() === 'vimeo') {
        var $vimeoIframe = $('#' + videoId);
        var $imgContainer = $vimeoIframe.parent('.js-video-player');
        var $modalContainer = $vimeoIframe.closest('.modal');

        // eslint-disable-next-line
        videoPlayer = new window.Vimeo.default(videoId);

        videoPlayer.ready().then(function () {
            $imgContainer.addClass('is-playing');
            videoPlayer.play();
        });

        videoPlayer.on('playing', function () {
            var videoName = $imgContainer.data('name');

            (videoName ?
                Promise.resolve() :
                videoPlayer.getVideoTitle().then(function (title) {
                    videoName = title;
                })
            )
            .catch(function () {}) // Ideally we would just use finally but the IE polyfill doesn't support it
            .then(function () {
                // add the video name in the digital data video object
                if (window.digitalData && window.digitalData.page && window.digitalData.page.video) {
                    window.digitalData.page.video.videoName = videoName;
                }

                // Note: this event trigger was added primarily to support code registered in Adobe Launch (AKA DTM)
                var ev = new CustomEvent('video:start', {
                    detail: {
                        videoUrl: $vimeoIframe.attr('src'),
                        videoName: videoName,
                        playerType: 'vimeo'
                    }
                });
                document.dispatchEvent(ev);
            });

            $imgContainer.addClass('is-playing');
        });

        // listen for the end of the video to destroy it.
        videoPlayer.on('ended', function () {
            videoPlayer.destroy();

            $imgContainer.removeClass('is-playing');
            // $modalContainer.modal('dispose');
            destroyModalMarkup($modalContainer);
            // remove the video from the videoPlayers
            delete window.videoPlayers[videoId];
        });
        window.videoPlayers[videoId] = videoPlayer;

        // play the video
        videoPlayer.play();
    } else if (host.toLowerCase() === 'scene7') {
        var $s7Video = $('#' + videoId);

        $s7Video.parent('.js-video-player').on('click', function () {
            if ($s7Video[0].paused) {
                $s7Video[0].play();
            } else {
                $s7Video[0].pause();
            }
        });

        $s7Video.on('play', function () {
            $s7Video.parent('.js-video-player').addClass('is-playing');
        });

        $s7Video.on('playing', function () {
            $s7Video.parent('.js-video-player').addClass('is-playing');
        });

        $s7Video.on('pause', function () {
            $s7Video.parent('.js-video-player').removeClass('is-playing');
        });

        $s7Video.on('ended', function () {
            $s7Video.parent('.js-video-player').removeClass('is-playing');
        });
    }
};

var loadVideoPlayer = function (videoContainer) {
    var videoSource = getVideoSource(videoContainer);
    var video = getVideo(videoSource.host, videoSource.id);
    var videoId = $(video).attr('id');

    if ($(videoContainer).find('#' + videoId).length) {
        return; // prevent adding duplicate video to DOM
    }

    // save non-video elements so they don't get removed when videoContainer contents get replaced below
    var $clonedVideoContainer = $(videoContainer).clone();
    $clonedVideoContainer.find('iframe').remove(); //  remove video element
    var nonVideoHtml = $clonedVideoContainer.html() || '';
    var videoHtml = nonVideoHtml + video;

    $(videoContainer).html(videoHtml);

    // let player-dependent code know when player has been created/updated
    $(document).trigger('video:player:update');
    playVideoInFrame(videoSource.host, videoId);
};

module.exports = function () {
    $('body')
        .on('click touchend', '.js-video-player', function (e) {
            e.preventDefault();
            loadVideoPlayer(this);
        })
        .on('click', '.js-video-modal', function (e) {
            e.preventDefault();
            var $trigger = $(this);
            var video = getVideoSource(this);
            var width = $trigger.data('width') || 766;
            var height = $trigger.data('height') || width * 0.5625; // default to 16:9 ratio
            var modalTitle = $trigger.data('title') || '';
            var iframe = getVideo(video.host, video.id, width, height, {});
            var iframeId = $(iframe).attr('id');
            // create the modal
            var html = utils.createModalMarkup(iframe, { title: modalTitle });
            var $modal = $(html).modal();

            // listen for events
            $modal
                .on('shown.bs.modal', function () {
                    // play the video after the modal is ready
                    playVideoInFrame(video.host, iframeId);
                }).on('hidden.bs.modal', function () {
                    // remove the modal markup completely
                    destroyModalMarkup($(this));
                });
        });
};
