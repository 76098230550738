'use strict';
var LazyLoad = global.LazyLoad = require('vanilla-lazyload/dist/lazyload');

/**
 * Executes during callback_enter in the Vanilla Lazyloading lib.
 * Initializes s7VideoSourceSet from data attrs
 * @param {HTMLElement} element - Video Element
 */
function lazyLoadS7VideoSourceSet(element) {
    let mobileVideoSourceIn = element.dataset.mobileSource;
    let desktopVideoSourceIn = element.dataset.desktopSource;
    let uniqueid = element.dataset.uniqueId;
    let mobileaspectratio = element.dataset.mobileAspectratio;
    let aspectratio = element.dataset.aspectratio;
    window.s7VideoSourceSet(mobileVideoSourceIn, desktopVideoSourceIn, uniqueid, mobileaspectratio, aspectratio);
}

/**
 * Initialize Vanilla Lazy Load instance
 */
function s7LazyInit() {
    // eslint-disable-next-line no-unused-vars
    var s7LazyLoadInstance = new LazyLoad({
        elements_selector: '.s7LazyVideo',
        use_native: false,
        callback_enter: lazyLoadS7VideoSourceSet,
        unobserve_entered: true
    });
}

module.exports = {
    init: function () {
        var lazyLoadInstance = new LazyLoad({
            elements_selector: '.lazy',
            use_native: true,
            load_delay: 200
        });
        // Update the instance
        if (lazyLoadInstance) {
            lazyLoadInstance.update();
        }

        s7LazyInit();
    }
};
