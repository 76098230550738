'use strict';

/**
 * When a promotion multiplier is in effect, highlight the bonus reward
 * if a strikeout price (sibling element) is detected
 */
function highlightMultiplierReward() {
    if ($('.js-rewards-highlight + s').length) {
        $('.js-rewards-highlight').addClass('forecasted-loyalty-rewards__text--highlight');
    }
}

/**
 * Displays the Loyalty points forecast on PDP
 * @param {HTML} html Product data passed on attribute selection
 */
function updateLoyaltyForecastPoints(html) {
    const $rewardsContainer = $('.js-rewards-container');
    $rewardsContainer.html(html);
    highlightMultiplierReward();
}

module.exports = {
    init: function () {
        // Attribute Select Event
        $('body').on('product:afterAttributeSelect', function (e, response) {
            var rewardsData = response.data.product.rewardsHtml;
            if (rewardsData) {
                updateLoyaltyForecastPoints(rewardsData);
            }
        });
        highlightMultiplierReward();
    }
};
