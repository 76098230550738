'use strict';

const ua = window.navigator.userAgent;
const $siteheader = $('.siteheader');
const url = $siteheader.data('request-url');

/**
 * function validates if the browser user agent is from an Internet Explorer 11 or lower version
 * @param  {string} userAgent user-agent of the http request
 * @return {boolean} true if the browser user agent is from an an Internet Explorer 11 or lower version.
 */
function isInternetExplorer11(userAgent) {
    var ie11Regex = /(msie|trident)/i; // 'i' at the end for case-invariant regex
    return ie11Regex.test(userAgent);
}

module.exports = {
    init: function () {
        if (isInternetExplorer11(ua)) {
            $.get(url, function (data) {
                $.alert({
                    content: data.message,
                    theme: 'danger',
                    placement: 'banner'
                });
            });
        }
    }
};
