'use strict';

const keyboardAccessibility = require('./keyboardAccessibility');

const headerSearchContainerSelector = '.js-header-search';
const searchField = '.js-search-field';
const searchLinkContainer = '.suggestions-wrapper';

/**
 *  Tab to previous link
 * @param {HTMLElement} $thisLink element the keyboard event was fired on
 */
function tabPrevItem($thisLink) {
    const $searchLinks = $thisLink.closest(searchLinkContainer).find('a:visible, button:visible');
    const nextLinkIndex = $searchLinks.has($thisLink) ? $searchLinks.index($thisLink) - 1 : 0;

    if (nextLinkIndex >= 0) {
        $($searchLinks[nextLinkIndex]).trigger('focus');
    } else {
        $(searchField).trigger('focus');
    }
}

/**
 *  Tab to next link
 * @param {HTMLElement} $thisLink element the keyboard event was fired on
 */
function tabNextItem($thisLink) {
    const $searchLinks = $thisLink.closest(searchLinkContainer).find('a:visible, button:visible');
    const nextLinkIndex = $searchLinks.has($thisLink) ? $searchLinks.index($thisLink) + 1 : 0;

    if (nextLinkIndex < $searchLinks.length) {
        $($searchLinks[nextLinkIndex]).trigger('focus');
    }
}

/**
 * Keyboard events for search in the header
 */
function keyboardEvents() {
    keyboardAccessibility(headerSearchContainerSelector,
        {
            27: function () { // escape
                $(searchField).trigger('focus');
            }
        },
        function () { }
    );

    keyboardAccessibility(searchField,
        {
            40: function ($searchForm) { // down
                $searchForm.find(searchLinkContainer).find('a:visible, button:visible').first().trigger('focus');
                return true; // prevent scroll
            }
        },
        function () {
            return $(this).closest('form');
        }
     );

    keyboardAccessibility('.suggestions a, .suggestions button',
        {
            13: function () { // enter
                this.click();
                return true;
            },
            38: function ($thisLink) { // up
                tabPrevItem($thisLink);
                return true; // prevent scroll
            },
            40: function ($thisLink) { // down
                tabNextItem($thisLink);
                return true; // prevent scroll
            }
        },
        function () {
            return $(this);
        },
        searchLinkContainer // delegated selector
    );
}

module.exports = {
    init: keyboardEvents
};
