'use strict';

if (!window.timerIDs) {
    window.timerIDs = {};
}
// PDP-specific selectors in countdown.isml
const COUNTDOWN_CONTAINER = '.countdown';
const COUNTDOWN_ACTIVE = '.countdown__prompt, .countdown__clock';
const COUNTDOWN_PROMPT = '.countdown__prompt';
const COUNTDOWN_FINISH_MSG = '.countdown__finished';
const COUNTDOWN_CLOCK = '.countdown__clock';

/**
 * Calculate time remaining for countdown
 * @param {DateTime} targetTime Time when countdown will hit 0
 * @return {number} Difference between target time and now
 */
function calcRemainingTime(targetTime) {
    return targetTime - new Date();
}

/**
 * Update unit label
 * @param {string} unit Days, hours, minutes,
 * @param {boolean} isPlural true passed if count is greater than 1
 */
function updateUnitLabel(unit, isPlural) {
    if (window.resources && window.resources.countdown && window.resources.countdown[unit]) {
        let $unit = $('.countdown__label--' + unit);

        if (isPlural) {
            $unit.text(window.resources.countdown[unit]);
        } else {
            // Drop 's' from days, hours, minutes, seconds
            $unit.text(window.resources.countdown[unit.substring(0, unit.length - 1)]);
        }
    }
}

/**
 * Updates countdown clock/timer values and handles end state.
 * @param {string} contentContainer Selector containing the timer
 * @param {string} timerId Selector for the timer
 * @param {DateTime} targetTime Time when countdown will hit 0
 * @param {string} selectorsActive Selector of content to show
 * @param {string} selectorsFinished Selector of content to hide
 */
function updateCountdownTimer(contentContainer, timerId, targetTime, selectorsActive, selectorsFinished) {
    let $target = $(contentContainer);
    let remainingTime = calcRemainingTime(targetTime);

    if (remainingTime <= 0) {
        clearInterval(window.timerIDs[timerId]);

        // Show end state
        $(selectorsFinished).show();
        $(selectorsActive).hide();
    } else {
        // Show active state
        $(selectorsFinished).hide();
        $(selectorsActive).show();

        let days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        let hours = Math.floor(remainingTime / (1000 * 60 * 60)) % 24;
        let minutes = Math.floor(remainingTime / (1000 * 60)) % 60;
        let seconds = Math.floor(remainingTime / 1000) % 60;

        // Leading zeros
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        let $countdownValues = $target.find('.hours, .minutes, .seconds');
        $countdownValues.eq(0).text(hours);
        $countdownValues.eq(1).text(minutes);
        $countdownValues.eq(2).text(seconds);

        // Dynamic 'day(s)' unit and label
        let $days = $target.find('.days');
        let $unit = $target.find('.countdown__label--days');
        if (days) {
            $days.text(days).show();
            updateUnitLabel('days', days > 1);
            $unit.show();
        } else {
            $days.hide();
            $unit.hide();
        }
    }
}

/**
 * Initiates countdown timer
 * @param {string} contentContainer Selector containing the timer
 * @param {string} timerId Selector for the timer
 * @param {string} dateStr target date
 * @param {number} freq Frequency, in ms, at which timer updates, eg. 1000
 * @param {string} selectorsActive Selector of content to show
 * @param {string} selectorsFinished Selector of content to hide
 */
function initCountdownTimer(contentContainer, timerId, dateStr, freq, selectorsActive, selectorsFinished) {
    let targetTime = new Date(dateStr);
    let remainingTime = calcRemainingTime(targetTime);

    if (remainingTime > 0 && selectorsActive && selectorsActive !== '') {
        $(contentContainer).removeClass('countdown--hidden').data('timerDisplayClasses', '');
        $(selectorsActive).show();
    }

    clearInterval(window.timerIDs[timerId]);

    updateCountdownTimer(
        contentContainer, // content container
        timerId,
        targetTime, // target date (date object)
        selectorsActive, // active countdown selectors
        selectorsFinished // finished countdown selectors
    );

    window.timerIDs[timerId] = setInterval(
        updateCountdownTimer, // callback function
        freq, // frequency
        contentContainer, // content container
        timerId,
        targetTime, // target date (date object)
        selectorsActive, // active countdown selectors
        selectorsFinished // finished countdown selectors
    );
}

/**
 * Initiate PDP countdown based on data attribute and pre-specified selectors
 */
function initPDPCountdown() {
    let $countdownContainer = $(COUNTDOWN_CONTAINER);
    let endTime = $countdownContainer.data('endTime');
    endTime = endTime === 'null' ? null : endTime; // Fix for null being sometimes passed to ISML as a string

    if (endTime) {
        initCountdownTimer(
            COUNTDOWN_CONTAINER,
            COUNTDOWN_CLOCK,
            endTime,
            1000,
            COUNTDOWN_ACTIVE, // Show
            COUNTDOWN_FINISH_MSG // Hide
        );
    } else {
        $countdownContainer.addClass('countdown--hidden');
    }
}

/**
 * Update data attribute based on active promotion for selected variant
 * @param {Object} response pdict data object
 */
function updateCountdownData(response) {
    if (response.data && response.data.product) {
        // Update endTime data attribute
        $(COUNTDOWN_CONTAINER).data('endTime', response.data.product.countdownEndDate);

        // Update prompt and finish message
        if (response.data.product.countdownPrompt) {
            $(COUNTDOWN_PROMPT).text(response.data.product.countdownPrompt);
        }

        if (response.data.product.countdownFinishMsg) {
            $(COUNTDOWN_FINISH_MSG).text(response.data.product.countdownFinishMsg);
        }
    }
}

module.exports = {
    init: function () {
        $(window).on('load.countdown', function () {
            initPDPCountdown();
        });

        $('body').on('product:afterAttributeSelect', function (e, response) {
            // Update counter end date / status
            updateCountdownData(response);
            initPDPCountdown();
        });
    }
};
