'use strict';

/**
 * Adds hooks for intercepting calls to window.history functions.
 */
module.exports = function () {
    var originalPushState = window.history.pushState;
    var originalReplaceState = window.history.replaceState;

    /**
     * Amends the data that is stored as part of the browser history
     * @param {Object} data - the original state provided by the caller, if applicable
     * @param {string} url - change to page URL, if applicable
     * @returns {Object} - amended state data
    */
    function amendStateData(data, url) {
        var pageVisitReferrer = require('./../dataLayer/pageVisitReferrer');
        var amendedData = data;

        /*
        * Some page referers are only single-use in terms of analytics (the data layer).
        * When the client alters the URL by changing browser history state, the client still holds reference to the original referer.
        * As a result, the client essentially reuses a single-use referer without the following intervention.
        */
        if (url && // only run this code when the page URL is being changed
            document.referrer &&
            window.resources &&
            window.resources.dataLayer &&
            window.resources.dataLayer.singleReferralDomains &&
            window.digitalData &&
            window.digitalData.pageVisit) {
            var referrerIsSingleUse = pageVisitReferrer.isSingleUseReferrer();

            if (referrerIsSingleUse) {
                // Referrer is no longer relevant on the current page due to the URL change
                window.digitalData.pageVisit.referrer = window.location.origin;

                /**
                 * Amend data related to the URL if its an object or no existing data
                 * so mainHead.js can blank the referrer on page load.
                 */
                if (!amendedData || typeof amendedData === 'object') {
                    amendedData = amendedData || {};
                    amendedData.digitalData = amendedData.digitalData || {};

                    amendedData.digitalData.blankVisitReferrerOnLoad = true;
                }
            }
        }

        return amendedData;
    }

    window.history.pushState = function (data, title, url) {
        var amendedData = amendStateData(data, url);
        originalPushState.call(window.history, amendedData, title, url);
    };

    window.history.replaceState = function (data, title, url) {
        var amendedData = amendStateData(data, url);
        originalReplaceState.call(window.history, amendedData, title, url);
    };
};
