'use strict';

const keyboardAccessibility = require('./keyboardAccessibility');

// Closure to keep track of index for current anchor when dealing with slotted content
const anchorIndexHelper = (function () {
    let anchorIndex = 0;
    return {
        increment: function () {
            return anchorIndex++;
        },
        decrement: function () {
            return --anchorIndex;
        },
        getIndex: function () {
            return anchorIndex;
        },
        setIndex: function (value) {
            anchorIndex = value;
        }
    };
}());

/**
 * Move to next anchor
 * @param {JQuery} $contentContainer container for content asset
 */
function nextAnchorItem($contentContainer) {
    const $nextAnchors = $contentContainer.find('a:visible');
    anchorIndexHelper.increment();

    if (anchorIndexHelper.getIndex() > $nextAnchors.length - 1) {
        anchorIndexHelper.setIndex($nextAnchors.length - 1);
    }

    let $focusTarget = $nextAnchors[anchorIndexHelper.getIndex()];

    if ($focusTarget) {
        $focusTarget.focus();
    }
}

/**
 * Move to the previous anchor
 * @param {JQuery} $contentContainer container for content asset
 * @param {JQuery} $prevSecondaryLi last secondary li item
 */
function previousAnchorItem($contentContainer, $prevSecondaryLi) {
    if (anchorIndexHelper.getIndex() > 0) {
        anchorIndexHelper.decrement();
    } else {
        // if no previous anchor to go to, go back to secondary navigation
        if ($prevSecondaryLi && $prevSecondaryLi.length) {
            $prevSecondaryLi.trigger('focus');
        }

        anchorIndexHelper.setIndex(0);

        return;
    }

    let $focusTarget = $contentContainer.find('a:visible')[anchorIndexHelper.getIndex()];

    if ($focusTarget) {
        $focusTarget.focus();
    }
}

/**
 * Find the initial anchor item for slotted content
 * @param {HTMLElement} thisLi current li item
 */
function findAnchorItem(thisLi) {
    $(thisLi).openMenuItem();

    let $contentAssetContainer = $(thisLi).find('.sitenav__category-content');
    let $anchorFocusTarget = $contentAssetContainer.find('a:visible').first();

    if (anchorIndexHelper.getIndex() > 0) {
        anchorIndexHelper.setIndex(0);
    }

    if ($anchorFocusTarget) {
        $anchorFocusTarget.focus();
    } else {
        $(thisLi).closeMenuItem();
    }
}

/**
 *  Close menu and blur focused menu item
 * @param {HTMLElement} focused element the keyboard event was fired on
 */
function closeMenu(focused) {
    $(focused).closeMenuItem();
}

/**
 *  Navigate link
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 */
function navigateLink(thisLi) {
    $(thisLi).children('button, a')[0].click();
}

/**
 *  Tab to previous menu item
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 */
function tabPrevItem(thisLi) {
    $(thisLi).closeMenuItem().trigger('blur');
    $(thisLi).prevAll('li:visible').first().trigger('focus');
}

/**
 *  Tab to next menu item
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 */
function tabNextItem(thisLi) {
    $(thisLi).closeMenuItem().trigger('blur');
    $(thisLi).nextAll('li:visible').first().trigger('focus');
}

/**
 *  Previous menu item
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 */
function previousMenuItem(thisLi) {
    const $focusTarget = $(thisLi).prevAll('li:visible').first();
    $(thisLi).closeMenuItem();
    $focusTarget.trigger('focus');
}

/**
 *  Next menu item
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 */
function nextMenuItem(thisLi) {
    const $focusTarget = $(thisLi).nextAll('li:visible').first();
    $(thisLi).closeMenuItem();
    $focusTarget.trigger('focus');
}

/**
 *  Previous menu level
 * @param {HTMLElement} previousLevel menu element to receive focus
 */
function previousMenuLevel(previousLevel) {
    $(previousLevel).trigger('focus').closeMenuItem();
}

/**
 *  Next menu level
 * @param {HTMLElement} thisLi element the keyboard event was fired on
 * @param {HTMLElement} nextLevel menu element to receive focus
 */
function nextMenuLevel(thisLi, nextLevel) {
    // show menu so that :visible selector works
    const $focusTarget = $(thisLi).find(nextLevel).first();
    // check if nextLevel can be focused
    if ($focusTarget.length) {
        $($focusTarget).siblings().closeMenuItem();
        $($focusTarget).trigger('focus');
    } else if ($(thisLi).find('.sitenav__category-content').length) { // check if there is slotted content
        module.exports.findAnchorItem($(thisLi));
    } else {
        // hide menu if nextLevel can't be focused
        $(thisLi).closeMenuItem();
    }
}

/**
 * keyboard events for Skip to Main Nav link anchors in main menu
 */
function skipLinkNav() {
    keyboardAccessibility('#skip-link-nav a:eq(1)', // grab 2nd link (Skip to Main Nav) in menu
        {
            13: function () { // enter
                $(this).click(); // navigate to href value - #main-menu or sitenav-primaryul
                $('#main-menu').children(':first-child').focus(); // put first primary menu item in focus to open menu & override focus event on .sitenav__menu
                return true;
            }
        },
        function () {
            $(this);
        }
    );
}

/**
 * keyboard events for primary anchors in main menu
 */
function primaryNav() {
    keyboardAccessibility('.sitenav-primary__li:not(.sitenav__search)',
        {
            9: function ($this, event) { // tab
                if (event.shiftKey && $this.prevAll('li:visible').length) {
                    module.exports.tabPrevItem($this);
                    return true;
                } else if (!event.shiftKey && $this.nextAll('li:visible').length) {
                    module.exports.tabNextItem($this);
                    return true;
                }

                module.exports.closeMenu(this);
                return false;
            },
            13: function () { // enter
                module.exports.navigateLink(this);
            },
            27: function () { // escape
                module.exports.closeMenu(this);
            },
            38: function () { // up
                return true; // prevent scroll
            },
            40: function () { // down
                if ($(this).find('li').length) {
                    module.exports.nextMenuLevel(this, 'li:visible');
                } else if ($(this).find('.sitenav__category-content').length) { // check for slotted content
                    module.exports.findAnchorItem($(this));
                }
                return true;
            }
        },
        function () {
            return $(this);
        }
    );
}

/**
 * keyboard events for secondary anchors inside open flyout menu
 */
function secondaryNav() {
    keyboardAccessibility('.sitenav-secondary__li',
        {
            13: function () { // enter
                module.exports.navigateLink(this);
                return true;
            },
            37: function () { // left
                if ($(this).prevAll('li:visible').length) {
                    module.exports.previousMenuItem(this);
                }
                return true;
            },
            38: function (parentLi) { // up
                if ($(this).prevAll('li:visible').length) {
                    module.exports.previousMenuItem(this);
                } else {
                    module.exports.previousMenuLevel(parentLi);
                }
                return true;
            },
            39: function () { // right
                if ($(this).nextAll('li:visible').length) {
                    module.exports.nextMenuItem(this);
                } else if ($(this).closest('.sitenav-primary__li:not(.sitenav__search)').find('.sitenav__category-content').length) { // check for slotted content
                    module.exports.findAnchorItem($(this).closest('.sitenav-primary__li:not(.sitenav__search)'));
                }
                return true;
            },
            40: function () { // down
                if ($(this).find('li:visible').length) {
                    module.exports.nextMenuLevel(this, 'li:visible');
                } else if ($(this).nextAll('li:visible').length) {
                    module.exports.nextMenuItem(this);
                } else if ($(this).closest('.sitenav-primary__li:not(.sitenav__search)').find('.sitenav__category-content').length) { // check for slotted content
                    module.exports.findAnchorItem($(this).closest('.sitenav-primary__li:not(.sitenav__search)'));
                }
                return true;
            }
        },
        function () {
            return $(this).closest('.sitenav-primary__li');
        }
    );
}

/**
 * keyboard events for tertiary anchors inside open flyout menu
 */
function tertiaryNav() {
    keyboardAccessibility('.sitenav-tertiary__li',
        {
            13: function () { // enter
                module.exports.navigateLink(this);
                return true;
            },
            38: function (parentLi) { // up
                if ($(this).prevAll('li:visible').length) {
                    module.exports.previousMenuItem(this);
                } else {
                    module.exports.previousMenuLevel(parentLi);
                }
                return true;
            },
            40: function (parentLi) { // down
                if ($(this).nextAll('li:visible').length) {
                    module.exports.nextMenuItem(this);
                } else if ($(parentLi).nextAll('li:visible').length) {
                    module.exports.nextMenuItem(parentLi);
                } else if ($(this).closest('.sitenav-primary__li:not(.sitenav__search)').find('.sitenav__category-content').length) { // check for slotted content
                    module.exports.findAnchorItem($(this).closest('.sitenav-primary__li:not(.sitenav__search)'));
                }
                return true;
            }
        },
        function () {
            return $(this).closest('.sitenav-secondary__li');
        }
    );
}

/**
 * keyboard navigation for slotted content in nav
 */
function sitenavCategoryContent() {
    keyboardAccessibility('.sitenav__category-content',
        {
            37: function () { // left
                let $this = $(this);
                if ($this.find('a:visible').length) {
                    let $prevSecondaryLi = $this.parent().children('li:visible').last();
                    previousAnchorItem($this, $prevSecondaryLi);
                }
                return true;
            },
            38: function (parentLi) { // up
                let $this = $(this);
                if (anchorIndexHelper.getIndex() === 0) {
                    let $prevSecondaryLi = $this.parent().children('li:visible').last();

                    if ($prevSecondaryLi.length) { // check if secondary navigation items exist
                        previousAnchorItem($this, $prevSecondaryLi);
                    } else {
                        previousMenuLevel(parentLi);
                    }
                } else if ($this.find('a:visible').length) {
                    previousAnchorItem($this);
                }
                return true;
            },
            40: function () { // down
                let $this = $(this);
                if ($this.find('a:visible').length) {
                    nextAnchorItem($this);
                }
                return true;
            },
            39: function () { // right
                let $this = $(this);
                if ($this.find('a:visible').length) {
                    nextAnchorItem($this);
                }
                return true;
            }
        },
        function () {
            return $(this).closest('.sitenav-primary__li');
        }
    );
}

/**
 * Initialize keyboard event listeners
 */
function init() {
    module.exports.skipLinkNav();
    module.exports.primaryNav();
    module.exports.secondaryNav();
    module.exports.tertiaryNav();
    module.exports.sitenavCategoryContent();
}

module.exports = {
    init: init,
    skipLinkNav: skipLinkNav,
    primaryNav: primaryNav,
    secondaryNav: secondaryNav,
    tertiaryNav: tertiaryNav,
    closeMenu: closeMenu,
    navigateLink: navigateLink,
    tabPrevItem: tabPrevItem,
    tabNextItem: tabNextItem,
    previousMenuItem: previousMenuItem,
    nextMenuItem: nextMenuItem,
    previousMenuLevel: previousMenuLevel,
    nextMenuLevel: nextMenuLevel,
    anchorIndexHelper: anchorIndexHelper,
    findAnchorItem: findAnchorItem,
    previousAnchorItem: previousAnchorItem,
    nextAnchorItem: nextAnchorItem,
    sitenavCategoryContent: sitenavCategoryContent
};
