'use strict';

var Cookies = global.Cookies = require('js-cookie/src/js.cookie'); // for manipulating / retrieving cookies

const cookieLocaleName = 'preferredLanguageCookie';

/**
 * Lanugage Selector
 * This module can be used to facilitate the language selection dialog
 * if the site being browsed is for a different country than the users
 * GeoIP location
 */

/**
 * Show Overlay
 *
 * @param {Event} event JS Event for Link clicked
 */
function showOverlay(event) {
    var numLocales = parseInt($(this).attr('data-num-locales'), 10);

    if (numLocales <= 1) {
        return;
    }

    event.preventDefault();

    $('.language-selection-background').show();

    var countrySelectorOverlay = $('.language-selection-overlay');

    countrySelectorOverlay.css({
        visibility: 'visible',
        bottom: (parseInt(countrySelectorOverlay.height(), 10) + 50) * -1
    });

    countrySelectorOverlay.animate({
        bottom: 0
    }, {
        duration: 1000
    });
}

/**
 * Hide Overlay
 */
function hideOverlay() {
    $('.language-selection-background').hide();
    $('.language-selection-overlay').css({
        visibility: 'hidden'
    });
}

/**
 * Close the Overlay and Cookie the user for the home country
 *
 * @param {event} event DOM Event
 */
function closeOverlay(event) {
    event.preventDefault();

    hideOverlay();
}

module.exports = function () {
    /**
     * When user change the country language from country selector then set cookie to prioritize clicked locale over browser's preferred language.
     */
    $('.language-selection-overlay .js-action__gotocountry, #region-europe-countries-mobile .js-action__gotocountry, #region-europe-countries-desktop .js-action__gotocountry').on('click', function () {
        Cookies.set(cookieLocaleName, true);
    });

    var languageSelectorOverlay = $('.language-selection-overlay');

    /**
     * Get cookie value
     */
    var isClickCountryLink = Cookies.get(cookieLocaleName);


    /**
     * Set cookie value is false
     */
    if (typeof isClickCountryLink !== 'undefined') {
        Cookies.set(cookieLocaleName, false);
    }

    /**
     * If the overlay doesn't exist, return because there is nothing for us to do
     */
    if (languageSelectorOverlay.length <= 0) {
        return;
    }

    /**
     * Event Listeners
     * =========================
     */
    var closeIcon = languageSelectorOverlay.find('.js-action__closeoverlay');
    var countryFlagIcon = $('.js-action__language-selection');
    var overlayBackground = $('.language-selection-background');

    closeIcon.on('click', closeOverlay);
    countryFlagIcon.on('click', showOverlay);
    overlayBackground.on('click', hideOverlay);
};
