'use strict';

const _ = require('underscore');

/**
 * Usage:
 *
 * var keyboardAccessibility = require('./keyboardAccessibility');
 *
 * keyboardAccessibility('.main-menu .sitenav-primary-a', // <-- Selector for keydown event
 *     {
 *         // Keycode and function handler
 *         40: function (menuItem) { // down
 *             if (menuItem.hasClass('nav-item')) { // top level
 *                 $('.navbar-nav .show').removeClass('show')
 *                     .children('.dropdown-menu')
 *                     .removeClass('show');
 *                 menuItem.addClass('show').children('.dropdown-menu').addClass('show');
 *                 $(this).attr('aria-expanded', 'true');
 *                 menuItem.find('ul > li > a')
 *                     .first()
 *                     .focus();
 *                 return true; // <-- Prevent default
 *             } else {
 *                 menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
 *                 $(this).attr('aria-expanded', 'false');
 *                 menuItem.next().children().first().focus();
 *                 return true; // <-- Prevent default
 *             }
 *             return false; // <-- Allow default
 *         },
 *         39: function (menuItem) { // right
 *             // Another function
 *         }
 *     },
 *     function () {
 *         // Prefunction call which passes a jQuery object to the above function.
 *         return $(this).parent();
 *     }
 * );
 */

const buildFunction = function (keyFunctions, preFunction, event, scope) {
    const key = event.which;
    const returnedScope = preFunction.call(scope, event);
    if (keyFunctions[key]) {
        const preventEvent = keyFunctions[key].call(scope, returnedScope, event);
        if (preventEvent) {
            // Generally not needed to block keyboard event unless you want
            // to prevent browser behavior. Tab key is a good example.
            // Or blocking up/down to prevent window scrolling.
            event.stopPropagation();
            event.preventDefault();
        }
    }
};

module.exports = function (selector, keyFunctions, preFunction, delegatedSelector) {
    if (delegatedSelector) {
        $(delegatedSelector).on('keydown', selector, function (e) {
            _.throttle(buildFunction(keyFunctions, preFunction, e, this), 100);
        });
    } else {
        $(selector).on('keydown', function (e) {
            _.throttle(buildFunction(keyFunctions, preFunction, e, this), 100);
        });
    }
};
