'use strict';

/**
 * Returns the value of an auth permission.
 * @param {string} permission - the permission to check
 * @returns {boolean} value of the permission
 */
function customerHasPermission(permission) {
    const value = window.clientData &&
        window.clientData.user &&
        window.clientData.user.permissionsForCurrentAuthState &&
        window.clientData.user.permissionsForCurrentAuthState[permission];

    return !!value; // force to boolean
}

/**
 * Checks permissions and adds click event listeners to a specified target to handle reauthentication logic.
 *
 * When the target is clicked, the function prevents the default action and triggers
 * an authentication event. If authentication is successful, it either redirects to
 * the specified URL or triggers a click on the target element.
 *
 * @param {string} selector - A selector for the target elements to attach the click event listener.
 * @param {string} permission - The permission key to check in the current authentication state.
 */
function registerClickAuthValidator(selector, permission) {
    if (customerHasPermission(permission)) {
        return;
    }

    $(document).on('click.reauth', selector + ':not(body)', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        const $target = $(e.currentTarget);

        $(document).trigger('accountDrawer:reauthenticate', {
            onAuthenticated: function () {
                try {
                    $(document).off('.reauth');

                    /**
                     * Re-trigger the original button click.
                     * Calling JQuery trigger('click') will not work, must use the native click function.
                     * Reference: https://stackoverflow.com/a/18654635
                     */
                    $target[0].click();

                    // 'this' references the login form in the callback. We add a data attribute to allow early exit from redirect behaviors upon successful login.
                    $(this).data('skip-default-nav', true);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error('Original click was not propely routed after re-authentication:', error.message);
                }
            }
        });
    });
}

/**
 * Registers page load event handlers for a specified target to handle reauthentication logic.
 *
 * @param {string} selector - A selector for the target elements to attach the click event listener.
 * @param {string} permission - The permission key to check in the current authentication state.
 *
 */
function registerPageAuthValidator(selector, permission) {
    if (!$(selector).length || customerHasPermission(permission)) {
        return;
    }

    $(window).on('load', function () {
        $(document).trigger('accountDrawer:reauthenticate', {
            onAuthenticated: function () {
                $(document).off('.reauth');
            },
            forcePageReauth: true
        });
    });
}

/**
 * For HTML elements that are loaded/rendered from external sources via iFrames and such,
 * this registers overlay event handlers to trigger reauthentication.
 * @param {string} promptSelector - A selector for the prompt that should be clicked to trigger reauthentication
 * @param {string} overlaySelector - A selector for the overlay that blocks the external HTML elements
 */
function registerExternalElementsOverlayValidator(promptSelector, overlaySelector) {
    /**
     * ATTENTION:
     * We cannot abort event registration based upon whether or not the user has a given permission.
     *
     * Why?
     * The overlay should be server rendered. In the case of lazy loading, a permission that was granted upon page load,
     * may not be granted still when lazy load fires. As such, the click handler always needs to be registered
     * but it will have no effect if there's no overlays.
     *
     * Why is this different than other validators?
     * The other validation scenarios don't render elements that block a user's access to functionality, they just intercept clicks.
     * Also, the other scenarios are not for external HTML elements so if they accidentally get clicked... server-side auth validation
     * works as fallback to redirect them to a log in prompt.
     */

    // Note: intentionally not using the 'reauth' event namespace here so the click handler is never unregistered
    $(document).on('click', promptSelector, function () {
        const $overlays = $(overlaySelector);
        const $prompts = $(promptSelector);

        $(document).trigger('accountDrawer:reauthenticate', {
            onAuthenticated: function () {
                const $loginForm = $(this);

                // Since we've reauthenticated, other reauth click handlers can be disabled for normal interaction
                $(document).off('.reauth');

                // Skip nav so user can click the original button/link
                $loginForm.data('skip-default-nav', true);

                // Remove the existing prompts and overlay(s)
                $overlays.parent().removeClass('disabled');
                $overlays.remove();
                $prompts.remove();
            }
        });
    });

    $(document).on('click', overlaySelector, function () {
        const $prompts = $(promptSelector);

        $prompts.each(function () {
            const $this = $(this);

            // Alert already setup
            if ($this.find('.alert').length) {
                return;
            }

            const html = $this.html();
            $this.empty();
            $this.alert({
                content: html,
                theme: 'warning',
                autoClose: false,
                transition: '',
                dismissible: false
            });
        });
    });
}

/**
 * Registers event handlers for elements requiring PII and Checkout authentication.
 */
function initAuthValidators() {
    /**
     * If they're not allowed to at least view their basic customer info
     * then their state is not applicable to re-authentication.
     */
    if (!customerHasPermission('canViewBasicPersonalInfo')) {
        // Logs out remembered customers when the feature is disabled and customer is unathenticated
        if (window.clientData.user.isRegistered) {
            $.ajax({
                url: window.resources.account.logoutURL,
                type: 'get',
                success: function () {
                    window.location.reload();
                },
                error: function () {
                    console.error('Ajax request to log out remembered customer failed');
                }
            });
        }

        return;
    }

    registerClickAuthValidator('.js-pii_auth-required', 'canViewAndEditPii');
    registerClickAuthValidator('.js-checkout_auth-required', 'canPlaceOrder');
    registerPageAuthValidator('body.js-checkout_auth-required', 'canPlaceOrder');
    registerExternalElementsOverlayValidator('.js-express-payment_auth-required', '.js-express-payment_auth-required-overlay');
}

module.exports = {
    initAuthValidators: initAuthValidators
};
