/* eslint-disable no-console */
module.exports = {
    init: function () {
        const DEBUG_MODE_STORAGE_KEY = 'cscDebugMode';
        const ENABLED_STORAGE_KEY = `${DEBUG_MODE_STORAGE_KEY}.enabled`;

        let enabled;

        Object.defineProperty(window, 'debugMode', {
            configurable: false,
            enumerable: true,
            writable: false,
            value: {
                get enabled() {
                    // Performance precaution: only get the value from local storage on first access, afterwards hold the value in memory.
                    if (enabled === undefined) {
                        const storedValue = localStorage.getItem(ENABLED_STORAGE_KEY);
                        enabled = storedValue === 'true';
                    }

                    return enabled;
                },
                set enabled(value) {
                    if (value !== true) {
                        enabled = false;
                        localStorage.removeItem(ENABLED_STORAGE_KEY);

                        console.log('Debug mode disabled.');
                    } else {
                        enabled = true;
                        localStorage.setItem(ENABLED_STORAGE_KEY, enabled);

                        console.log('Debug mode enabled. If needed, set the browser dev tools to \'preserve log\' across pages.');
                    }
                },
                log: function (msg) {
                    if (this.enabled) {
                        console.log(msg);
                    }
                }
            }
        });
    }
};
