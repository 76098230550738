let dangerZone = 60; // change to a danger theme at this time.
let messageBufferTime = 5; // time to account for page loading when time passes.
const reservationTimerId = 'reservationTimer';
const reservationTimerIdSelector = `#${reservationTimerId}`;

const getExpireTime = () => {
    return $(reservationTimerIdSelector).data('expireTime') || 0;
};

const calculateNewExpireTime = () => {
    return new Date().getTime() + parseInt(window.resources.reservation.reservationSecondsRemaining * 1000, 10);
};

const destroyTimer = () => {
    const $timerBanner = $(reservationTimerIdSelector);
    const reservationInterval = $timerBanner.data('reservationInterval');

    clearInterval(reservationInterval);

    $timerBanner.data('reservationInterval', null);
    $timerBanner.data('expireTime', null);

    if (window.resources && window.resources.reservation && window.resources.reservation.reservationSecondsRemaining) {
        delete window.resources.reservation.reservationSecondsRemaining;
    }
    if ($timerBanner && $timerBanner.length) {
        $timerBanner.trigger('close');
    }
};

const getRemainingSeconds = (expireTime = getExpireTime()) => {
    let now = new Date().getTime();
    let secondsRemaining = parseInt((expireTime - now) / 1000, 10);

    return secondsRemaining;
};

const popBanners = () => {
    if (window.resources && window.resources.reservation && window.resources.reservation.messages) {
        const remainingSeconds = getRemainingSeconds();
        // check each message for a matching time.
        let msgKeys = Object.keys(window.resources.reservation.messages);
        for (let i = 0; i < msgKeys.length; i++) {
            let msgKey = msgKeys[i];
            let msg = window.resources.reservation.messages[msgKey];

            let startTime = typeof msg.startTime !== 'undefined' ? msg.startTime : remainingSeconds; // display it immediately if startTime is undefined.
            let endTime = startTime - messageBufferTime;
            if ((remainingSeconds <= startTime && remainingSeconds > endTime) || typeof msg.startTime === 'undefined') {
                // show the banner:
                let html = `<p class="font-weight-bold">${msg.title}</p>
                            <p>${msg.message}</p>
                            ${msg.additionalContent || ''}`;
                $.alert({
                    content: html,
                    placement: 'banner',
                    theme: msg.theme,
                    id: msgKey,
                    containerId: 'reservationBannerContainer',
                    timeToLive: 10000
                });
                // we've used the message, so remove it from the list
                delete window.resources.reservation.messages[msgKey];
            }
        }
    }
};

const createTimeString = (remainingSeconds) => {
    let mins = Math.floor(remainingSeconds / 60);
    let secs = remainingSeconds % 60;
    if (secs < 10) {
        secs = '0' + secs; // convert to a string so we can have two digits. 9 => '09'
    }
    return `${mins}:${secs}`;
};

const updateReservationTimer = () => {
    const remainingSeconds = getRemainingSeconds();

    if (remainingSeconds >= 0) {
        $('.reservation__time').text(createTimeString(remainingSeconds));

        if (remainingSeconds <= dangerZone) {
            // change the theme from 'info' to 'danger'
            $(`${reservationTimerIdSelector}.alert--info`).toggleClass('alert--info alert--danger');
        } else {
            $(`${reservationTimerIdSelector}.alert--danger`).toggleClass('alert--info alert--danger');
        }

        popBanners();
    } else {
        destroyTimer();
    }
};

const startTimer = () => {
    const $timerBanner = $(reservationTimerIdSelector);
    const expireTime = calculateNewExpireTime();

    if (!$timerBanner || !$timerBanner.length) { // $timerBanner might be undefined, empty jquery object (truthy), or a valid jquery object (length 1)
        const remainingSeconds = getRemainingSeconds(expireTime);

        if (remainingSeconds >= 0) { // when remainingSeconds === 0, we still want to display '0:00'
            let initialTimeStr = createTimeString(remainingSeconds);
            let timeRemainingMessage = window.resources.reservation.timeRemainingMessage;
            const reservationInterval = setInterval(updateReservationTimer, 1000);

            $.alert({
                content: `<div class="reservation-timer">${timeRemainingMessage} <strong class="reservation__time">${initialTimeStr}</strong></div>`,
                placement: 'banner',
                theme: window.resources.reservation.reservationSecondsRemaining <= dangerZone ? 'danger' : 'info',
                id: reservationTimerId,
                containerId: 'reservationBannerContainer',
                autoClose: false
            }).data('reservationInterval', reservationInterval).data('expireTime', expireTime);
        }
    } else {
        $timerBanner.data('expireTime', expireTime);
    }
};

const init = () => {
    if (window.resources && window.resources.reservation && window.resources.reservation.reservationSecondsRemaining) {
        startTimer();
    }
    // remove any existing banners.
    $('#reservationBannerContainer').find('.alert').not(reservationTimerIdSelector).remove();
    popBanners();
};

const updateReservationData = (response) => {
    if (response && response.reservation) {
        if (window.resources) {
            if (window.resources.reservation) {
                window.resources.reservation.messages = $.extend({}, window.resources.reservation.messages, response.reservation.messages);
                window.resources.reservation.reservationSecondsRemaining = response.reservation.reservationSecondsRemaining;
                $(reservationTimerIdSelector).data('remainingSeconds', parseInt(response.reservation.reservationSecondsRemaining, 10));
            } else {
                window.resources.reservation = response.reservation;
            }
        } else {
            window.resources = {
                reservation: response.reservation
            };
        }
        init();
    }
};

const getDestroyTimer = () => {
    return destroyTimer;
};

module.exports = {
    init,
    getDestroyTimer,
    updateReservationData
};
