'use strict';

/**
 * Remove overview modal and backdrop
 */
function closeModal() {
    $('.overview-modal, .modal-backdrop').remove();

    // Account for scrollbars to prevent CLS
    $.enableScroll();
    $('body').removeClass('modal-open').css('padding-right', '');
    $(window).trigger('scroll');
}

/**
 * Builds HTML element that will display the overview benefit modal contents
 * @param {HTML} html response used render modal content
 * @returns {HTMLElement} Reference to modal JQuery object
 */
function buildModal(html) {
    const $modal = $.modal({
        content: html,
        customClasses: 'overview-modal',
        modalSizeClass: 'modal-lg'
    }).on('hidden.bs.modal', () => {
        closeModal();
    });

    return $modal;
}

/**
 * Fetches overview html from server and launches modal
 * @param {string} url  - Overview controller endpoint url
 * @param {HTMLElement} $modal - Reference to modal JQuery object
 */
function launchModal(url) {
    if (!url) {
        return;
    }

    $.ajax({
        url: url,
        method: 'GET',
        dataType: 'html',
        success: (data) => {
            buildModal(data);
        },
        complete: () => {
            $.spinner().stop();
            $.disableScroll();
        },
        error: () => {
            closeModal();
        }
    });
}

module.exports = {
    init: function () {
        $('body')
            .off('click.overview', '.js-overview-modal')
            .on('click.overview', '.js-overview-modal', (e) => {
                e.preventDefault();
                let $this = $(e.currentTarget);
                let link = $this.data('link') || $this.attr('href');

                launchModal(link);
            });
    },
    buildModal,
    launchModal,
    closeModal
};
