'use strict';

(function ($) {
    $.fn.parallax = function (options) { // eslint-disable-line no-param-reassign
        let defaults = {
            speed: 'slow'
        };
        let settings = $.extend({}, defaults, options);

        // get the viewport
        let getViewport = () => ({
            height: $(window).height()
        });

        window.parallax = {};

        let resizeDebounce;
        $(window).off('.parallax')
            .on('load.parallax resize.parallax', () => {
                clearTimeout(resizeDebounce);
                resizeDebounce = setTimeout(() => {
                    window.parallax.viewport = getViewport();
                    this.trigger('parallax_measure');
                    this.trigger('parallax_position');
                }, 100);
            })
            .on('scroll.parallax', () => {
                this.trigger('parallax_position');
            });

        return $(this).each(function () {
            let $this = $(this);
            let data = $this.data('parallax') || {};
            let speed = data.speed ? data.speed : settings.speed;
            let $container = $this.parent();
            let contentHeight;
            let container;
            let containerHeight;
            let heightDiff;

            if ($container.css('position') === 'static') {
                $container.css('position', 'relative');
            }

            $this.css({
                position: 'absolute',
                'z-index': -1
            });

            // measuring content height, container position and height, and height difference on resize
            $this.on('parallax_measure', () => {
                contentHeight = $this.outerHeight();
                container = $container.offset();
                containerHeight = $container.outerHeight();
                heightDiff = containerHeight - contentHeight;
            });

            // on scroll
            $this.on('parallax_position', () => {
                let scrollAmount = $(window).scrollTop();
                let pointA;
                let pointB;
                let progress;
                let position;

                if (speed === 'slow') {
                    pointA = (container.top + containerHeight) - window.parallax.viewport.height;
                    pointB = container.top;
                    progress = (scrollAmount - pointA) / (pointB - pointA);
                    position = heightDiff - (heightDiff * progress);
                } else {
                    pointA = container.top - window.parallax.viewport.height;
                    pointB = container.top + containerHeight;
                    progress = (scrollAmount - pointA) / (pointB - pointA);
                    position = heightDiff * progress;
                }

                $this.css('top', position);
            });
        });
    };
}(jQuery));
