/**
 * Main Script File
 *
 * This is loaded in the footer of all pages. This is the preferred place to
 * load all script files.
 */

var processInclude = require('../components/processInclude');
processInclude(require('../components/rewardsTracker'));

