'use strict';
var breakpoints = require('../components/breakpoints');
var tabbedContainer = require('../aem/tabbedContainer');

/**
 * Show unit toggle control if .js-no-unit-toggle class is not present
 */
function showUnitToggle() {
    if ($('.tabbed-container__tabs .js-no-unit-toggle.active').is(':visible')) {
        $('.js-unit-toggle').addClass('d-none');
    } else {
        $('.js-unit-toggle').removeClass('d-none');
    }
}

/**
 *
 * @param {string} categorySuffix - the category to be shown
 * @param {string} sectionDiv - the section to be shown
 * @param {string} buttonText - the text to be replace the top accordion
 * @param {string} gender - text containing the gender option to show on the top most accordion upon selection
 */
function toggleCategorySizeFit(categorySuffix, sectionDiv, buttonText, gender) {
    if (typeof categorySuffix === 'undefined' || typeof sectionDiv === 'undefined') {
        return;
    }

    var $categorySelected = $('div.size-guides-' + categorySuffix);
    var $divSelected = $('div.' + sectionDiv);
    var $activeCategory = $('.category-shown');
    var $activeSection = $('.section-shown');

    if ($activeCategory) {
        $activeCategory.addClass('d-none').removeClass('category-shown');
    }

    if ($activeSection) {
        $activeSection.addClass('d-none').removeClass('section-shown');
    }

    $categorySelected.removeClass('d-none').addClass('category-shown');
    $divSelected.removeClass('d-none').addClass('section-shown');
    var menuText = gender + ' > ' + buttonText;
    var $menu = $('a.selectedCategory div.accordion__title__text');
    $menu.html(menuText);

    // Determine if unit toggle control should display
    showUnitToggle();

    // Click tab to ensure proper tabbed content height
    $('.ofs__item.active:visible').trigger('click');

    var genderCategory = gender.replace(/[^\w ]+/g, '').toLowerCase();

    $('#selectionCategory-' + genderCategory).collapse('toggle');
    $('#selectionAccordion').collapse('toggle');
}

/**
 * to select a category from the list
 * @param {Object} $element category id value
 */
function selectCategory($element) {
    var elementIdParts = $element.id.split('-');
    var categorySuffix = elementIdParts[0] + '-' + $element.dataset.subcat;
    var genderToShow = $element.dataset.gendershow;
    var sectionDiv = elementIdParts[1];
    var buttonText = $element.innerText;
    toggleCategorySizeFit(categorySuffix, sectionDiv, buttonText, genderToShow);
}

/**
 * convert inches-to-centimeters and vice-versa
 * @param {boolean} useCentimeters if true then convert to centimeters, else convert to inches
 * @param {string} value value to convert
 * @returns {string} converted value
 */
function unitConversion(useCentimeters, value) {
    var result;
    var radix = 10;
    var unit = (useCentimeters) ? 2.54 : 0.3937;
    if (value.indexOf('-') > -1) {
        var values = value.split('-');
        values.forEach(function (size) {
            if (!isNaN(size)) {
                result = (result == null) ? parseFloat((Number(size) * unit).toFixed(1), radix) : result + '-' + parseFloat((Number(size) * unit).toFixed(1), radix);
            }
        });
    } else if (!isNaN(value)) {
        result = parseFloat((Number(value) * unit).toFixed(1), radix);
    }

    return result;
}

/**
 * function to read the measurement values and set respective converted value
 * @param {boolean} useCentimeters if true then convert to centimeters, else convert to inches
 */
function toggleMeasurements(useCentimeters) {
    const $toggleTables = $('.size-chart-asset table');

    $toggleTables.each(function () {
        const $toggleTable = $(this);
        const $columnHeaders = $toggleTable.find('thead th'); // get table column headers

        $toggleTable.find('.js-convert-column').each(function () {
            const nthIndex = $columnHeaders.index(this) + 1; // index of .js-convert-column element relative to it's siblings
            const $toggleCells = $toggleTable.find('tbody td:nth-child(' + nthIndex + ')'); // get table cells in the same column as .js-convert-column

            $toggleCells.each(function () {
                const $toggleCell = $(this);
                const convertedValue = unitConversion(useCentimeters, $toggleCell.text()); // convert values on relevant table cells

                if (convertedValue) {
                    $toggleCell.text(convertedValue);
                }
            });
        });
    });
}

/**
 * Clear table of all styling classes
 * @param {Object} $tableElement - the table that will have the styling removed from its elements
 */
function clearTableClasses($tableElement) {
    $tableElement.find('th, td').removeClass('selected-table-element cross-section-header cross-section-data');
}

/** Adds the appropriate styling to the table element being passed
 * @param {Object} $tableElement - element to be styled, based of th/td tag
 */
function applyTableElementStyle($tableElement) {
    var className;
    className = $tableElement.is('th') ? 'cross-section-header' : 'cross-section-data';
    $tableElement.addClass(className);
}

/** For the table in the measurement section
 * @param {Object} tableElement - table element to be focussed on for cross section
 * @returns {boolean} - false to break the loop, true to move on to the next iteration in the each loop.
 */
function tableCrossSection(tableElement) {
    var $tdElement = $(tableElement);
    var $trElement = $tdElement.closest('tr');
    var containsTdElement = $trElement.has('td').length;
    var elementIndex = $tdElement.index();
    var $tableElement = $tdElement.closest('table');
    clearTableClasses($tableElement);
    $tdElement.addClass('selected-table-element');

    // Check if row contains td element, if not it is the header row and
    // cross section doesn't need to be calculated
    if (containsTdElement && elementIndex) {
        // Loop through each table row and apply cross section styling
        $tableElement.find('tr').each(function (iterator, rowElement) {
            var $rowElement = $(rowElement);

            // If table row does not contain the moused over cell,
            // apply styling to the cell in the same column
            if (!$rowElement.has('td.selected-table-element').length) {
                var $rowCell = $($rowElement.find('th, td')[elementIndex]);
                applyTableElementStyle($rowCell);
            } else {
                // Apply styling to the entire row, prior to the moused over element
                $rowElement.children().each(function (columnIndex, childElement) {
                    var $childElement = $(childElement);
                    if (columnIndex < elementIndex) {
                        applyTableElementStyle($childElement);
                    }
                });
                return false;
            }
            return true;
        });
    }
    return true;
}

/**
 * Create scroll buttons when the table is larger than the size of the screen
 * TODO: Tech Debt: https://columbia.atlassian.net/browse/GSD-18362 - Remove createScrollButtons()
 */
function createScrollButtons() {
    var $activeContent = $('.js-sizefit-content:visible .tabbed-container__content.active');
    var overflow = $activeContent.find('table.size-chart-asset__table').outerWidth() - $activeContent.outerWidth();
    if (overflow > 0 && breakpoints.isLowRes()) {
        $('.js-scroll-button').remove(); // remove old instances
        var tableHeaderHeight = $activeContent.find('table.size-chart-asset__table th').css('height');
        var marginLeft = parseInt($activeContent.find('table.size-chart-asset__table th').css('width'), 10) + 1 + 'px'; // Adds extra pixel for outline
        var $rightScrollButton = `<button class="btn btn-icon scroll-table-right js-scroll-button" data-overflow="${overflow}" style="height: ${tableHeaderHeight}">
        <svg class="svgicon" aria-hidden="true"><use href="#chevron_right"></use></svg><span class="aria-offscreen">Scroll right</span></button>`;
        var $leftScrollButton = `<button class="btn btn-icon scroll-table-left js-scroll-button d-none" data-overflow="0" style="height: ${tableHeaderHeight}; left: ${marginLeft};"><svg class="svgicon" aria-hidden="true"><use href="#chevron_left"></use></svg><span class="aria-offscreen">Scroll left</span></button>`;
        $activeContent.append($leftScrollButton);
        $activeContent.append($rightScrollButton);
    }
}

/**
 * Switches position of scroll buttons
 * @param {Object} $container - jQuery element of table element
 */
function scrollButtonPosition($container) {
    var $table = $container.find('table.size-chart-asset__table');
    var tableOverflow = $table.outerWidth() - $container.outerWidth();
    var $tableOffset = $table.offset();
    var offsetLeft = $tableOffset.left - 48; // remove 3rem offset

    if (tableOverflow + offsetLeft < 2) {
        $('.scroll-table-right').addClass('d-none');
        $('.js-scroll-button.scroll-table-left.d-none').removeClass('d-none');
    } else if (offsetLeft < 2 && offsetLeft > 0) {
        $('.scroll-table-left').addClass('d-none');
        $('.js-scroll-button.scroll-table-right.d-none').removeClass('d-none');
    }
}

/**
 * Highlights fit selection in Fit Guide content asset
 */
function highlightFitSelection() {
    const $sizeFitModal = $('.sizefit-modal');
    const fitAsset = $('.js-apparel-fit').data('fit-asset');
    const selection = $sizeFitModal.find('[id*="' + fitAsset + '"]');

    // Adds outline to content asset wrapper
    selection.addClass('outline');
}

module.exports = {
    initSizeFit: function () {
        const $unitToggles = $('.js-unit-toggle');

        createScrollButtons();
        highlightFitSelection(); // Highlight apparel fit in content asset

        // TODO: Add event delegation / investigate if needed.
        $('.js-sizefit-option').on('click', function () {
            selectCategory(this);
            $(window).trigger('resize'); // this fixes the height of the overflow slider tabs
        });

        // inches/cm toggle
        $unitToggles.on('click', function () {
            var $element = $(this);
            var useCentimeters = false;
            if ($element.hasClass('inches')) {
                $unitToggles.addClass('centi').removeClass('inches');
                useCentimeters = true;
            } else {
                $unitToggles.addClass('inches').removeClass('centi');
            }

            toggleMeasurements(useCentimeters);
        });

        $('body').on('click', '.ofs__item', function () {
            createScrollButtons();
        });

        $('body').on('click', '.js-no-unit-toggle', function () {
            showUnitToggle();
        });

        $('.js-sizefit-content').on('shown.bs.collapse', function () {
            $(window).trigger('resize'); // this fixes the height of the overflow slider tabs
            // and this fixes the height of the active content container which was hidden, so had a height of 0:
            $(this).find('.tabbed-container__content-container').each((index, container) => {
                var $container = $(container);
                return $container.css('height', $container.find('.active').outerHeight());
            });
            createScrollButtons();
        });

        $('body').on('click', '.js-scroll-button', function () {
            var $activeTable = $('.js-sizefit-content:visible .tabbed-container__content.active .size-chart-asset');
            var $currentScrollButton = $(this);
            $activeTable.animate({ scrollLeft: $currentScrollButton.data('overflow') }, 200, function () {
                scrollButtonPosition($activeTable);
            });
        });
        $('body').on('mouseover', '.size-chart-asset__table td, .size-chart-asset__table th', function () {
            tableCrossSection(this);
        });
        $('body').on('mouseleave', 'table', function () {
            var $tableElement = $(this);
            clearTableClasses($tableElement);
        });
        $('.size-chart-asset').on('scroll', function () {
            var $container = $(this);
            scrollButtonPosition($container);
        });
    },
    getSizeFitGuideContent: function ($modalContentWrapper, url) {
        var successHandler = (html) => {
            // append the template HTML of the interactive size/fit guide to the '.modal-body'
            $modalContentWrapper.find('.modal-body').html(html);

            // initialize the event handlers and interactive behavior
            $modalContentWrapper.find('.overflow-slider').overflowSlider();
            tabbedContainer.init();
            $modalContentWrapper.find('.tabbed-container__content-container .active').siblings().hide(); // full page load already happened so we need to do this explicitly
            this.initSizeFit();
        };

        // fetch the HTML content for the interactive size/fit guide and
        $.ajax({
            url: url,
            method: 'GET',
            dataType: 'json',
            success: (data) => {
                if (!$modalContentWrapper[0].parentElement) {
                    // success was called before the modal is shown.
                    $modalContentWrapper.on('shown.bs.modal', () => {
                        successHandler(data.renderedTemplate);
                    });
                } else {
                    successHandler(data.renderedTemplate);
                }
            },
            error: () => {
                $modalContentWrapper.modal('hide');
            }
        });
    }
};
