'use strict';

(function ($) {
    /**
     * Creates a "progress bar" labeled with steps at regular intervals.
     * @param {Oject|string} options The options object passed in when creating the stepBar component, or a string command for interacting with an existing stepBar component.
     * @param {*} args Additional parameters when calling a method (passing a string in the options parameter). See methods below for details.
     * @returns {jQuery} A jQuery handle to the stepBar component.
     */
    $.fn.stepBar = function (options, args) { // eslint-disable-line no-param-reassign
        const defaults = {
            steps: [],
            progress: 0.25,
            theme: ''
        };
        const CLASS_COMPLETED = 'stepbar__step--complete';
        const CLASS_ACTIVE = 'stepbar__step--active';

        /**
         * Merges default settings, passed in options, and settings found in data attributes to return a single object containing all settings.
         * @param {element} el The element containing the stepBar, which might have data attributes.
         * @param {Object} optionsObj The optional options object that is passed in when creating a stepBar component.
         * @returns {Object} The merged objects, containing all settings for this stepBar instance.
         */
        const getSettings = function (el, optionsObj) {
            let dataOptions = {};
            let dataProgress = $(el).data('progress');
            let dataSteps = $(el).data('steps');

            if (dataProgress) {
                let newProgress;
                if (typeof dataProgress === 'string' && dataProgress.includes('%')) {
                    // if for example, data-progress="30%", convert to 0.3
                    newProgress = parseFloat(dataProgress) / 100;
                } else {
                    newProgress = parseFloat(dataProgress);
                }
                if (!isNaN(newProgress)) {
                    dataOptions.progress = newProgress;
                }
            }

            if (dataSteps) {
                let newSteps = dataSteps.split(',');
                if (newSteps.length) {
                    dataOptions.steps = newSteps;
                }
            }

            return $.extend({}, defaults, optionsObj, dataOptions);
        };

        /**
         * Generates a string of markup for the steps in the stepBar, including inline style for the position of each step.
         * @param {array} steps An array of labels (strings) for the steps in the stepBar.
         * @returns {string} The generated markup string.
         */
        const getStepsMarkup = function (steps) {
            let stepsMarkup = '';
            let numSteps = steps.length;
            let leftPosition = 'auto';

            steps.forEach((step, i) => {
                let label = typeof step === 'object' ? step.label : step;

                if (label) {
                    if (typeof step === 'object' && step.position) {
                        leftPosition = (step.position * 100) + '%';
                    } else if (numSteps > 1) {
                        leftPosition = ((100 / (numSteps - 1)) * i) + '%';
                    }
                    stepsMarkup += `<div class="stepbar__step" style="left: ${leftPosition};">${label}</div>`;
                }
            });

            return stepsMarkup;
        };

        /**
         * Builds the markup for a stepBar component and appends it to the given element.
         * @param {element} el The container element that will receive the stepBar component.
         * @param {Object} settings An object containing the settings for this stepBar component.
         * @returns {jQuery} A jQuery handle to the generated stepBar.
         */
        const init = function (el, settings) {
            let stepsMarkup = getStepsMarkup(settings.steps);
            let theme = settings.theme ? `stepbar--${settings.theme}` : '';

            const $stepBar = $(`
                <div class="stepbar ${theme}" data-progress="0">
                    <div class="stepbar__track">
                        <div class="stepbar__progress"></div>
                    </div>
                    <div class="stepbar__steps">
                        ${stepsMarkup}
                    </div>
                </div>
            `);

            $(el).append($stepBar);

            return $stepBar;
        };

        /**
         * Updates the progress bar or the steps in the given stepBar component.
         * @param {jQuery} $stepBar The component to be updated
         * @param {Object} settings An object containing all the settings to be updated.
         */
        const updateProgress = function ($stepBar, settings) {
            if (!settings) {
                // No settings?! What are we supposed to update? Just return, I guess.
                return;
            }

            if (settings.steps) {
                // update / replace the steps
                $stepBar.find('.stepbar__steps').html(getStepsMarkup(settings.steps));
            }

            const progress = settings.progress !== undefined ? settings.progress : $stepBar.data('progress');

            if (settings.progress !== undefined) {
                $stepBar.find('.stepbar__progress').css({
                    width: `${progress * 100}%`
                });
                $stepBar.data('progress', progress);
            }

            // set completed and active steps based on progress percentage
            let $steps = $stepBar.find('.stepbar__step').removeClass([CLASS_COMPLETED, CLASS_ACTIVE]);
            let barLength = $stepBar.find('.stepbar__track').width();
            let $lastActiveStep;
            let maxActivePosition = 0;

            $steps.each(function () {
                let $step = $(this);
                let stepPos = parseFloat($step.css('left')) / barLength;

                if (stepPos <= progress) {
                    $step.addClass(CLASS_COMPLETED);

                    if (stepPos >= maxActivePosition) {
                        maxActivePosition = stepPos;
                        $lastActiveStep = $step;
                    }
                }
            });

            if ($lastActiveStep) {
                $lastActiveStep.addClass(CLASS_ACTIVE);
            }
        };

        /**
         * METHODS
         * if options is a string, it's probably a method call
         */
        if (typeof options === 'string') {
            const $container = $(this);
            const method = options.toLowerCase();
            const $stepBar = $container.find('.stepbar');
            if (method === 'update') {
                /**
                 * Method: update
                 * Updates an existing stepBar component with the given progress and/or steps.
                 * @example
                 *     // update the progress, no change to the steps
                 *     $('.existingStepBarComponent').stepBar('update', {
                 *         progress: 0.8
                 *     });
                 *     // result looks something like this:
                 *     //  ( )======( )======(/)==----( )
                 *     // Step1    Step2    Step3    Step4
                 * @example
                 *     // update the steps and progress (steps are replaced and repositioned)
                 *     $('.existingStepBarComponent').stepBar('update', {
                 *         steps: ['Start', 'New step', 'End'],
                 *         progress: 0.5
                 *     });
                 *     // result looks something like this:
                 *     //  ( )===========(/)-----------( )
                 *     // Start        New step        End
                 */
                updateProgress($stepBar, args);
            } else if (method === 'addstep') {
                /**
                 * Method: addStep
                 * Insert a step at the specified location on the bar.
                 * @example
                 *     // create a standard step bar with evenly spaced steps
                 *     $('.existingStepBarComponent').stepBar({ steps: [1, 2, 3], progress: 0.5});
                 *     // add a step
                 *     $('.existingStepBarComponent').stepBar('addStep', { label: '2.5', position: 0.75 });
                 *     // result looks something like this:
                 *     // ( )===========(/)----( )----( )
                 *     //  1             2     2.5     3
                 */
                let label;
                let leftPosition;

                if (typeof args === 'object') {
                    label = args.label;
                    leftPosition = (args.position * 100) + '%';
                }

                if (label && leftPosition !== undefined) {
                    let markup = `<div class="stepbar__step" style="left: ${leftPosition};">${label}</div>`;
                    let $stepsContainer = $stepBar.find('.stepbar__steps');
                    $stepsContainer.append(markup);
                    updateProgress($stepBar, {});
                }
            }

            return $container;
        }

        return this.each(function () {
            const settings = getSettings(this, options);
            const $container = $(this);
            const $stepBar = init(this, settings);
            let firstStepOffset = $stepBar.find('.stepbar__step:first').outerWidth() / 2;
            let lastStepOffset = $stepBar.find('.stepbar__step:last').outerWidth() / 2;
            let offsets = firstStepOffset + lastStepOffset;
            let $track = $stepBar.find('.stepbar__track');
            let tallestHeight = $track.position().top + $track.height(); // default height to the track size
            let totalWidth = 0;

            $stepBar.find('.stepbar__step').each(function () {
                let $thisStep = $(this);

                // add to the total width
                totalWidth += $thisStep.outerWidth();

                // get height of tallest step
                let h = $thisStep.outerHeight();
                if (h > tallestHeight) tallestHeight = h;
            });

            // set element widths
            $stepBar.find('.stepbar__track, .stepbar__steps').css({
                left: firstStepOffset,
                width: `calc(100% - ${offsets}px)`, // using calc() is better for when resizing the window
                minWidth: totalWidth - offsets
            }).filter('.stepbar__steps').css('height', tallestHeight); // steps are absolutely positioned, so set a height to prevent overlaping content

            // set the progress bar width
            updateProgress($stepBar, { progress: settings.progress });

            return $container;
        });
    };
}(jQuery));
