/* eslint-disable no-underscore-dangle */
'use strict';

(function () {
    $.fn.wizard = function (options) {
        const $thisWiz = $(this);
        const defaults = {
            initialStep: 0, // step id (string) or index (integer)
            activeClass: 'active',
            init: function ($wiz) {
                // `this` is the wizard element
                $wiz.on('click', '.next', function (e) {
                    $wiz.nextStep(e, true); // the 2nd param as true will call beforeStepExit before proceeding
                });
            },
            afterStepEnter: function (stepId, $wiz) {}, // eslint-disable-line no-unused-vars
            beforeStepExit: function (e, stepId, $wiz) { // eslint-disable-line no-unused-vars
                // `this` is the current step element
                return true; // return true to continue to next step; return false to remain on the current step.
            },
            updateProgress: function ($wiz) {
                const $progress = $(this);
                $progress.text(`${$progress.data('step')} / ${$wiz.numSteps}`);
            }
        };

        /**
         * Finds and returns the index of the current "active" step.
         * @returns {integer} The index of the current step.
         */
        const _getCurrentStepIndex = function () {
            return $thisWiz.steps.indexOf($thisWiz.currentStepId);
        };

        /**
         * Returns the id of the step. If the step argument is not a number, it is returned unchanged.
         * @param {integer|string} step The index or id of the step
         * @returns {string} The id of the step.
         */
        const _getStepId = function (step) {
            // step could be an index or a name
            if (isNaN(step)) {
                return step;
            }

            return $thisWiz.steps[step];
        };

        /**
         * Gets the jQuery object of the requested step.
         * @param {integer|string} step The index or id of the step
         * @returns {jQuery} The jQuery step object
         */
        const _getStep = function (step) {
            // step could be an index or a name
            let stepId = _getStepId(step);

            return $thisWiz.find(`.wizard__step[data-step-id=${stepId}]`);
        };

        /**
         * Updates the data attributes of the progress element and calls the public updateProgress() function
         * which can be used to display the progress.
         */
        const _updateProgress = function () {
            const $progress = $thisWiz.find('.wizard__progress');
            const stepIndex = _getCurrentStepIndex($thisWiz);
            $progress.data({
                step: stepIndex + 1,
                'num-steps': $thisWiz.numSteps,
                progress: (stepIndex + 1) / $thisWiz.numSteps
            });
            $thisWiz.settings.updateProgress.call($progress[0], $thisWiz);
        };

        /**
         * Moves the wizard to the requested step. In the process, calls the public functions beforeStepExit and afterStepEnter.
         * @param {Event} e The event that triggered this function call which is passed through to the beforeStepExit function.
         * @param {integer|string} targetStepIdOrIndex The index or id of the step we're going to.
         */
        const _gotoStep = function (e, targetStepIdOrIndex) {
            if ($thisWiz.data('isAnimating')) {
                return;
            }

            const $currentStep = $thisWiz.find(`.wizard__step.${$thisWiz.settings.activeClass}`);

            let proceed = true;

            // preventing infinite loop if beforeStepExit() calls gotoStep()
            if (!$thisWiz.data('beforeStepExitInProgress')) {
                $thisWiz.data('beforeStepExitInProgress', true);

                proceed = $thisWiz.settings.beforeStepExit.call($currentStep[0], e, $thisWiz.currentStepId, $thisWiz);

                $thisWiz.data('beforeStepExitInProgress', false);
            }

            if (proceed !== false) {
                const $targetStep = _getStep(targetStepIdOrIndex);
                if ($targetStep.length) {
                    $thisWiz.data('isAnimating', true);
                    $thisWiz.currentStepId = _getStepId(targetStepIdOrIndex); // targetStepIdOrIndex can be step id (string) or index (integer)

                    const $stepContainer = $currentStep.parent();
                    $stepContainer.css({
                        height: $stepContainer.outerHeight(),
                        overflow: 'hidden'
                    });

                    $currentStep.css({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0
                    });
                    $targetStep.css({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0
                    });

                    $currentStep.removeClass($thisWiz.settings.activeClass).fadeOut();
                    $targetStep.addClass($thisWiz.settings.activeClass).fadeIn(null, function () {
                        $currentStep.css('position', 'static');
                        $targetStep.css('position', 'static');

                        $stepContainer.animate({ height: $targetStep.outerHeight() }, null, null, function () {
                            $stepContainer.css({
                                height: 'auto',
                                overflow: 'visible'
                            });
                            $thisWiz.data('isAnimating', false);
                        });
                    });

                    _updateProgress();
                    $thisWiz.settings.afterStepEnter.call($targetStep[0], $thisWiz.currentStepId, $thisWiz);
                } else {
                    console.warn('Step not found:', targetStepIdOrIndex);
                }
            }
        };

        /**
         * This is a shortcut function to _gotoStep(step + 1).
         * @param {Event} e The event that triggered this function call which is passed through to the _gotoStep function.
         */
        const _nextStep = function (e) {
            const nextStepIndex = _getCurrentStepIndex() + 1;
            if (nextStepIndex < $thisWiz.numSteps) {
                _gotoStep(e, $thisWiz.steps[nextStepIndex]);
            } else {
                // else out of bounds
                console.warn('This is the last step');
            }
        };

        /**
         * This is a shortcut function to _gotoStep(step - 1).
         * @param {Event} e The event that triggered this function call which is passed through to the _gotoStep function.
         */
        const _prevStep = function (e) {
            const prevStepIndex = _getCurrentStepIndex() - 1;
            if (prevStepIndex >= 0) {
                _gotoStep(e, $thisWiz.steps[prevStepIndex]);
            } else {
                // else out of bounds
                console.warn('This is the first step');
            }
        };

        const settings = $.extend({}, defaults, options);
        $thisWiz.steps = [];
        $thisWiz.find('.wizard__step').each(function () {
            $thisWiz.steps.push($(this).data('step-id'));
        });
        $thisWiz.numSteps = $thisWiz.steps.length;
        $thisWiz.settings = settings;
        $thisWiz.currentStepId = _getStepId(settings.initialStep);

        // expose functions
        $thisWiz.gotoStep = _gotoStep;
        $thisWiz.nextStep = _nextStep;
        $thisWiz.prevStep = _prevStep;

        /**
         * Fetches and inserts a step into the wizard.
         * @param {string} id The id of the step that is to be inserted
         * @param {string} src The URL that will return the HTML markup for the step content
         * @param {integer} index The index of which to place this step. If omitted, the step is appended to the end.
         */
        $thisWiz.fetchStep = function (id, src, index) {
            const $step = $(`<div class="wizard__step" data-step-id="${id}"></div>`);
            $.get(src).done(function (stepHtml) {
                $step.html(stepHtml);
            });

            if (index === undefined) {
                index = $thisWiz.numSteps; // eslint-disable-line no-param-reassign
            }

            if (index === 0) {
                const $stepContainer = $thisWiz.find('.wizard__step').parent();
                $stepContainer.prepend($step);
            } else {
                const $prevStep = _getStep(index - 1);
                $prevStep.after($step);
            }

            $thisWiz.steps.splice(index, 0, id);
            $thisWiz.numSteps++;
        };

        /**
         * Removes a step from the wizard.
         * @param {integer|string} step The index or id of the step to be removed.
         */
        $thisWiz.removeStep = function (step) {
            const id = _getStepId(step);
            const index = $thisWiz.steps.indexOf(id);
            _getStep(id).remove();
            $thisWiz.steps.splice(index, 1);
            $thisWiz.numSteps--;
        };

        const $firstStep = _getStep(settings.initialStep);
        $firstStep.addClass('active');

        // set styles
        const $stepContainer = $firstStep.parent();
        if ($stepContainer.css('position') === 'static') {
            $stepContainer.css('position', 'relative');
        }
        $thisWiz.find('.wizard__step').css({
            display: 'none'
        });
        $firstStep.css('display', 'block');

        _updateProgress();

        $thisWiz.settings.init.call(this, $thisWiz);

        $thisWiz.settings.afterStepEnter.call($firstStep[0], $thisWiz.currentStepId, $thisWiz);
        return $thisWiz;
    };
}(jQuery));
