'use strict';

/**
 * Show the Tier Expiration Warning message if the user's expiration date is within 30 days
 */
function showTierExpirationWarning() {
    const $expirationWarning = $('.js-tier-expiration-warning');
    // expiration warning will not be present if user is not in an expirable tier
    if (!$expirationWarning.length) {
        return;
    }

    let expirationDate = new Date($expirationWarning.data('tier-expiration-date'));
    let alertPeriod = $expirationWarning.data('tier-expiration-alert-period');
    let timeDiff = (expirationDate - new Date()) / (1000 * 3600 * 24); // convert millis to days
    if (timeDiff <= alertPeriod && timeDiff > 0) { // inbetween expiration date and 30 till
        $expirationWarning.removeClass('d-none');
    }
}

/**
 * Private Function: Determines StepBar progress settings
 * @param {string} balance - the rewards balance value
 * @return {Object} - with progress settings object
 */
function getStepBarSettings(balance) {
    const numOfSteps = 3;
    const stepInterval = 5; // Interval between steps - in this case $5 increments
    const overhang = stepInterval * 0.5; // the amount the progress bar extends beyond the first and last step.
    let steps = [];
    let progress;
    let barStartValue; // start position of the progress bar
    let barEndValue; // end position
    let barLengthInRewardsUnits;

    if (balance < stepInterval * (numOfSteps - 1)) {
        // first step is on left edge, last step is inset
        // (00)===---(05)------(10)---
        // (00)======(05)===---(10)---
        barStartValue = 0;
        barEndValue = (stepInterval * (numOfSteps - 1)) + overhang;

        for (let i = 0; i < numOfSteps; i++) {
            let stepVal = stepInterval * i;
            steps.push({
                label: '$' + stepVal,
                position: stepVal / barEndValue
            });
        }

        progress = balance / barEndValue;
    } else {
        // first and last step are inset, progress is between last two steps
        // ===(05)===========(10)======-----(15)---
        // ===(10)======(15)======(20)===---(25)---
        let activeStepVal = balance - (balance % stepInterval); // round down to nearest step
        let firstStepVal = activeStepVal - (stepInterval * (numOfSteps - 2));
        if (firstStepVal < stepInterval) {
            firstStepVal = stepInterval;
        }
        let firstStepIndex = firstStepVal / stepInterval;

        barStartValue = activeStepVal - ((stepInterval * (numOfSteps - 2)) + overhang);
        barEndValue = activeStepVal + (stepInterval + overhang);
        barLengthInRewardsUnits = barEndValue - barStartValue;

        for (let i = firstStepIndex; i < firstStepIndex + numOfSteps; i++) {
            let stepVal = stepInterval * i;
            steps.push({
                label: '$' + stepVal,
                position: (stepVal - barStartValue) / barLengthInRewardsUnits
            });
        }

        progress = (balance - barStartValue) / barLengthInRewardsUnits;
    }

    return {
        steps: steps,
        progress: progress,
        theme: 'slim'
    };
}

/**
 * Private Function: Adds progress icon above StepBar
 * @param {Object} $rewardsTracker - the rewards balance value
 * @param {string} balance - the rewards balance value
 */
function addProgressIcon($rewardsTracker, balance) {
    let $rewardsCaret = $('<svg class="svgicon" width="24" height="24"><title>$' + balance + '</title><use href="#location"></use></svg>');

    $rewardsCaret.css({
        position: 'absolute',
        right: 0,
        bottom: '150%',
        transform: 'translate(50%, -0.0625rem)'
    });

    $rewardsTracker.find('.stepbar__progress').append($rewardsCaret);
}

/**
 * Loads Rewards Tracker StepBar
 * @param {Object} $container - jQuery container object
 */
function loadRewardsTracker($container) {
    // eslint-disable-next-line no-param-reassign
    $container = ($container && $container.length) ? $container : $(document);
    let $oldRewardsTracker = $container.find('.js-old-rewards-tracker');
    let $rewardsTracker = $container.find('.js-rewards-tracker');

    if ($rewardsTracker.length) {
        // Initialize the Rewards Tracker Circular Progress Indicator
        if ($('.rewards-tracker__redemption-progress').length) {
            $rewardsTracker.find('.progress').progressArc();
        }

        showTierExpirationWarning();
    } else if ($oldRewardsTracker.length) {
        let rewardsBalance = $oldRewardsTracker.data('rewards-balance');
        let formattedBalance = (rewardsBalance < 0) ? rewardsBalance = 0 : rewardsBalance; // Necessary for the edge case of a customer having negative rewards balance

        $oldRewardsTracker.each(function () {
            let $tracker = $(this);
            if ($tracker.data('stepbar-active') !== true) {
                $tracker.stepBar(getStepBarSettings(formattedBalance));
                addProgressIcon($tracker, formattedBalance);
                $tracker.data('stepbar-active', true);
            }
        });
    }
}

module.exports = {
    loadRewardsTracker: loadRewardsTracker
};
