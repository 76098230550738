module.exports = function () {
    var scrollToElement = function (target, offset, duration) {
        if (target.length > 1) {
            var $target = $(target);
            if ($target.length > 0 && $target.is(':visible')) { // if target is hidden, no way to get the position of the element
                var position = $target.offset();
                var off = (typeof offset !== 'undefined') ? offset : 0;
                var dur = (typeof duration !== 'undefined') ? duration : 1000;
                $('body, html').stop().animate({
                    scrollTop: position.top - off
                }, dur);
            }
        }
    };

    var headerOffset = $('.js-site-header').height();
    $(function () {
        var hash = location.hash;
        var badChars = new RegExp('[?=&]');
        if (!badChars.test(hash) && $(hash).length) {  // ignore hashes that are misused.
            // if it's an accordion, open it
            $(hash).show();
            // scroll to the element
            scrollToElement(hash, headerOffset, 1000);
        }
    });
};
