'use strict';

/**
 * This file has the pdp actions that are going to be shared between quickview and pdp
 * The methods included here are initialized globally, and there is no need to initialize them
 * again on PDP
 */

var base = require('./base');

module.exports = {
    selectAttribute: base.selectAttribute,
    availability: base.availability,
    addToCart: base.addToCart,
    showSelectedSwatchValue: base.quickViewSwatchFunctionality
};
