'use strict';

const loggingPrefix = 'dataLayerPersistence';

/**
 * Mirror the user-specific data layer fields from a source to destination object.
 * @param {Object} source - source data object
 * @param {Object} destination - destination data object
 */
function mirrorUserSpecificFields(source, destination) {
    // Note: window.digitalData.transaction is user specifc but only applies to the order confirmation page and thus not being cached
    const userSpecificFields = ['cart', 'user'];

    userSpecificFields.forEach(function (fieldName) {
        if (fieldName in source) {
            // eslint-disable-next-line no-param-reassign
            destination[fieldName] = source[fieldName];
        } else {
            // eslint-disable-next-line no-param-reassign
            delete destination[fieldName];
        }
    });
}

/**
 * Caches the user specific parts of the data layer in local storage.
 * Note: this code can be debugged by switching tabs but it cannot be debugged on page unload so for that case...
 * 1. set window.debugMode.enabled = true
 * 2. set browser dev tools to preserve the console log
 * @param {Date} dataVersionDate - date & time the data was retrieved from the server
 * @param {boolean} dataIsFreshFromServer - true if caching data that was just pulled fresh from the server
 */
function cacheUserSpecificData(dataVersionDate, dataIsFreshFromServer) {
    /**
     * Cookie dl_cud: Data Layer - Client User Data
     * A more verbose/readable name and value increases cookie size by 3x
     * so opting for a shorter format here to reduce overhead on all requests.
     */
    const cookieName = 'dl_cud';

    // Do not change the storage key unless code is added to delete entries made under the old key
    const localStorageKey = 'dataLayerPersistence.userSpecificData';

    /**
     * Per https://documentation.b2c.commercecloud.salesforce.com/DOC2/topic/com.demandware.dochelp/DWAPI/scriptapi/html/api/class_dw_system_Session.html
     * A soft timeout occurs 30 minutes after the last request has been made.
     * The soft timeout logs out and clears all privacy data, but it is still possible to use the session ID to reopen the session.
     * A hard timeout renders a session ID invalid after six hours, even if the session is still in use. The hard timeout prevents a session from being reopened.
     * -----
     * To avoid issues, we will buffer the timeout by removing an additional 5 minutes.
     */
    const sfccSoftSessionTimeoutInMinutes = 30 - 5;
    const currentDate = new Date();
    const minutesElapsed = (currentDate - dataVersionDate) / 1000 / 60;

    if (minutesElapsed > sfccSoftSessionTimeoutInMinutes) {
        window.debugMode.log(`${loggingPrefix}: skipping caching because data is older than the SFCC soft session timeout`);
        return;
    }

    const Cookies = require('js-cookie/src/js.cookie');
    const currentCookieValue = Cookies.get(cookieName);
    const INVALIDATED_BY_SERVER = 'i';
    const CLIENT_CACHED = 'c';

    if (currentCookieValue === INVALIDATED_BY_SERVER) {
        localStorage.removeItem(localStorageKey);
        window.debugMode.log(`${loggingPrefix}: previously cached data cleared because server set '${cookieName}' cookie to '${INVALIDATED_BY_SERVER}' (invalidated)`);

        if (!dataIsFreshFromServer) {
            window.debugMode.log(`${loggingPrefix}: aborting caching of stale data after server invalidated it`);
            return;
        }
    }

    // Safeguard: if something went wrong with populating the data layer, don't perpetuate the problem by caching it
    if (!window.digitalData || !window.digitalData.user) {
        // eslint-disable-next-line no-console
        console.error(`${loggingPrefix}: aborting caching because 'window.digitalData.user' is missing`);
        return;
    }

    const sessionSpecificLocalStorageHelper = require('../helper/sessionSpecificLocalStorageHelper');
    let cacheEntry = sessionSpecificLocalStorageHelper.getItem(localStorageKey);

    if (cacheEntry) {
        const wrapperVersionDate = cacheEntry.versionDate ? new Date(cacheEntry.versionDate) : null;

        // Handles multiple tab browsing. The existing stored data is newer so don't overwrite it.
        if (wrapperVersionDate && wrapperVersionDate > dataVersionDate) {
            window.debugMode.log(`${loggingPrefix}: caching skipped, existing cached data is newer`);
            return;
        }
    }

    cacheEntry = {
        versionDate: dataVersionDate.toJSON(),
        data: {},
        /**
         * localStorage is not intended to expire. As such, if the storage structure changes, the code will need to account for it.
         * In such a scenario, increase this version number and clear the stored data for older versions.
         */
        storageVersion: 1
    };

    mirrorUserSpecificFields(window.digitalData, cacheEntry.data);

    const setSuccessful = sessionSpecificLocalStorageHelper.setItem(localStorageKey, cacheEntry);

    if (setSuccessful) {
        window.debugMode.log(`${loggingPrefix}: data cached successfully in local storage under the '${localStorageKey}' key`);

        const expirationDate = new Date(dataVersionDate.getTime() + (sfccSoftSessionTimeoutInMinutes * 60 * 1000));

        Cookies.set(cookieName, CLIENT_CACHED, { expires: expirationDate, secure: true });
        window.debugMode.log(`${loggingPrefix}: ${cookieName} cookie set to '${CLIENT_CACHED}' (cached)`);
    }
}

module.exports = {
    init: function () {
        let dataLayerDataVersionDate = new Date();

        $(function () {
            if (window.digitalData && window.digitalData.userSpecificDataPopulatedFromClientCache) {
                var userSpecificDataUrl = $('.js-data-layer-script').data('user-specific-data-url');
                var updateSuccess = false;

                window.debugMode.log(`${loggingPrefix}: user-specific data layer populated using the client cache, starting request for updated data from server...`);

                $.ajax({
                    url: userSpecificDataUrl,
                    method: 'GET',
                    success: function (responseJSON) {
                        if (responseJSON.dtmLayer && responseJSON.dtmLayer.user) {
                            mirrorUserSpecificFields(responseJSON.dtmLayer, window.digitalData);
                            updateSuccess = true;

                            window.debugMode.log(`${loggingPrefix}: successfully updated user-specific data from server, caching data...`);
                            dataLayerDataVersionDate = new Date();
                            cacheUserSpecificData(dataLayerDataVersionDate, true);

                            $(document).trigger('dataLayer:userSpecificData:updated');
                        }
                    },
                    complete: function () {
                        if (!updateSuccess) {
                            // eslint-disable-next-line no-console
                            console.error(`${loggingPrefix}: error getting updated user-specific data from server`);
                        }
                    }
                });
            } else {
                window.debugMode.log(`${loggingPrefix}: user-specific data layer populated in page response from server, caching data...`);
                cacheUserSpecificData(dataLayerDataVersionDate, true);
            }
        });

        /**
         * Update the cache whenever closing tabs, switching tabs, or navigating away in case it was updated by client code.
         * Per https://developer.mozilla.org/en-US/docs/Web/API/Window/unload_event
         * visibilitychange is a better and most performant alternative to the 'unload' event.
         */
        $(document).on('visibilitychange', () => {
            /**
             * Regardless of closing tabs, switching tabs, or navigating away... the state will be hidden.
             * Note: the debugger may not fire breakpoints when the tab is closing but it can be debugged by switching tabs.
             */
            if (document.visibilityState === 'hidden') {
                window.debugMode.log(`${loggingPrefix}: caching user-specific data as part of navigating or closing/switching tabs...`);
                cacheUserSpecificData(dataLayerDataVersionDate, false);
            }
        });
    }
};
