'use strict';

const keyboardNav = require('./searchKeyboardNav');

const endpoint = $('.suggestions-wrapper').data('url');
const minChars = 3;
const headerSearchContainerSelector = '.js-header-search';
const Cookies = global.Cookies = require('js-cookie/src/js.cookie');

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    const $searchContainer = $(scope).closest(headerSearchContainerSelector);
    const $wrapper = $searchContainer.find('.suggestions-wrapper');
    return $wrapper;
}

/**
 * Suggestions wrapper toggle
 *
 * @param {Object} scope - Search field DOM element
 */
function toggleSuggestionsWrapperContent(scope) {
    const $suggestionsWrapper = module.exports.getSuggestionsWrapper(scope);
    $suggestionsWrapper.removeClass('d-none').addClass('d-block');

    const $quicklinks = $suggestionsWrapper.find('.js-search-quicklinks');
    const $suggestions = $suggestionsWrapper.find('.js-search-suggestions');
    if ($suggestions.text().replace(/\s/g, '').length !== 0) {
        $quicklinks.removeClass('d-block').addClass('d-none');
        $suggestions.removeClass('d-none').addClass('d-block');
    } else {
        $suggestions.removeClass('d-block').addClass('d-none');
        if ($(scope).val()) {
            $quicklinks.removeClass('d-block').addClass('d-none');
        } else {
            $quicklinks.removeClass('d-none').addClass('d-block');
        }
    }
}

/**
 * In the 'did you mean' section, highlight the search text entered by user
 * @param {string} searchText - Search text entered by user
 */
function highlightSearchKeywords(searchText) {
    if ($('.suggestions__items--did-you-mean-term').length) {
        const $suggestionTextElem = $('.suggestions__items--did-you-mean-term a').eq(0);
        const suggestionText = $suggestionTextElem.text();

        const matchStart = suggestionText.toLowerCase().indexOf('' + searchText.toLowerCase() + '');
        if (matchStart !== -1) {
            const matchEnd = matchStart + (searchText.length);
            const beforeMatch = suggestionText.slice(0, matchStart);
            const matchText = suggestionText.slice(matchStart, matchEnd);
            const afterMatch = suggestionText.slice(matchEnd);
            $suggestionTextElem.html(beforeMatch + '<em>' + matchText + '</em>' + afterMatch);
        }
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    const $suggestionsWrapper = module.exports.getSuggestionsWrapper(this);
    const $suggestions = $suggestionsWrapper.find('.js-search-suggestions');
    $suggestions.empty();

    if (typeof (response) !== 'object') {
        $suggestions.append(response);
        module.exports.highlightSearchKeywords($(this).val());
    }
    module.exports.toggleSuggestionsWrapperContent(this);
}

/**
 * Retrieve suggestions via
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: module.exports.processResponse
        });
    } else {
        $('.js-search-suggestions').empty();
        module.exports.toggleSuggestionsWrapperContent(scope);
    }
}

/**
 * Tear down search and close popover
 * @param {jQuery} $target jquery reference to target element
 */
function tearDownSearch($target) {
    $(headerSearchContainerSelector).removeClass('js-show-search expanded');
    $('.suggestions-wrapper').removeClass('d-block').addClass('d-none');

    if ($target && $target.hasClass('js-action-search-close')) {
        $('.js-search-suggestions').empty();
        $('.js-search-field').val('');
    }
    $.enableScroll();
}

/**
 * Open Desktop Search
 * @param {jQuery} $searchOpen jquery reference search element to open
 */
function openDesktopSearch($searchOpen) {
    $.disableScroll();
    $searchOpen.addClass('expanded');
    $('.js-search-field', $searchOpen).trigger('focus');
    // close search when it loses focus
    $searchOpen.loseFocus(tearDownSearch, '.globalbanner, header, .page, footer');
}

/**
 * Open Mobile Search
 * @param {jQuery} $searchOpen jquery reference search element to open
 */
function openMobileSearch($searchOpen) {
    $.disableScroll();
    $searchOpen.addClass('js-show-search');
    $('.js-search-field', $searchOpen).trigger('focus');
    $('.refinement-bar button.close').trigger('click'); // close refinement bar on mobile
}

/**
 * Search event handlers
 */
function suggestionKeyHandler() {
    keyboardNav.init();

    $('input.js-search-field').each(function () {
        const tabKey = 9;
        let timeout = null;
        $(this).on('keyup', function (e) {
            if (e.which !== tabKey) {
                clearTimeout(timeout);
                // Make a new timeout set to go off in 800ms
                timeout = setTimeout(() => module.exports.getSuggestions(e.target), 500);
            }
        });

        $(this).on('focus', function () {
            $.disableScroll();
            module.exports.toggleSuggestionsWrapperContent(this);
        });
    });
}

/**
 * JS action events search
 */
function suggestionClickHandler() {
    $('body').on('click', '.suggestions__items--did-you-mean a, .suggestions__items--product a, .suggestions__items--category a, .suggestions__items--content a, .quicklinks a', function (e) {
        e.preventDefault();
        let searchElement;
        const searchMethod = $(this).data('searchmethod');
        if (searchMethod === 'quicklinksSearch') {
            searchElement = this.innerText;
        }
        const redirectURL = $(this).attr('href');
        const cookieName = 'searchMethodCookie';
        Cookies.set(cookieName, { searchMethod: searchMethod, searchElement: searchElement }, { expires: 1 });
        if (redirectURL) {
            window.location.href = redirectURL;
        }
    });
}

/**
 * JS action events search
 */
function searchSubmitHandler() {
    // Setting data analytics search method for manual search.
    $('.js-header-search form, .js-search-popover form').on('submit', function (e) {
        e.preventDefault();

        // Avoid an unnecessary post to the server by catching an empty search entry
        const searchValue = $(this).find('.js-search-field').val().trim();
        if (searchValue) {
            const searchMethod = $(this).data('searchmethod');
            const cookieName = 'searchMethodCookie';
            Cookies.set(cookieName, { searchMethod: searchMethod }, { expires: 1 });
            $(this)[0].submit();
        }
    });
}

/**
 * JS action events search
 */
function searchClickOpenHandler() {
    $('.js-action-search').on('click', function (e) {
        e.preventDefault();
        const $searchContainer = $(e.target).closest(headerSearchContainerSelector);
        if ($searchContainer.hasClass('search-mobile')) {
            module.exports.openMobileSearch($searchContainer);
        } else {
            module.exports.openDesktopSearch($searchContainer);
        }
    });
}

/**
 * JS action search close
 */
function searchClickCloseHandler() {
    $('.js-action-search-close').on('click', function (e) {
        e.preventDefault();
        module.exports.tearDownSearch($(e.currentTarget));
    });
}

/**
 *  To call all search events
 */
function searchEvents() {
    module.exports.suggestionKeyHandler();
    module.exports.suggestionClickHandler();
    module.exports.searchSubmitHandler();
    module.exports.searchClickOpenHandler();
    module.exports.searchClickCloseHandler();
}

/**
 *  Function for child classes to call for initialization
 */
function init() {
    module.exports.keyboardNav.init();
    module.exports.searchEvents();
}

module.exports = {
    // expose methods for use within brand cartridges
    getSuggestionsWrapper: getSuggestionsWrapper,
    toggleSuggestionsWrapperContent: toggleSuggestionsWrapperContent,
    highlightSearchKeywords: highlightSearchKeywords,
    processResponse: processResponse,
    getSuggestions: getSuggestions,
    tearDownSearch: tearDownSearch,
    openDesktopSearch: openDesktopSearch,
    openMobileSearch: openMobileSearch,
    suggestionKeyHandler: suggestionKeyHandler,
    searchClickOpenHandler: searchClickOpenHandler,
    searchClickCloseHandler: searchClickCloseHandler,
    searchSubmitHandler: searchSubmitHandler,
    suggestionClickHandler: suggestionClickHandler,
    searchEvents: searchEvents,
    init: init,
    headerSearchContainerSelector: headerSearchContainerSelector,
    keyboardNav: keyboardNav
};
