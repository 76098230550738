/* eslint-disable no-underscore-dangle */
'use strict';

(function ($) {
    const DEFAULT_TOAST_PARENTSELECTOR = 'body';
    const DEFAULT_TOAST_CONTAINERID = 'defaultToaster';
    const DEFAULT_BANNER_PARENTSELECTOR = '.siteheader__bottom';
    const DEFAULT_BANNER_CONTAINERID = 'defaultBannerContainer';
    const DEFAULT_BANNER_CONTAINERATTACHMENT = 'after';
    const TRANSITION_DURATION = 400;
    const AUTOTIMING_BASE_SECONDS = 5;
    const AUTOTIMING_WORDSPERSECOND = 5; // timing ~= 5 + (wordCount / 5)
    const DEFAULT_ICON = {
        primary: '',
        success: 'check_circle',
        info: 'help_outline',
        warning: 'warning',
        danger: 'error'
    };
    const PLACEMENT = {
        toast: 'toast',
        banner: 'banner',
        inline: 'inline'
    };

    const _attach = function ($parent, $child, attachment) {
        switch (attachment) {
            case 'prepend':
                $parent.prepend($child);
                break;
            case 'before':
                $parent.before($child);
                break;
            case 'after':
                $parent.after($child);
                break;
            default: // 'append'
                $parent.append($child);
        }
    };

    const _close = function ($alert) {
        $alert.trigger('alert.close');

        if ($alert.hasClass('alert--slide')) {
            $alert.slideUp(TRANSITION_DURATION, 'swing', function () {
                $alert.trigger('alert.closed');
                $alert.remove();
            });
        } else if ($alert.hasClass('alert--fade')) {
            $alert.fadeOut(TRANSITION_DURATION, 'linear', function () {
                $alert.trigger('alert.closed');
                $alert.remove();
            });
        } else {
            $alert.trigger('alert.closed');
            $alert.remove();
        }
    };

    $.alert = function (options) { // eslint-disable-line no-param-reassign
        if (typeof options === 'string') {
            // $.alert('string');
            // create an alert with default settings.
            let contentString = options;
            options = { // eslint-disable-line no-param-reassign
                content: contentString
            };
        } else if (options && typeof options.jquery === 'string') {
            // $.alert($('.content'));
            // a jQuery object was passed. Create an alert with default settings.
            options = { // eslint-disable-line no-param-reassign
                content: options.html()
            };
        }
        // else we have this: $.alert({...});

        let settings = $.extend({}, $.alert.defaults, options);

        if (!Object.values(PLACEMENT).includes(settings.placement)) {
            // if the user didn't specify a placement, or specificed an invalid placement, fall back to the default value.
            settings.placement = $.alert.defaults.placement;
        }

        switch (settings.placement) {
            case PLACEMENT.banner:
                if (!settings.parentSelector) {
                    settings.parentSelector = DEFAULT_BANNER_PARENTSELECTOR;
                }
                if (!settings.containerAttachment) {
                    settings.containerAttachment = DEFAULT_BANNER_CONTAINERATTACHMENT;
                }
                if (!settings.containerId) {
                    settings.containerId = DEFAULT_BANNER_CONTAINERID;
                }
                break;
            case PLACEMENT.inline:
                break;
            default: // PLACEMENT.toast
                if (!settings.parentSelector) {
                    settings.parentSelector = DEFAULT_TOAST_PARENTSELECTOR;
                }
                // containerAttachment will default to DEFAULT_TOAST_CONTAINERATTACHMENT (append)
                if (!settings.containerId) {
                    settings.containerId = DEFAULT_TOAST_CONTAINERID;
                }
                break;
        }

        // Select default icon value if given value is not a string. Empty string will omit the icon.
        if (typeof settings.icon !== 'string') {
            settings.icon = DEFAULT_ICON[settings.theme];
        }

        if (settings.autoClose) {
            if (settings.autoTiming) {
                let wordCount = $(`<span>${settings.content}</span>`).text().trim().split(/\s+/).length;
                let seconds = AUTOTIMING_BASE_SECONDS + (wordCount / AUTOTIMING_WORDSPERSECOND);
                settings.timeToLive = Math.ceil(seconds) * 1000;
            } else {
                // make sure timeToLive is a number, not a string.
                settings.timeToLive = parseInt(settings.timeToLive, 10);
            }
        }

        if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            // the user prefers reduced motion - don't slide or fade, just show/hide instantly
            settings.transition = '';
        }

        if (settings.debug) {
            console.debug(settings); // eslint-disable-line no-console
        }

        const TEMPLATE = {
            closeBtn: '<button type="button" class="alert__close-btn js-alert-close" aria-label="Close"><svg class="svgicon" aria-hidden="true"><use href="#close"></use></svg></button>',
            icon: `<svg class="svgicon alert__icon" aria-hidden="true"><use href="#${settings.icon}"></use></svg>`,
            timeBar: '<div class="alert__timebar"></div>',
            bannerContainer: `<div id="${settings.containerId}" class="alert__banner-container"></div>`,
            toaster: `<div id="${settings.containerId}" class="alert__toaster"></div>`
        };
        TEMPLATE.alert = `<div class="alert alert--${settings.placement} alert--${settings.theme} ${settings.customClass}" role="alert" aria-hidden="false" aria-live="${settings.placement === 'banner' ? 'assertive' : 'polite'}">
                        ${settings.icon ? TEMPLATE.icon : ''}
                        <div class="alert__content">
                            ${settings.content}
                        </div>
                        ${settings.dismissible ? TEMPLATE.closeBtn : ''}
                    </div>`;

        let $container;
        if (settings.placement === PLACEMENT.inline) {
            // for inline alerts, the container should already be on the page, and the parent is not used.
            $container = settings.containerId ? $('#' + settings.containerId) : $(settings.parentSelector);
        } else {
            $container = $('#' + settings.containerId);
            let $parent = $(settings.parentSelector);
            if (!$container.length) {
                // container doesn't exist. Select the corresponding container to be attached.
                $container = settings.placement === PLACEMENT.banner ? $(TEMPLATE.bannerContainer) : $(TEMPLATE.toaster);
            }
            // attach the container to the parent, or move it into position. (in some cases this does nothing, when the container is already in position)
            _attach($parent, $container, settings.containerAttachment);
        }

        // BUILD THE ALERT COMPONENT
        const $alert = $(TEMPLATE.alert);

        if (settings.id) {
            $alert.attr('id', settings.id);
        }

        // attach the alert to the container
        _attach($container, $alert, settings.attachment);

        // show the alert (slide/fade/appear instantly)
        setTimeout(function () {
            $alert.trigger('alert.show');
        });

        if (settings.transition === 'slide') {
            $alert.addClass('alert--slide');
            $alert.hide().slideDown(TRANSITION_DURATION, 'swing', function () {
                $alert.trigger('alert.shown');
            });
        } else if (settings.transition === 'fade') {
            $alert.addClass('alert--fade');
            $alert.hide().fadeIn(TRANSITION_DURATION, 'linear', function () {
                $alert.trigger('alert.shown');
            });
        } else {
            $alert.trigger('alert.shown');
        }

        $alert.close = function () {
            _close($alert);
        };

        let autoCloseTimeout;
        if (settings.autoClose && settings.timeToLive) {
            if (settings.showTimeBar) {
                // if we have a timebar, use that for the timing of the closing.
                const $timebar = $(TEMPLATE.timeBar);
                $alert.append($timebar);

                $timebar.animate({
                    width: 0
                }, settings.timeToLive, 'linear', function () {
                    _close($alert);
                });
            } else {
                // ...else use a timeout.
                autoCloseTimeout = setTimeout(function () {
                    _close($alert);
                }, settings.timeToLive);
            }
        }

        $alert.one('click', '.js-alert-close', function (e) {
            e.preventDefault();
            clearTimeout(autoCloseTimeout);
            _close($alert);
        });

        $alert.one('close', function (e) {
            e.preventDefault();
            clearTimeout(autoCloseTimeout);
            _close($alert);
        });

        return $alert;
    };

    $.alert.defaults = {  // eslint-disable-line no-param-reassign
        content: '',
        placement: PLACEMENT.toast,
        theme: 'danger', // CSS style: 'primary', 'success', 'info', 'warning', 'danger',
        icon: null, // specify a svg icon to override the theme's default icon
        dismissible: true,
        attachment: 'append', // 'append', 'prepend', 'before', 'after'
        transition: 'slide', // or 'fade' or empty string to instantly appear / disappear
        autoClose: true,
        autoTiming: false, // if true, sets timeToLive to 5s + 1s per 4 words in content.
        timeToLive: 7000, // ms before auto closing. (only applies if autoClose is true and autoTiming is false)
        id: null, // used by reservable products to select specific content.
        customClass: '',
        showTimeBar: false,
        // container parameters
        parentSelector: '',
        containerId: '', // only used for banners and toast
        containerAttachment: '',
        debug: false
    };

    $.fn.alert = function (options) { // eslint-disable-line no-param-reassign
        let settings;
        let $alertCollection = $();

        this.each(function () {
            if (typeof options === 'undefined') {
                // example: $('.content').alert();
                // options is undefined, so `this` (selected element) will be the content.
                settings = {
                    content: this.innerHTML
                };
            } else if (options.jquery) {
                // example: $('.alert-container').alert($('.alert-content'));
                // options is a jQuery obejct which will be the content, and `this` will be the container/parent and placement will be 'inline'.
                let content = '';
                options.each(function () {
                    content += this.innerHTML;
                });

                if (!content) {
                    // no need to keep looping - they'll all have no content.
                    return false; // break
                }

                settings = {
                    content: content,
                    parentSelector: this,
                    placement: PLACEMENT.inline
                };
            } else if (typeof options === 'object' && !options.content) {
                // example: $('.toast-content').alert({});
                // options is object, but options.content is not defined so `this` will be the content and the placement will default to 'toast'.
                settings = $.extend({
                    content: this.innerHTML
                }, options);
            } else if (typeof options === 'string') {
                // example: $('.alert-container').alert('Hello world');
                // `options` is a string so `this` will be the container/parent and placement will be 'inline'.
                settings = {
                    content: options,
                    parentSelector: this,
                    placement: PLACEMENT.inline
                };
            } else {
                // example: $('.alert-container').alert({ content: 'Hello world'});
                // options.content is defined so `this` will be the container/parent and placement will be 'inline'
                settings = $.extend({
                    parentSelector: this,
                    placement: PLACEMENT.inline
                }, options);
            }

            let $alert = $.alert(settings);
            $alertCollection = $alertCollection.add($alert);

            return true; // continue
        });

        $alertCollection.close = function () {
            $alertCollection.each(function () {
                _close($(this));
            });
        };

        return $alertCollection;
    };
}(jQuery));

// click handler for close button on inline alert module (inlineAlert.isml)
$('body').on('click', '.js-alert-module-close', function () {
    const $alert = $(this).closest('.alert');
    $alert.trigger('alert.close');
    $alert.slideUp(null, null, function () {
        $alert.trigger('alert.closed');
    });
});

// support for old BS alerts
$('body').on('click', '[data-dismiss=alert]', function () {
    const $alert = $(this).closest('.alert');
    $alert.trigger('alert.close').trigger('close.bs.alert');
    $alert.slideUp(null, null, function () {
        $alert.trigger('alert.closed').trigger('closed.bs.alert');
        $alert.remove();
    });
});
