'use strict';

/**
 * The imageRollOver function takes the element and builds an image swap from the existing source and an active state
 * of a file of the same name with '_active' appended to it. if the active image is not in the S7 repo
 * then the function does nothing.
 *
 * @param {string} selector The selector is the component on which the image roll over is going to be placed.
 */
window.imageRollOver = function (selector) { // eslint-disable-line no-unused-vars
    var $imgWrapper = $(selector);
    var $image;
    if ($imgWrapper[0].tagName.toLowerCase() === 'img') {
        $image = $imgWrapper;
    } else {
        $image = $imgWrapper.find('img');
    }
    var imageSource = $image.attr('src');  // Original source
    var targetSource = imageSource.split('?');
    targetSource[0] += '_active?';
    targetSource = targetSource.join('');  // Hover active source
    try {
        $.get(targetSource)
            .done(function () {
                $imgWrapper.on('mouseenter', function () {
                    $image.attr('src', targetSource);

                    $imgWrapper.one('mouseleave', function () {
                        $image.attr('src', imageSource);
                    });
                });
            });
    } catch (error) {
        // image not found
    }
};
