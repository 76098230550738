'use strict';

/**
 * JS for Loyalty relates stuff (used by Columbia and MHW for now)
 */

const formHelpers = require('../checkout/formErrors');

module.exports = {
    /**
     * Setup handler for when a customer opts in to enroll for the rewards system, in My accounts dashboard
     */
    onEnrollRewards: function () {
        $('body').on('submit', 'form.js-rewards-join-now-form', function (e) {
            e.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');
            var redirectUrl = $form.data('redirect-url');
            var isMobileMenu = $form.data('mobile-menu');
            var $signUpBtn = $('.js-loyalty-banner-signup-btn');

            $signUpBtn.prop('disabled', true);
            $.spinner().start();

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        var loyaltyEnrollSuccessMessage = $('.js-enroll-message-success').first().text();
                        var emailEnrollSuccessMessage = $('.js-enroll-email-success').first().text();
                        var successBanners = function () {
                            sessionStorage.setItem('loyaltyEnrollSuccessMessage', loyaltyEnrollSuccessMessage);
                            if (data.isEmailOptIn) {
                                sessionStorage.setItem('emailEnrollSuccessMessage', emailEnrollSuccessMessage);
                            }
                        };
                        if (redirectUrl) {
                            successBanners();
                            window.location.href = redirectUrl;
                        } else {
                            if (isMobileMenu) {
                                $('.sitenav').removeClass('open');
                            }

                            successBanners();
                            window.location.reload(true);
                        }
                    } else {
                        let contentMessage = data.errorURL ?
                            `<a href='${data.errorURL}'>${data.errorMessage}</a>` :
                            data.errorMessage;

                        $.alert({
                            content: contentMessage,
                            placement: 'banner',
                            theme: 'danger'
                        });
                        $signUpBtn.prop('disabled', false);
                        $.spinner().stop();
                    }
                },
                error: function (data) {
                    $.alert({
                        content: data.errorMessage,
                        placement: 'banner',
                        theme: 'danger'
                    });
                    $signUpBtn.prop('disabled', false);
                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    /**
     * Setup handler for when a customer opts out of the rewards system
     */
    onOptOutRewards: function () {
        $('form.js-rewards-opt-out').on('submit', function (e) {
            e.preventDefault();
            var $form = $(this);
            var $errorMsg = $form.find('.js-opt-out-error');
            var url = $form.attr('action');
            var redirect = $form.data('redirect');
            var closeModalBtn = $('#loyaltyOptOutModal');

            // Remove Error Message
            $errorMsg.addClass('d-none');
            $.spinner().start();
            closeModalBtn.modal('hide');
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        if (redirect) {
                            window.location.href = redirect;
                        } else {
                            window.location.reload(true);
                        }
                    } else {
                        // Show Error Message
                        $errorMsg.removeClass('d-none');
                        $.spinner().stop();
                        closeModalBtn.modal('show');
                    }
                },
                error: function () {
                    // Show Error Message
                    $errorMsg.removeClass('d-none');
                    $.spinner().stop();
                    closeModalBtn.modal('show');
                }
            });
            return false;
        });
    },

    /**
     * Setup handler for optin banners
     */
    onLoyaltyEnroll: function () {
        let loyaltyEnrollSuccessMessage = sessionStorage.getItem('loyaltyEnrollSuccessMessage');
        let emailEnrollSuccessMessage = sessionStorage.getItem('emailEnrollSuccessMessage');
        if (loyaltyEnrollSuccessMessage || emailEnrollSuccessMessage) {
            $(function () {
                if (loyaltyEnrollSuccessMessage) {
                    $.alert({
                        content: loyaltyEnrollSuccessMessage,
                        placement: 'banner',
                        theme: 'success'
                    });
                    sessionStorage.removeItem('loyaltyEnrollSuccessMessage');
                }

                if (emailEnrollSuccessMessage) {
                    $.alert({
                        content: emailEnrollSuccessMessage,
                        placement: 'banner',
                        theme: 'success'
                    });
                    sessionStorage.removeItem('emailEnrollSuccessMessage');
                }
            });
        }
    },

    /**
     * In Columbia loyalty dashboard, the opt out link requires a modal confirmation
     */
    onloyaltyDashboardOptOut: function () {
        $('.js-link-loyalty-opt-out').on('click', function (e) {
            e.preventDefault();

            var $modal = $('#loyaltyOptOutModal');
            var $errorMsg = $modal.find('.js-opt-out-error');

            // Remove Error Message
            $errorMsg.addClass('d-none');

            $modal.modal('show');
        });
    },

    /**
     * Setup pop-up email opt-in form
     */
    initEmailPopupForm: function () {
        const $step1 = $('.js-subscribe-form-step1');
        const $step1Submit = $('.js-subscribe-form-step1Submit');
        const $step1EmailErr = $('.js-email-subscribed-error');
        const $step2 = $('.js-subscribe-form-step2');
        const $step2Email = $('.email-address-value');
        const $step2Submit = $('.js-subscribe-form-step2submit');
        const $step3 = $('.signup-success-container');

        $step1.on('submit', function (e) {
            const $form = $(this);
            const url = $form.attr('action');
            e.preventDefault();
            $.spinner().start();
            $step1Submit.prop('disabled', true);

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        $step1EmailErr.text('').hide();
                        $step2Email.attr('value', data.email);
                        $step1.addClass('d-none');
                        $step2.removeClass('d-none');
                    } else if (data.msg) {
                        $step2.addClass('d-none');
                        $step1EmailErr.text(data.msg).show();
                    }

                    if ('dtmLayer' in data && 'digitalData' in window && data.dtmLayer.user) {
                        window.digitalData.user = data.dtmLayer.user;
                    }
                },
                complete: function () {
                    $step1Submit.prop('disabled', false);
                    $.spinner().stop();
                }
            });
        });

        $step2.on('submit', function (e) {
            const $form = $(this);
            const url = $form.attr('action');
            e.preventDefault();
            $step2Submit.prop('disabled', true);
            $.spinner().start();

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        $('.signup-form-container').addClass('d-none');
                        $step3.removeClass('d-none');
                    } else {
                        formHelpers.loadFormErrors($step2, data.fields);
                    }

                    if ('dtmLayer' in data && 'digitalData' in window && data.dtmLayer.user) {
                        window.digitalData.user = data.dtmLayer.user;
                    }
                },
                complete: function () {
                    $step2Submit.prop('disabled', false);
                    $.spinner().stop();
                }
            });
        });
    },

    /**
     * Setup pop-up loyalty form
     */
    initLoyaltyPopupForm: function () {
        const $loyaltyForm = $('.js-loyalty-popup-form');
        const $step1 = $('.js-loyalty-popup-step1');
        const $step1Email = $('.email-address-template');
        const $step1Submit = $('.js-loyalty-popup-step1submit');
        const $step2 = $('.js-loyalty-popup-step2');
        const $step2Email = $('.email-address-value');
        const $step2EmailRow = $('.js-loyalty-popup-step2email');
        const $step2Submit = $('.js-loyalty-popup-step2submit');
        const $step3 = $('.signup-success-container');

        const validateStep1 = function () {
            let isValid = false;
            if ($step1Email.hasClass('is-invalid') || !$step1Email.val()) {
                $step1Email.trigger('focus'); // Indicate invalid field with cursor
                $step1Submit.prop('disabled', true);
            } else {
                $step1Submit.prop('disabled', false);
                isValid = true;
            }
            return isValid;
        };

        $step1Submit.on('click', function () {
            if (validateStep1()) {
                $step2Email.attr('value', $step1Email.val());
                $step1.addClass('d-none');
                $step2.removeClass('d-none');
            }
        });

        $step1Email.on('blur', function () {
            validateStep1();
        });

        $loyaltyForm.on('submit', function (e) {
            const $form = $(this);
            const url = $form.attr('action');
            e.preventDefault();
            $step2Submit.prop('disabled', true);
            $.spinner().start();

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        $('.signup-form-container').addClass('d-none');
                        $step3.removeClass('d-none');
                    } else {
                        $step2EmailRow.removeClass('d-none');
                        formHelpers.loadFormErrors($loyaltyForm, data.fields);
                        $('.email-signup-modal').animate({
                            scrollTop: $('.form-group.is-invalid').first().offset().top
                        }, 1000);
                    }
                },
                complete: function () {
                    $step2Submit.prop('disabled', false);
                    $.spinner().stop();
                }
            });
        });
    }

};
