(function ($) {
    /**
     * adaLabel: The hidden text that is applied to each hot spot for ADA compliance.
     * enableTooltipsAt: The window size at which to switch over to tooltips instead of toasters. This must align with the CSS for the tooltips.
     * hotSpots: An array of hot spot objects to be created.
     * iconName: If icons are displayed on the hot spots (marker: 'icon'), this is the name of the SVG that will be applied. Default value is 'add'.
     * marker: The style of hot spots: 'none' for blank circle, 'icon' to insert an icon, 'number' to insert a number.
     * onInit: A callback function that is called after the hot spots is initialized.
     * toasterPosition: The attachment of the toasters: 'top' or 'bottom'.
     * tooltipPosition: The default position of the tooltips: 'right', 'left', 'top', or 'bottom'.
     * tooltipSize: The width of the tooltip: 'sm', 'md', 'lg'. These sizes can be adjusted in CSS.
     * addPadding: Boolean. Set to false to remove padding from the tooltip. Defaults to true which applies 0.5rem padding.
     * showCloseOnTooltips: Boolean. Set to true to display the close button on the tooltips. Defaults to false.
     * triggerEvent: The event that should trigger the content for tooltips.
     *
     * @param {Object} options The selected jQuery object(s), with functions attached.
     * @return {Object} The jQuery collection
     */
    $.fn.cscHotSpots = function (options) { // eslint-disable-line no-param-reassign
        var resizeTimeoutId;
        var prevBehavior;
        var BEHAVIOR = { TOASTER: 'toaster', TOOLTIP: 'tooltip' };
        var MARKER_TYPE = { ICON: 'icon', NUMBER: 'number', NONE: 'none' };
        var defaults = {
            adaLabel: 'Hot Spot',
            enableTooltipsAt: 576,
            hotSpots: [],
            iconName: 'add',
            marker: MARKER_TYPE.ICON,
            onInit: function () {},
            toasterPosition: 'top',
            tooltipPosition: 'right',
            tooltipSize: 'md',
            addPadding: true,
            showCloseOnTooltips: false,
            triggerEvent: 'click'
        };
        var settings = $.extend({}, defaults, options);

        /**
         * Simple check to determine if we're behaving as a toaster. Otherwise we're behaving as a tooltip.
         * @return {boolean} True for toaster, false for tooltip.
         */
        var isToaster = function () {
            return prevBehavior === BEHAVIOR.TOASTER;
        };

        /**
         * Positions each content wrapper within the hotspots container, based on the current behavior and classes.
         * @param {Object} $hsContainer the hotspots container in which the content wrappers will be positioned.
         */
        var positionContentWrapper = function ($hsContainer) {
            var isTooltips = !isToaster();

            // set a class on the container to be used to style the content wrappers.
            if (isTooltips) {
                $hsContainer.addClass('hotSpots--tooltips').removeClass('hotSpots--toasters');
            } else {
                $hsContainer.addClass('hotSpots--toasters').removeClass('hotSpots--tooltips');
            }

            $hsContainer.find('.hotSpot__content-wrapper').each(function () {
                var $wrapper = $(this);
                if (isTooltips) { // position wrapper as tooltips.
                    var index = $wrapper.data('target');
                    var $hotSpot = $hsContainer.find('.hotSpot.hs' + index);

                    // the following variables are used to calculate the wrapper position.
                    var hsPos = $hotSpot.position();
                    var hsRadius = ($hotSpot.outerWidth() / 2);

                    // position the wrapper relative to the hot spot
                    $wrapper.css({
                        top: hsPos.top,
                        left: hsPos.left
                    });
                    if ($wrapper.hasClass('hotSpot__content-wrapper--top')) {
                        // we can't use calc() within a translate() in IE11, so have to use margin to nudge the tooltip into place.
                        $wrapper.css({
                            transform: 'translate(-50%, -100%)',
                            'margin-left': hsRadius
                        });
                    } else if ($wrapper.hasClass('hotSpot__content-wrapper--bottom')) {
                        $wrapper.css({
                            transform: 'translate(-50%, ' + (hsRadius * 2) + 'px)',
                            'margin-left': hsRadius
                        });
                    } else if ($wrapper.hasClass('hotSpot__content-wrapper--left')) {
                        $wrapper.css({
                            transform: 'translate(-100%, -50%)',
                            'margin-top': hsRadius
                        });
                    } else { // default to .hotSpot__content-wrapper--right
                        $wrapper.css({
                            transform: 'translate(' + (hsRadius * 2) + 'px, -50%)',
                            'margin-top': hsRadius
                        });
                    }
                } else { // isToaster
                    // clear out any CSS properties set by JS above
                    $wrapper.css({
                        top: '',
                        left: '',
                        'margin-left': '',
                        'margin-top': '',
                        transform: 'none'
                    });
                }
            });
        };

        return this.each(function () {
            var $hsContainer = $(this);
            // make sure that we have a container into which we can place our spots
            if ($hsContainer.prop('tagName').toLowerCase() === 'img') {
                // this is the image. wrap it in a div
                $hsContainer.removeClass('hotSpots').wrap('<div></div>');
                // now redefine what 'this' is.
                $hsContainer = $hsContainer.parent();
            }

            /**
             *	Creates a spot from the passed in data, including all the HTML for the spot the content wrapper, and positions the spot and content wrapper.
             *	@param {Object} spotData The data object describing the hot spot. See defaults for options.
             *	@param {int} index The spot index. Used to create matching class names between the spot and content wrapper. Must be unique across all hot spots.
             */
            $hsContainer.addSpot = function (spotData, index) {
                /**
                 * x:                  The hot spot x position.
                 * y:                  The hot spot y position.
                 * contentSelector:    The selector to the content that goes inside the toaster/tooltip.
                 *
                 * These settings can be set per hot spot. Otherwise they'll inherit the collection settings.
                 * adaLabel:           Hidden text that describes the hot spot, for screen readers.
                 * iconName:           The name of the svg to apply an icon to the hot spot.
                 * toasterPosition:    The position of the content wrapper in relation to the image. Only applies when the screen size is less than settings.enableTooltipsAt.
                 * tooltipPosition:    The position of the content wrapper in relation to the hot spot. Only applies when the screen size is greater than or equal to settings.enableTooltipsAt.
                 * tooltipSize:        The width of the tooltip: 'sm', 'md', 'lg'. These sizes can be adjusted in CSS.
                 * addPadding:         Boolean. Set to false to remove padding from the tooltip.
                 * showCloseOnTooltip: Boolean. Set to true to display the close button on the tooltip.
                 */
                var localDefaults = {
                    x: '50%',
                    y: '50%',
                    contentSelector: '',
                    adaLabel: settings.adaLabel,
                    iconName: settings.iconName,
                    toasterPosition: settings.toasterPosition,
                    tooltipPosition: settings.tooltipPosition,
                    tooltipSize: settings.tooltipSize,
                    addPadding: settings.addPadding,
                    showCloseOnTooltip: settings.showCloseOnTooltips
                };
                var hsSettings = $.extend({}, localDefaults, spotData); // settings for this particular spot, not the component settings.

                // create content wrapper
                var $hotSpotContentWrapper = $('<div></div>');
                $hotSpotContentWrapper.addClass('hotSpot__content-wrapper');
                $hotSpotContentWrapper.addClass('hotSpot__content-wrapper--' + hsSettings.tooltipPosition);
                $hotSpotContentWrapper.addClass('hotSpot__content-wrapper--' + hsSettings.tooltipSize);
                if (hsSettings.addPadding) {
                    $hotSpotContentWrapper.addClass('hotSpot__content-wrapper--padded');
                }
                if (hsSettings.showCloseOnTooltip) {
                    // the close button is always added because we need it for closing toasters,
                    // but this class will allow us to show or hide the button on the tooltip.
                    $hotSpotContentWrapper.addClass('hotSpot__content-wrapper--withClose');
                }
                $hotSpotContentWrapper.addClass('hotSpot__content-wrapper--toaster-' + hsSettings.toasterPosition);

                $hotSpotContentWrapper.addClass('hs' + index); // for selecting this content wrapper, given a hotspot.
                $hotSpotContentWrapper.data('target', index); // for selecting hotspots from this content wrapper.

                // move the content into the content wrapper.
                var $content = $(hsSettings.contentSelector);
                $content.addClass('hotSpot__content');
                $hotSpotContentWrapper.html($content);

                // append close button
                var $closeBtn = $('<div></div>').addClass('hotSpot__closeBtn');
                $hotSpotContentWrapper.find('.hotSpot__content').append($closeBtn);

                // create hot spot
                var $hotSpot = $('<div></div>').addClass('hotSpot').attr('tabindex', '0');
                $hotSpot.addClass('hs' + index); // for selecting this hotspot, given a content wrapper.
                $hotSpot.data('target', index); // for selecting a content wrapper from this hotspot.
                $hotSpot.css({
                    top: hsSettings.y,
                    left: hsSettings.x
                });

                var $srOnly = '<span class="sr-only">' + hsSettings.adaLabel + '</span>';
                $hotSpot.append($srOnly);

                // set the marker on the hot spot
                if (settings.marker === MARKER_TYPE.NUMBER) {
                    var $marker = $('<span class="hotSpot__marker-number">' + (index + 1) + '</span>');
                    $hotSpot.append($marker);
                } else if (settings.marker === MARKER_TYPE.ICON) {
                    var $svg = $(`<svg width='24' height='24'><use href='${'#' + hsSettings.iconName}'></use></svg>`);
                    $hotSpot.append($svg);
                }
                // else if (settings.marker === MARKER_TYPE.NONE) // don't need to do anything.

                // attach the spot and content wrapper to the container.
                this.append($hotSpot);
                this.append($hotSpotContentWrapper);
            };

            /**
             * (re)register click/hover events
             * @return {undefined}
             */
            $hsContainer.registerEvents = function () {
                $hsContainer.off('click mouseenter mouseleave focus blur'); // unbind events so we can re-bind.
                if (settings.triggerEvent === 'click' || isToaster()) { // toasters only get the click event handler.
                    var toggleClickedSpot = function (spot) {
                        var $clickedSpot = $(spot);
                        if ($clickedSpot.hasClass('active')) {
                            $hsContainer.closeAll();
                        } else {
                            var index = $clickedSpot.data('target');
                            var $wrapper = $hsContainer.find('.hotSpot__content-wrapper.hs' + index);
                            $hsContainer.closeAll();
                            $clickedSpot.addClass('active');
                            $wrapper.addClass('active');
                        }
                    };
                    $hsContainer.on('click', '.hotSpot', function (e) {
                        e.preventDefault();
                        toggleClickedSpot(this);
                    });
                    $hsContainer.on('keydown', '.hotSpot:focus', function (e) {
                        if (e.key === 'Enter') {
                            toggleClickedSpot(this);
                        }
                    });
                } else {
                    var mouseleaveTimeoutId;
                    $hsContainer.on('mouseenter focus', '.hotSpot', function () {
                        clearTimeout(mouseleaveTimeoutId);
                        $hsContainer.closeAll();
                        var $hoveredSpot = $(this);
                        var index = $hoveredSpot.data('target');
                        var $wrapper = $hsContainer.find('.hotSpot__content-wrapper.hs' + index);
                        $hoveredSpot.addClass('active');
                        $wrapper.addClass('active');
                    });
                    $hsContainer.on('mouseenter focus', '.hotSpot__content-wrapper', function () {
                        clearTimeout(mouseleaveTimeoutId);
                    });
                    $hsContainer.on('mouseleave blur', '.hotSpot, .hotSpot__content-wrapper', function () {
                        var index = $(this).data('target');
                        mouseleaveTimeoutId = setTimeout(function () {
                            $hsContainer.find('.hs' + index).removeClass('active');
                        }, 100);
                    });
                }
                $hsContainer.on('click', '.hotSpot__closeBtn', function (e) {
                    e.preventDefault();
                    $hsContainer.closeAll();
                });
            };

            /**
             * Remove the active class from all hot spots and content wrappers, thus closing them.
             * @return {undefined}
             */
            $hsContainer.closeAll = function () {
                this.find('.active').removeClass('active');
            };

            /**
             * initialize the hot spots component.
             * @return {Object} this jquery object.
             */
            $hsContainer.init = function () {
                $hsContainer.addClass('hotSpots');
                $hsContainer.children('img:first').addClass('hotSpots__image');

                if (settings.hotSpots.length) {
                    $hsContainer.addSpots(settings.hotSpots);
                }

                // call the callback
                settings.onInit.call($hsContainer);

                return $hsContainer;
            };

            /**
             * Handler for the onResize listener.
             * @return {undefined}
             */
            var onResize = function () {
                $hsContainer.closeAll();
                // determin if we're above or below the window size in which we switch from toasters to tooltips.
                var windowWidth = window.innerWidth;
                var behavior = windowWidth < settings.enableTooltipsAt ? BEHAVIOR.TOASTER : BEHAVIOR.TOOLTIP;
                // check if we've changed behaviors.
                if (behavior !== prevBehavior) {
                    prevBehavior = behavior;
                    $hsContainer.registerEvents();
                }
                positionContentWrapper($hsContainer);
            };

            /**
             * Adds a spot for each item in the given array.
             * @param {array} spots Array of hotspots.
             */
            $hsContainer.addSpots = function (spots) {
                for (var i = 0; i < spots.length; i++) {
                    $hsContainer.addSpot(spots[i], i);
                }
                settings.hotSpots = spots;
                setTimeout(onResize, 0); // this will call onResize after the spots have been added to the container.
            };

            /**
             * Register a resize listener to re-position content when the window is resized.
             * @return {undefined}
             */
            $(window).on('resize.hotspots', function () {
                clearTimeout(resizeTimeoutId);
                resizeTimeoutId = setTimeout(onResize, 250);
            });
            $hsContainer.init();
            return this;
        });
    };
}(jQuery));
