'use strict';

var _ = require('underscore');
var formHelpers = require('../checkout/formErrors');

/**
 * Initialize back to top button
 */
const initBackToTop = function () {
    const $backToTop = $('.back-to-top');

    if (!$backToTop.length) {
        return;
    }

    $backToTop.on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 500);
    });

    // Show back to top when user scrolls down a certain amount of pixels (e.g. 300px)
    // and hide it when they scroll to the footer
    $(document).on('scroll', _.throttle(function () {
        var scrollTop = $(this).scrollTop();
        if (scrollTop > $(window).height()) {
            $backToTop.addClass('show');
        } else {
            $backToTop.removeClass('show');
        }
    }, 200));
};

/**
 * Auto-update copyright year
 */
const updateNowYear = function () {
    const jsNowYear = document.querySelector('.js-now-year');
    if (jsNowYear) {
        const now = new Date();
        jsNowYear.innerText = now.getFullYear();
    }
};

/**
 * Format Zip/Postal code
 */
var formatZipCode = function () {
    $('.js-footer-email-zip').on('keyup blur keypress change', function () {
        var $targetInput = $(this);
        var enteredValue = $targetInput.val();
        var valueToDisplay = enteredValue;
        if (enteredValue) {
            valueToDisplay = enteredValue.toUpperCase();
        }
        var isPostalCodeCA = $targetInput.is('[name$="_emailSignup_postalCodes_CA"]');
        if (isPostalCodeCA && enteredValue.length === 6 && enteredValue.indexOf(' ') === -1) {
            var tp = enteredValue.split('');
            tp.splice(3, 0, ' ');
            valueToDisplay = tp.join('');
        }

        var isPostalCodeHK = $targetInput.is('[name$="_emailSignup_postalCodes_HK"]');
        if (isPostalCodeHK) {
            if (enteredValue.trim() === '-') {
                valueToDisplay = '00000';
            }
        }
        $targetInput.val(valueToDisplay);
    });
};

module.exports = function () {
    $('.foot-email form.email-signup__step1').submit(function (e) {
        e.preventDefault();
        var $form = $(this);
        var $parent = $form.parent();
        var url = $form.attr('action');

        $.spinner().start();
        $('.js-btn-email').prop('disabled', true);

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                var $emailInvalid = $parent.find('.js-footer-email-invalid');
                var $step2 = $parent.find('.email-signup__step2');
                var $step3 = $parent.find('.email-signup__step3');

                if (data.success) {
                    $emailInvalid.text('').hide();
                    $parent.find('.email-address-value').attr('value', data.email);

                    if (data.allFieldsProvided) {
                        $('.js-footer-zipcode').text(data.postalCode).show();
                        $('.js-footer-gender').text(data.gender).show();
                        $step3.drawer({
                            returnFocusTo: '.js-footer-email'
                        });
                    } else if ($step2.hasClass('footer-prana-email')) { // show alert for prana immediately after successful submission
                        $.alert({
                            content: `<div><h3>${window.resources.emailSignupFormAlert.title}</h3><br><span>${window.resources.emailSignupFormAlert.content}</span></div>`,
                            placement: 'toaster',
                            theme: 'success',
                            dismissible: true,
                            id: 'footersignup-banner-message'
                        });
                    } else {
                        $step2.drawer({
                            returnFocusTo: '.js-footer-email'
                        });
                    }

                    if ('dtmLayer' in data && 'digitalData' in window) {
                        if (data.dtmLayer.page && data.dtmLayer.page.form &&
                            window.digitalData.page && window.digitalData.page.form) {
                            window.digitalData.page.form.registrationType = data.dtmLayer.page.form.registrationType;
                        }

                        if (data.dtmLayer.user) {
                            window.digitalData.user = data.dtmLayer.user;
                        }
                    }

                    $('html, body').animate({
                        scrollTop: $form.offset().top - 100
                    }, 1000);

                    formatZipCode();
                } else if (data.msg) {
                    $parent.find('.email-signup__step2__success').collapse('hide');
                    $step2.collapse('hide');
                    $step3.collapse('hide');
                    $emailInvalid.text(data.msg).show();
                }
            },
            complete: function () {
                $('.js-btn-email').prop('disabled', false);
                $.spinner().stop();
            }
        });
    });

    $('.foot-email form.email-signup__step2').on('change', function () {
        var $zipField = $(this).find('.js-footer-email-zip');
        var $genderField = $(this).find('.custom-select');
        var $signUpButton = $(this).find('.js-footer-email-submit');
        // Handles duplicate sign up forms introduced by content assets
        if (($zipField.length && $zipField.val() === $zipField.prop('defaultValue')) && $genderField.prop('selectedIndex' === 0)) {
            $signUpButton.prop('disabled', true);
        } else {
            $signUpButton.prop('disabled', false);
        }
    });

    $('.foot-email form.email-signup__step2').submit(function (e) {
        e.preventDefault();
        var $form = $(this);
        var formIndex = $('.foot-email form.email-signup__step2').index(this);
        var url = $form.attr('action');
        $('.email-signup__step2 .js-footer-email-submit').eq(formIndex).prop('disabled', true);
        $.spinner().start();

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.success) {
                    $('.emailFooterSignup').eq(formIndex).collapse('hide');
                    const $step2Drawer = $('.email-signup__step2.emailFooterSignup.drawer');

                    if (data.successMessage) {
                        $step2Drawer.removeClass('show');
                        $('.drawer__backdrop').off('click').remove();

                        $.alert({
                            content: data.successMessage,
                            placement: 'toaster',
                            theme: 'success',
                            dismissible: true,
                            id: 'footersignup-banner-message'
                        });
                    } else {
                        $('.email-signup__step2__success').eq(formIndex).collapse('show');
                    }

                    if ('dtmLayer' in data && 'digitalData' in window) {
                        if (data.dtmLayer.page && data.dtmLayer.page.form &&
                            window.digitalData.page && window.digitalData.page.form) {
                            window.digitalData.page.form.registrationType = data.dtmLayer.page.form.registrationType;
                        }

                        if (data.dtmLayer.user) {
                            window.digitalData.user = data.dtmLayer.user;
                        }
                    }
                } else {
                    formHelpers.loadFormErrors('.email-signup__step2', data.fields, formIndex);
                }
            },
            complete: function () {
                $('.email-signup__step2 .js-footer-email-submit').eq(formIndex).prop('disabled', false);
                $.spinner().stop();
            }
        });
    });

    $('#hpEmailSignUp').on('focus', function () {
        $('.email-signup__step2__success').collapse('hide');
        $('.invalid-feedback').hide();
    });

    /**
     * Pre-populates the footer email box if the user is logged in and not already subscribed
     */
    $(function () {
        var currentUser = window.digitalData && window.digitalData.user;

        if (currentUser && currentUser.emailId && currentUser.loggedIn && !currentUser.newsletterOptedIn) {
            $('.js-footer-email').val(currentUser.emailId);
        }
    });

    /**
     * Submits the loyalty registration form.
     * On successful registration, redirects to location defined in loyaltyRedirectURL.
     * If loyaltyRedirectURL is not present in response, refresh the page
     */
    $('form.loyalty-registration').submit(function (e) {
        e.preventDefault();
        var $form = $(this);
        var url = $form.attr('action');

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.success) {
                    var location = typeof data.loyaltyRedirectURL !== 'undefined' ? data.loyaltyRedirectURL : window.location.href;
                    window.location.href = location;
                } else {
                    formHelpers.loadFormErrors('.loyalty-registration', data.fields);
                }
            },
            error: function (data) {
                formHelpers.loadFormErrors('.loyalty-registration', data.fields);
            }
        });
    });

    $('.rewards__link').on('click', function () {
        var $rewardsLinks = $(this);
        var $header = $('header');
        $rewardsLinks.scrollTo($header.outerHeight() + 16 /* add some space between the header and link */);
    });

    /**
     * The show event first as soon as a request is made to display the pane.
     * As such, using the event so lazy load can happen earlier (faster experience).
     */
    $('.js-rewards-signup-panel').on('show.bs.collapse.lazyload', function () {
        var $panel = $(this);

        if ($panel.data('is-loading')) {
            return;
        }

        /**
         * Mark as loading before the call returns so rapid clicks by the user
         * doesn't load the form multiple times.
         */
        $panel.data('is-loading', true);

        var $formContainer = $panel.find('.js-rewards-form-container');
        var lazyLoadUrl = $panel.data('lazy-load-url');

        var loadingSpinner = $formContainer.spinner();
        loadingSpinner.start();

        $.ajax({
            url: lazyLoadUrl,
            method: 'GET',
            success: function (responseHtml) {
                $formContainer.append(responseHtml);

                // eslint-disable-next-line no-undef
                registerButtonAsRecaptchaTrigger($formContainer.find('.btn-recaptcha'));

                $formContainer.find('form').trigger('form:created');

                $panel.off('show.bs.collapse.lazyload');
            },
            complete: function () {
                $panel.data('is-loading', false);
                loadingSpinner.stop();
            }
        });
    });

    initBackToTop();
    updateNowYear();
};
