'use strict';

require('slick-carousel/slick/slick.min');

module.exports = function () {
    var defaultSlickSettings = {
        infinite: false,
        mobileFirst: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    dots: true,
                    centerMode: true,
                    centerPadding: '2.5rem'
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    centerMode: true,
                    centerPadding: '3.75rem'
                }
            }
        ]
    };

    $('.carousel-container').slick(defaultSlickSettings);
};

// eslint-disable-next-line no-unused-vars
window.initCarousel = function (selector, classes, settings) {
    var slickAttempts = 0;
    var checkForSlick = function () {
        if (typeof $.fn.slick !== 'function' && slickAttempts < 30) {
            slickAttempts++;
            setTimeout(checkForSlick, 100);
        } else {
            var $carousel = $(selector);
            $carousel.addClass(classes);
            $carousel.children('div').each(function () {
                var $slide = $(this);
                if (($slide.text().trim() === '') && ($slide.find('img').length === 0)) {
                    $slide.remove();
                }
            });
            $carousel.slick(settings);
        }
    };
    checkForSlick();
};
